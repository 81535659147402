import React, { useState, useEffect } from 'react';
import './Employee.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Modal, Box, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, IconButton, FormLabel } from '@mui/material';
import { InputCustom, SelectCustom } from '../adminComponents/adminComponents';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import axios from 'axios';
import { apiBaseUrl } from '../../static/Apiname/api';
import { SelectBox } from '../selectBox/selectBox';
import { toastmsg, toastmsgsuccess } from '../Toasts/toasts';
import { AlertModal } from '../Admin/Modal';
import { ClipLoader } from 'react-spinners';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useModal } from '../ModalToast/ToastModal';


const WhiteDropDownIcon = () => (
    <ArrowDropDownIcon style={{ color: 'var(--text-color)', fontSize: '2rem', paddingRight: '5px' }} />
);

// import IconButton from '@mui/material/IconButton';
// import { Button } from '@mui/material';
function createData(code, name, username, dept, designaion, mobile, mail) {
    return { code, name, username, dept, designaion, mobile, mail };
}
function Employee() {
    const [open, setOpen] = useState(false);
    const [employeeData, setEmployeeData] = useState([]);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState('');
    // const [designation, setDesignation] = useState('');
    const [reportingTo, setReportingTo] = useState(null);
    const [designations, setDesignations] = useState([]);
    const [selectedDesignation, setSelectedDesignation] = useState([]);
    const [reportingOptions, setReportingOptions] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);
    const [selectedState, setSelectedState] = useState(null);
    const [userRoleOptions, setUserRoleOptions] = useState([]);
    const [selectedUserRole, setSelectedUserRole] = useState([]);
    const [iseditmode, setIseditmode] = useState(false);
    const [allowLogin, setAllowLogin] = useState('0');
    const [rebuildemploy, setrebuildembloy] = useState(false)
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const {showModal} = useModal();

    const handleOpenConfirmModal = () => setShowConfirmModal(true);
    const handleCloseConfirmModal = () => setShowConfirmModal(false);


    const token = sessionStorage.getItem('access-token')
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const orgHerarchieValues = ["ED", "VP", "HOD", "MIS", "RSM", "ASM", "SE"];

    const orgHerarchy = [
        ...orgHerarchieValues.map(
            org => ({
                label: org,
                value: org
            })
        )
    ]
    const [orgHeraSelected, setOrgHeraSelected] = useState(null)
    // {value:"ALL"},
    const handleAllowLoginChange = (event) => {
        setAllowLogin(event.target.checked ? '1' : '0'); // Update state based on checkbox value
    };
    const handleUserRoleChange = (selectedOption) => {
        setSelectedUserRole(selectedOption);
        console.log("Selected User Role: ", selectedOption); // Console log can be removed in production
    };
    const handleStateChange = (selectedOption) => {
        setSelectedState(selectedOption);
        console.log("Selected State: ", selectedOption); // You can remove this console log in production
    };
    const handleDepartmentChange = (event) => {
        setSelectedDepartment(event); // Update the selected department
    };
    const handleDesignationChange = (event) => {
        setSelectedDesignation(event); // Update the selected department
    };

    const handleReportingChange = (event) => {
        setReportingTo(event);
    };
    const rebuildemplylist = () => {
        setrebuildembloy(!rebuildemploy)
    }
    const handleEdit = (row) => {
        console.log(row)
        const selectherarchy = orgHerarchy.filter(
            herarchy => herarchy.value === row.FM06_OrganizationHierarchy
        )
        const reportTo = reportingOptions.filter(
            optns => optns.value == row.FM06_M06_ReportingTo
        )
        const designationSelect = designations.filter(
            des => row.FM06_Designation === des.FM06_Designation
        ).map(
            desig => ({ label: desig.FM06_Designation, value: desig.FM06_Designation })
        )[0]
        const departmentSelect = departments.filter(
            dpt => row.FM06_Department === dpt.FM06_Department
        ).map(
            dept => ({ label: dept.FM06_Department, value: dept.FM06_Department })
        )[0]
        console.log('designationSelect to', designationSelect)
        setAllowLogin(row.FM06_AllowLogin.toString())
        setIseditmode(true);
        setSelectedEmployee(row);
        setSelectedDesignation(designationSelect);
        setSelectedDepartment(departmentSelect);
        setOrgHeraSelected(selectherarchy.length > 0 ? selectherarchy[0] : null)
        setReportingTo(reportTo.length > 0 ? reportTo[0] : null)
        handleOpen();
    };
    const handleclear = (row) => {
        setIseditmode(false)
        setSelectedEmployee(null);
        setSelectedDesignation(null);
        setSelectedDepartment(null);
        setOrgHeraSelected(null)
        setReportingTo(null)
        setAllowLogin('0')
    };

    const handleDeleteEmployee = async () => {
        if (!selectedEmployee || !selectedEmployee.id) return;
    
        try {
            // Make a delete request to your API
            await axios.delete(`${apiBaseUrl}/api/employee/delete/${selectedEmployee.id}`, config);
            showModal('Employee deleted successfully', 'success');
    
            // Update local state or refetch data to reflect the deletion
            setEmployeeData(employeeData.filter(emp => emp.id !== selectedEmployee.id));
    
            // Close the modal and reset edit mode
            handleClose();
            setIseditmode(false);
        } catch (error) {
            console.error('Error deleting employee:', error);
            showModal('Error deleting employee');
        }
    };


    useEffect(() => {
        const fetchStates = async () => {
            try {
                const response = await axios.get(`${apiBaseUrl}/api/state/list`, config);
                const formattedStates = response.data.states.map(state => ({
                    label: state.FM01_State,
                    value: state.id // assuming you want to use the 'id' as the value
                }));
                setStateOptions(formattedStates);
            } catch (error) {
                console.error('Error fetching states:', error);
            }
        };

        fetchStates();
    }, []);

    const modalClose = () => {
        handleClose()
        handleclear();
        rebuildemplylist()
    }
    // Modify handleSubmit to handle adding a new employee
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (iseditmode) {
            handleUpdate(); // For updating existing employee
        } else {
            // Prepare data for new employee
            const newEmployeeData = {
                FM06_EmployeeCode: selectedEmployee?.FM06_EmployeeCode,
                FM06_EmployeeName: selectedEmployee?.FM06_EmployeeName,
                FM06_Mobile: selectedEmployee?.FM06_Mobile,
                FM06_MailID: selectedEmployee?.FM06_MailID,
                email: selectedEmployee?.email,
                // ... include other necessary fields
                FM06_Department: selectedDepartment.value,
                FM06_Designation: selectedDesignation.value,
                FM06_OrganizationHierarchy: orgHeraSelected?.value,
                FM06_M06_ReportingTo: reportingTo?.value,
                FM06_AllowLogin: allowLogin,


                // ... include as needed
            };

            console.log('newempdata', newEmployeeData)

            // const formData = new FormData();

            // // Append each key-value pair from newEmployeeData to the FormData object
            // for (const key in newEmployeeData) {
            //     if (newEmployeeData.hasOwnProperty(key)) {
            //         formData.append(key, newEmployeeData[key]);
            //     }
            // }



            try {
                const response = await axios.post(`${apiBaseUrl}/api/employee/create`, newEmployeeData, config);

                console.log('New Employee Added:', response.data);

                const userId = response.data?.id;
                if (userId && selectedState && selectedState.length > 0) {

                    const stateData = {
                        employee_id: userId,
                        reporting_to: reportingTo?.value,
                        state_ids: selectedState.map(
                            state => state.value
                        )
                    }
                    console.log('state data', stateData)
                    const response = await axios.post(`${apiBaseUrl}/api/emp-state-mapping/create`, stateData, config);
                    console.log('successfully sent', response.data)
                    if (selectedUserRole && selectedUserRole.length > 0) {
                        const roleData = {
                            employee_id: userId,
                            role_master_ids: selectedUserRole.map(
                                role => role.value
                            )
                        }
                        console.log('roledata ', roleData)
                        const response1 = await axios.post(`${apiBaseUrl}/api/emp-state-mapping/create`, stateData, config);
                        console.log('successfully sent role', response1.data)
                    }


                }
                modalClose()
                showModal('Employee Added Successfull', 'success')

                // Optionally, update your state to reflect the new employee
                // setEmployeeData([...newEmployeeData, response.data]);
            } catch (error) {

                console.error('Error adding new employee:', error);

                // Extract error messages if they exist
                const errorData = error?.response?.data;
                if (errorData && errorData.message === "The given data was invalid.") {
                    // Construct an error message based on specific field errors
                    let errorMessage = '';
                    if (errorData.errors.email) {
                        errorMessage += errorData.errors.email[0]; // Add first email error message
                    }
                    if (errorData.errors.FM06_EmployeeCode) {
                        errorMessage += (errorMessage ? '\n' : '') + errorData.errors.FM06_EmployeeCode[0]; // Add first employee code error message
                    }
                    // ... handle other specific fields similarly if needed ...
                    showModal(errorMessage); // Display the constructed error message
                } else {
                    showModal(error?.response?.data?.message || 'Error adding new employee');
                }
            }
        }
    };


    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'var(--sidebar-color)',
        boxShadow: 24,
        p: 4,
        maxHeight: '90vh',
        overflow: 'scroll',
        color: 'var(--text-color)',
        borderRadius: '6px'
    };
    const modalStyleDelete = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '230px',
        bgcolor: 'var(--sidebar-color)',
        boxShadow: 24,
        p: '13px',
        border: '1px solid white',
        maxHeight: '90vh',
        color: 'var(--text-color)',
        borderRadius: '6px'
    };

    const handleDelete = (row) => {
        // Add your delete logic here
        console.log("Delete", row);
    };
    useEffect(() => {
        // Fetch user roles from the API
        const fetchUserRoles = async () => {
            try {
                const response = await axios.get(`${apiBaseUrl}/api/user-role/list`, config);
                const formattedUserRoles = response.data.user_roles.map(role => ({
                    label: role.FM14_RoleName,
                    value: role.id
                }));
                setUserRoleOptions(formattedUserRoles);
            } catch (error) {
                console.error('Error fetching user roles:', error);
            }
        };

        fetchUserRoles();
    }, []);
    useEffect(() => {
        // Fetch data when the component mounts
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${apiBaseUrl}/api/employee`, config);
                setEmployeeData(response.data); // Update the state with fetched data
                const employeeOptions = [
                    // {value:null,label:'Reporting To'},
                    ...response.data.map(employee => ({
                        label: employee.FM06_EmployeeName,
                        value: employee.id // assuming there's an 'id' field to use as the value
                    }))
                ];
                setReportingOptions([
                    ...employeeOptions
                ]);
                setReportingTo(employeeOptions[0])
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false); // Stop loading regardless of success or failure
            }
        };

        fetchData();
    }, [rebuildemploy]);



    useEffect(() => {
        // Fetch departments when the component mounts
        const fetchDepartments = async () => {
            try {
                const response = await axios.get(`${apiBaseUrl}/api/department/list`, config);
                // Check if departments is an array
                if (Array.isArray(response.data.departments)) {
                    setDepartments(response.data.departments); // Update the state with fetched department data
                } else {
                    console.error('Expected departments to be an array, but received:', response.data.departments);
                }
            } catch (error) {
                console.error('Error fetching departments:', error);
            }
        };

        const fetchDesignations = async () => {
            try {
                const response = await axios.get(`${apiBaseUrl}/api/department/list`, config);
                if (Array.isArray(response.data.designations)) {
                    setDesignations(response.data.designations);
                    console.log('resdata', response.data)
                } else {
                    console.error('Expected designations to be an array, but received:', response.data.designations);
                }
            } catch (error) {
                console.error('Error fetching designations:', error);
            }
        };

        fetchDepartments();
        fetchDesignations();
    }, []);
    if (isLoading) {
        return <div className='home'>
            <SkeletonTheme baseColor="#f6f5ff00" highlightColor="#ffffff73" width={'95%'}>
                <p>
                    <Skeleton height={90} />
                    <Skeleton count={15} />
                </p>
            </SkeletonTheme>
        </div>; // Show a loading indicator while data is being fetched
    }
    const handleUpdate = async () => {
        try {
            const updatedEmployee = {
                FM06_EmployeeCode: selectedEmployee.FM06_EmployeeCode,
                FM06_EmployeeName: selectedEmployee.FM06_EmployeeName,
                FM06_Mobile: selectedEmployee.FM06_Mobile,
                FM06_MailID: selectedEmployee?.FM06_MailID,
                email: selectedEmployee?.email,
                FM06_Department: selectedDepartment.value,
                FM06_Designation: selectedDesignation.value,
                FM06_OrganizationHierarchy: orgHeraSelected?.value,
                FM06_M06_ReportingTo: reportingTo?.value,
                FM06_AllowLogin: allowLogin,
                // ... include other fields that need to be updated
            };

            console.log('updated data is ', updatedEmployee)

            const response = await axios.post(`${apiBaseUrl}/api/employee/update/${selectedEmployee.id}`, updatedEmployee, config);
            console.log('Update Response:', response.data);

            // Update the local state to reflect the change
            // setEmployeeData(employeeData.map(emp => emp.id === selectedEmployee.id ? response.data : emp));
            if (selectedEmployee.id && selectedState && selectedState.length > 0) {

                const stateData = {
                    employee_id: selectedEmployee.id,
                    reporting_to: reportingTo?.value,
                    state_ids: selectedState.map(
                        state => state.value
                    )
                }
                console.log('state data', stateData)
                const response = await axios.post(`${apiBaseUrl}/api/emp-state-mapping/create`, stateData, config);
                console.log('successfully sent', response.data)
                if (selectedUserRole && selectedUserRole.length > 0) {
                    const roleData = {
                        employee_id: selectedEmployee.id,
                        role_master_ids: selectedUserRole.map(
                            role => role.value
                        )
                    }
                    console.log('roledata ', roleData)
                    const response1 = await axios.post(`${apiBaseUrl}/api/emp-state-mapping/create`, stateData, config);
                    console.log('successfully sent role', response1.data)
                }


            }
            showModal('Employee updated Successfully', 'success')
            modalClose()

        } catch (error) {
            console.error('Error updating employee:', error);
        }
    };


    const getFilteredEmployeeData = () => {
        if (!searchQuery) return employeeData;
        return employeeData.filter((employee) =>
            employee.FM06_EmployeeName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            employee.FM06_EmployeeCode.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };
    return (
        <>
            <section className="home">
                <AlertModal show={false}>
                    <ClipLoader color='white' size={30} />
                </AlertModal>
                <div className="mainDiv" style={{ padding: '1px' }}>
                    <div className="btngrp d-flex justify-content-start align-items-center  flex-row-reverse " style={{ width: '95%' }}  >
                        <Button variant="contained" color="success" className='mb-2' style={{ float: 'right' }} onClick={handleOpen}  >
                            <i className='bx bxs-user-plus icon fs-2 pe-2 '></i> New Employee
                        </Button>
                        <div className="search-container mb-2" style={{ float: 'right' }} >

                            <InputCustom
                                label='Search Employee'
                                variant="outlined"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                style={{ margin: '0px 10px 0px' }}
                            />
                        </div>

                        <Modal
                            open={open}
                            onClose={modalClose}

                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >

                            <Box sx={modalStyle} >
                                <form onSubmit={handleSubmit} autoComplete="off" className='container'>
                                    <div className="row">
                                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                            <InputCustom
                                                required
                                                id="code"
                                                label="Employee Code"
                                                variant="outlined"
                                                className="custom-outline custom-label"
                                                fullWidth
                                                margin="normal"
                                                value={selectedEmployee ? selectedEmployee.FM06_EmployeeCode : ''}

                                                onChange={(e) => setSelectedEmployee({ ...selectedEmployee, FM06_EmployeeCode: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-xl-9 col-lg-8 col-md-6 col-sm-12">
                                            <InputCustom
                                                required
                                                id="name"
                                                label="Employee Name"
                                                variant="outlined"
                                                className="custom-outline custom-label"
                                                fullWidth
                                                margin="normal"
                                                value={selectedEmployee ? selectedEmployee.FM06_EmployeeName : ''}
                                                onChange={(e) => setSelectedEmployee({ ...selectedEmployee, FM06_EmployeeName: e.target.value })}

                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <FormControl fullWidth margin="normal" className="custom-outline">
                                                <div>
                                                    <b>Departments</b>
                                                </div>
                                                <SelectBox
                                                    required
                                                    options={departments.map(dept => ({ label: dept.FM06_Department, value: dept.FM06_Department }))} // Assuming your department objects have 'id' and 'name' properties
                                                    placeholder='Departments'
                                                    value={selectedDepartment}
                                                    onChange={handleDepartmentChange}
                                                />
                                            </FormControl>

                                        </div>

                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <FormControl fullWidth margin="normal" className="custom-outline" >
                                                <div>
                                                    <b>Designation</b>
                                                </div>
                                                <SelectBox
                                                    required
                                                    options={designations.map(desig => ({ label: desig.FM06_Designation, value: desig.FM06_Designation }))} // Assuming your department objects have 'id' and 'name' properties
                                                    placeholder='Designation'
                                                    value={selectedDesignation}
                                                    onChange={handleDesignationChange}
                                                />

                                            </FormControl>
                                        </div>



                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <FormControl fullWidth margin="normal" className="custom-label">
                                                <div>
                                                    <b>Organizational Herarchy</b>
                                                </div>
                                                <SelectBox
                                                    required
                                                    zIndex={4}
                                                    placeholder='Organizational Herarchy'
                                                    options={orgHerarchy}
                                                    isMulti={false}
                                                    onChange={(value) => setOrgHeraSelected(value)}
                                                    value={orgHeraSelected}
                                                ></SelectBox>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4  col-sm-12">
                                            {/* <div>
                                                   <b>Mobile</b> 
                                                </div> */}
                                            <InputCustom
                                                // required
                                                id="FM06_Mobile"
                                                label="Mobile"
                                                type='number'
                                                variant="outlined"
                                                className="custom-outline custom-label"
                                                fullWidth
                                                margin="normal"
                                                value={selectedEmployee ? selectedEmployee.FM06_Mobile : ''}
                                                onChange={(e) => setSelectedEmployee({ ...selectedEmployee, FM06_Mobile: e.target.value })}
                                            />
                                        </div>


                                        <div className="col-lg-4  col-sm-12">
                                            <InputCustom
                                                required
                                                id="FM06_MailID"
                                                label="Mail ID"
                                                type="email"
                                                variant="outlined"
                                                className="custom-outline custom-label"
                                                fullWidth
                                                margin="normal"
                                                value={selectedEmployee ? selectedEmployee.FM06_MailID : ''}
                                                onChange={(e) => setSelectedEmployee({ ...selectedEmployee, FM06_MailID: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-lg-4 col-sm-12">
                                            <InputCustom
                                                required
                                                id="email" label="Username"
                                                variant="outlined"
                                                className="custom-outline custom-label"
                                                fullWidth
                                                margin="normal"
                                                value={selectedEmployee ? selectedEmployee.email : ''}

                                                onChange={(e) => setSelectedEmployee({ ...selectedEmployee, email: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">


                                        <div className="col-sm-12">
                                            <FormControl fullWidth margin="normal" className="custom-label">
                                                <div>
                                                    <b>Reporting to</b>
                                                </div>
                                                <SelectBox
                                                    placeholder='Reporting to'
                                                    labelId="FM06_M06_ReportingTo-label"
                                                    id="FM06_M06_ReportingTo"
                                                    options={reportingOptions}
                                                    onChange={handleReportingChange}
                                                    value={reportingTo}
                                                    zIndex={3}
                                                    required
                                                >

                                                </SelectBox>
                                            </FormControl>
                                        </div>
                                    </div>

                                    <FormControlLabel
                                        control={<Checkbox style={{ color: '#90484a' }}
                                            name="FM06_AllowLogin"
                                            checked={allowLogin == '0' ? false : true}
                                            onChange={handleAllowLoginChange}
                                        />}

                                        label="Allow Login"
                                    />
                                    <hr style={{ color: '#ffffff00' }} />
                                    <div style={{ width: '100%', height: '1px', background: 'var(--blackandwhite-color)' }}></div>
                                    <hr style={{ color: '#ffffff00' }} />

                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <SelectBox
                                                placeholder='States'
                                                zIndex={2}
                                                options={stateOptions}
                                                onChange={handleStateChange}
                                                value={selectedState}
                                                isMulti={true}
                                            ></SelectBox>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <SelectBox
                                                isMulti={true}
                                                placeholder='Roles'
                                                zIndex={1}
                                                options={userRoleOptions}
                                                onChange={handleUserRoleChange} // Pass the handler to SelectBox
                                                value={selectedUserRole} // Control the component with the selected value
                                            />
                                        </div>

                                    </div>
                                    <br />


                                    <div>
                                        <Button
                                            variant="contained"
                                            className='empaddbtn'
                                            // onClick={}
                                            type='submit'
                                        >
                                            {iseditmode ? 'Edit' : 'Add'}
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            className='Cancelbtn'
                                            onClick={handleClose}
                                            style={{ marginLeft: '10px', color: 'var(--text-color)', borderColor: 'var(--primaryy-color)', padding: '4px 10px' }}
                                        >
                                            Cancel
                                        </Button>

                                        {iseditmode && (
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                className='empaddbtn'
                                                onClick={handleOpenConfirmModal}
                                                style={{ marginLeft: '10px', float: 'right' }}
                                            >
                                                Delete Employee
                                            </Button>


                                        )}
                                    </div>
                                </form>
                            </Box>
                        </Modal>
                        <Modal
                            open={showConfirmModal}
                            onClose={handleCloseConfirmModal}
                        // ... other modal props
                        >
                            <Box sx={modalStyleDelete} > {/* Your styling here */}
                                <h5>Confirm Deletion</h5>
                                <p>Are you sure you want to delete this employee?</p>
                                <button
                                    onClick={handleDeleteEmployee} 
                                    className='empaddbtn'>OK</button>
                                <Button onClick={handleCloseConfirmModal}
                                    variant="outlined"
                                    className='Cancelbtn'
                                    style={{ marginLeft: '10px', color: 'var(--text-color)', borderColor: 'var(--primaryy-color)', padding: '4px 10px' }}>Cancel</Button>
                            </Box>
                        </Modal>

                    </div>

                    <Paper sx={{ width: '95%', overflow: 'hidden' }} id='tablemaindiv' >

                        <TableContainer sx={{ maxHeight: 'calc(100vh - 150px)' }} id='table-scrollbar'>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table"  >
                                <TableHead style={{ position: 'sticky', top: '0', padding: '0' }}>
                                    <TableRow>
                                        <TableCell id='TableHead'>Code</TableCell>
                                        <TableCell id='TableHead'>Employee Name  </TableCell>
                                        <TableCell id='TableHead'>Department</TableCell>
                                        <TableCell id='TableHead'>Designation</TableCell>
                                        <TableCell id='TableHead'>Mobile</TableCell>
                                        <TableCell id='TableHead'>Mail</TableCell>
                                        {/* <TableCell id='TableHead'>Actions</TableCell> */}
                                    </TableRow>
                                </TableHead>

                                <TableBody>

                                    {getFilteredEmployeeData().map((row) => (
                                        <TableRow
                                            onClick={() => handleEdit(row)}
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, alignItems: 'flex-end' }}
                                            className='dataRow'
                                        >
                                            <TableCell id='TableData' component="th" scope="row">
                                                {row.FM06_EmployeeCode}
                                            </TableCell>
                                            <TableCell id='TableData' align="right">{row.FM06_EmployeeName}</TableCell>
                                            <TableCell id='TableData' align="right">{row.FM06_Department}</TableCell>
                                            <TableCell id='TableData' align="right">{row.FM06_Designation}</TableCell>
                                            <TableCell id='TableData' align="right">{row.FM06_Mobile}</TableCell>
                                            <TableCell id='TableData' align="right">{row.FM06_MailID}</TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </div>

            </section >

        </>
    );
}

export { Employee };
