import {configureStore} from '@reduxjs/toolkit'
import { navTogleReducer } from './navTogle/navTogleSlice'
import { TableDateReducer } from './navTogle/tableSlice'
import { userDetailsReducer } from './userReducer/userSlice'


export const store = configureStore(
    {
        reducer:{
            navState:navTogleReducer,
            TableData:TableDateReducer,
            userData:userDetailsReducer
        }
    }
)