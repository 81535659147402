
import React, { useRef, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ProtectedRoutes } from '../Routes/protectedRoutes';
import axios from 'axios';
import './exeluploadpage.css';
import { apiBaseUrl } from '../../static/Apiname/api';
import { ClipLoader } from 'react-spinners'; // Import ClipLoader
import { Link } from 'react-router-dom';
import SalesDeleteForm from '../DeleteTableData/Salesdeletefrom';
import Missingdatas from '../MissingData/Missingdata';
import { useModal } from '../ModalToast/ToastModal';
const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '86vh',
  position: 'relative',
  marginBottom:'48px'
};

function ExeluploadPage() {




  const [file, setFile] = useState(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // State to track loading state
  const [uploadType, setUploadType] = useState('sales'); // State to track the type of data being uploaded
  const fileInput = useRef(null)
  const { showModal } = useModal();
  const [buttonText, setButtonText] = useState('Sales Upload');
  const [buttonTextReturn , setButtonTextReturn] = useState('Return Upload');
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [isReturnUploadLoading, setIsReturnUploadLoading] = useState(false);

  // const [modal, setModal] = useState({ isOpen: false, message: '' });
  // // ...



  // const showModal = (message) => {
  //   setModal({ isOpen: true, message });
  // };

  // // Function to close modal
  // const closeModal = () => {
  //   setModal({ isOpen: false, message: '' });
  // };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && isExcelFile(selectedFile)) {
      setFile(selectedFile);
    } else {
      showModal('Please select a valid Excel or CSV file (.xls, .xlsx, .csv).');
      e.target.value = null; // Clear the input field
    }
  };

  const isExcelFile = (file) => {
    return (
      file.type === 'application/vnd.ms-excel' ||
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'text/csv'

    );
  };

  const handleFileUpload = async () => {
    if (!file) {
      showModal('Please select a file to upload.');
      return;
    }


    setIsLoading(true);

    const formData = new FormData();
    formData.append('uploaded_file', file);


    let apiUrl;

    // eslint-disable-next-line default-case
    switch (uploadType) {
      case 'sales':
        apiUrl = `${apiBaseUrl}/api/import-data/temporarycsv/uploadcsvsalesfile`;
        break;
      case 'sales1':
        apiUrl = `${apiBaseUrl}/api/import-data/sales-daily`;
        break;

      case 'salesreturn':
        apiUrl = `${apiBaseUrl}/api/import-data/temporarycsv/uploadcsvreturnfile`;
        break;

      case 'employee':
        apiUrl = `${apiBaseUrl}/api/import-data/emp-distr-mapping`;
        break;
      case 'distributor':
        apiUrl = `${apiBaseUrl}/api/import-data/distributor`;
        break;
      case 'sku':
        apiUrl = `${apiBaseUrl}/api/import-data/sku`;
        break;
    }

    const token = sessionStorage.getItem('access-token');
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(apiUrl, formData, config);

      setIsLoading(false);
      setFile(null);
      showModal('Upload successful', 'success');
      if (fileInput.current) {
        fileInput.current.value = "";
      }
    } catch (error) {
      setIsLoading(false);
      console.error('API request failed:', error);

      // Check if there is an error response from the API
      if (error.response && error.response.data && error.response.data.errors) {
        const errorMessages = error.response.data.message;
        if (errorMessages.uploaded_file) {
          // Display the error message related to 'uploaded_file'
          showModal(errorMessages.uploaded_file[0]);
        } else {
          // Handle other errors as needed
          showModal('The given data was invalid.');
        }
      } else {
        showModal('The given data was invalid..');
      }
    }
  };

 
 
  function handleSalesData() {
    setIsUploadLoading(true);
    setButtonText('Loading...');
    const token = sessionStorage.getItem('access-token');
  
    const apiUrl = `${apiBaseUrl}/api/import-data/runSales`;
  
    fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(response => response.json())
    .then(data => {
      let messages = [];
  
      if (data.Import_response?.original?.message) {
        messages.push('Sales Import Response: ' + data.Import_response.original.message);
      }
      if (Array.isArray(data.Insert_response)) {
        data.Insert_response.forEach(item => {
          messages.push('Sales Insert Response: ' + item.message);
        });
      }
  
      if (messages.length > 0) {
        alert(messages.join('\n'));
      } else {
        alert('No sales responses to display.');
      }
    })
    .catch(error => {
      console.error('Sales Data Error:', error);
      alert('Sales Data Error: ' + error.message);
    })
    .finally(() => {
      setIsUploadLoading(false);
      setButtonText('Sales Upload');
    });
  }
  
  function handleReturnData() {
    setIsReturnUploadLoading(true);
    setButtonTextReturn('Loading...');
    const token = sessionStorage.getItem('access-token');
  
    const apiUrl = `${apiBaseUrl}/api/import-data/runReturn`;
  
    fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(response => response.json())
    .then(data => {
      let messages = [];
  
      if (data.Import_response?.original?.message) {
        messages.push('Return Import Response: ' + data.Import_response.original.message);
      }
      if (Array.isArray(data.Insert_response)) {
        data.Insert_response.forEach(item => {
          messages.push('Return Insert Response: ' + item.message);
        });
      }
  
      if (messages.length > 0) {
        alert(messages.join('\n'));
      } else {
        alert('No return responses to display.');
      }
    })
    .catch(error => {
      console.error('Return Data Error:', error);
      alert('Return Data Error: ' + error.message);
    })
    .finally(() => {
      setIsReturnUploadLoading(false);
      setButtonTextReturn('Return Upload');
    });
  }
  
  
  





  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);

    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile && isExcelFile(droppedFile)) {
      setFile(droppedFile);
    } else {
      showModal('Please select a valid Excel or CSV file (.xls, .xlsx, .csv).');
    }
  };

  return (
    <div style={containerStyle}>
      {/* <Modal isOpen={modal.isOpen} message={modal.message} onClose={closeModal} /> */}
      <div style={{ marginBottom: '40px',marginTop:'108px' }} >

        <label htmlFor="sales1" className='radio'>
          <input type="radio" id="sales1" name="uploadType" value="sales1" checked={uploadType === 'sales1'} onChange={() => setUploadType('sales1')} />
          <span></span>
          Sales Data 01
        </label>
        <label htmlFor="sales" className='radio' style={{ marginLeft: '40px' }} >
          <input type="radio" id="sales" name="uploadType" value="sales" checked={uploadType === 'sales'} onChange={() => setUploadType('sales')} />
          <span></span>
          Sales Data
        </label>
        <label htmlFor="salesreturn" className='radio' style={{ marginLeft: '40px' }} >
          <input type="radio" id="salesreturn" name="uploadType" value="salesreturn" checked={uploadType === 'salesreturn'} onChange={() => setUploadType('salesreturn')} />
          <span></span>
          Sales Return
        </label>
        <label htmlFor="employee" className='radio' style={{ marginLeft: '40px' }} >
          <input type="radio" id="employee" name="uploadType" value="employee" checked={uploadType === 'employee'} onChange={() => setUploadType('employee')} />
          <span></span> Employee-Distributor
        </label>
        <label htmlFor="distributor" className='radio' style={{ marginLeft: '40px' }}>
          <input type="radio" id="distributor" name="uploadType" value="distributor" checked={uploadType === 'distributor'} onChange={() => setUploadType('distributor')} />
          <span></span> Distributor
        </label>

        <label htmlFor="sku" className='radio' style={{ marginLeft: '40px' }}>
          <input type="radio" id="sku" name="uploadType" value="sku" checked={uploadType === 'sku'} onChange={() => setUploadType('sku')} />
          <span></span> SKU
        </label>
      </div>


      <label htmlFor="file" className={`file-input-label ${isDragOver ? 'drag-over' : ''}`} onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
        <div className="dottedline">
          <h1 style={{ color: 'var(--primary-color)' }}>
            {isDragOver ? 'Drop here Excel file ' :
              (uploadType === 'sales' ? 'Select the Sales Data files' :
                uploadType === 'sales1' ? 'Select the Sales1 Data files' :
                  uploadType === 'employee' ? 'Select the Employee files' :
                    uploadType === 'distributor' ? 'Select the Distributor files' :
                      uploadType === 'salesreturn' ? 'Select the Return files' :
                        uploadType === 'sku' ? 'Select the SKU files' :
                          '')}
          </h1>
          <i class="bx bx-file icon" style={{ color: '#ffffff8c', fontSize: '5rem' }}></i>
          {file && <p style={{ color: 'white' }}>Selected File: {file.name}</p>} <br />
        </div>
        <input ref={fileInput} type="file" name="file" id="file" accept=".xls, .xlsx ,.csv" onChange={handleFileChange} style={{ display: 'none' }} />
      </label><br />

      <button onClick={handleFileUpload} id="uploadbtn" disabled={isLoading}>
        {isLoading ? <ClipLoader color="white" size={20} /> : 'Upload'}
      </button>

      <button id="uploadbtn" className='uploadRunBtn' onClick={handleSalesData}>
        {isUploadLoading ? <ClipLoader color="white" size={20} /> : <><i class="bi bi-file-earmark-arrow-up"></i>{buttonText}</>}
      </button>

      <button id="uploadbtn" className='uploadRunBtnReturn' onClick={handleReturnData}>
        {isReturnUploadLoading ? <ClipLoader color="white" size={20} /> : <><i class="bi bi-file-earmark-arrow-up"></i>{buttonTextReturn}</>}
      </button>
      <div className="delMisbtns" id='table-tabs'>
        <Link to='/Exeluploadpage/SalesDeletepage' style={{ background: 'var(--primary-color)', color: 'white', height: '40px', padding: '10px', borderTopRightRadius: '10px', borderTopLeftRadius: '10px', cursor: 'pointer', textDecoration: 'none', marginRight: '1px' }}>

          <div >
            <i class='bx bxs-trash shake' ></i> Delete Data
          </div>

        </Link>
        <Link to='/Exeluploadpage/MissingDataspage' style={{ background: 'var(--primary-color)', color: 'white', height: '40px', padding: '10px', borderTopRightRadius: '10px', borderTopLeftRadius: '10px', cursor: 'pointer', textDecoration: 'none', marginLeft: '1px' }}>

          <div >
            <i class='bx  bx-show-alt shake' ></i> Missing Data
          </div>

        </Link>
      </div>

    </div>
  );
}

function UploadRouting() {

  return (
    <Routes>
      <Route index element={<ExeluploadPage />} />
      <Route path="SalesDeletepage" element={<SalesDeleteForm />} />
      <Route path="MissingDataspage" element={<Missingdatas />} />
      {/* ... other routes ... */}
    </Routes>
  )

}
export { UploadRouting };
export { ExeluploadPage };
