import Select, { components } from 'react-select';

export function SelectBox({
    options=[
        {label:'select 1',value:'1'},
        {label:'select 2',value:'2'},
    ],
    components,
    styles,
    value=[],
    isMulti = false,
    closeMenuOnSelect=true,
    onChange = (value) => {console.log(value)},
    err=false,
    placeholder='Select...',
    zIndex=5,
    ...props
}) {

    return (
        <Select
            options={options}
            components={components}
            placeholder={placeholder}
            styles={
                {
                    control: (provided, state) => ({
                        ...provided,
                        backgroundColor: 'var(--sidebar-color)',
                        borderColor: err?.employee_ids ? 'red' : 'var(--primaryy-color)',
                        boxShadow: 'none',
                        cursor: 'pointer',
                        ':hover': {
                            borderColor: state.isSelected ? 'var(--primaryy-color)' : 'var(--primaryy-color)'
                        }
                    }),
                    option: (provided, state) => ({
                        ...provided,
                        color: state.isSelected ? 'white' : 'var(--text-color)',
                        backgroundColor: state.isSelected ? 'var(--primaryy-color)' : 'var(--sidebar-color)',
                        cursor: 'pointer',
                        ':active': {
                            ...provided[':active'],
                            backgroundColor: 'var(--primaryy-color)',
                            color: 'white',
                        },
                        borderBottom: 'solid 1px rgba(128, 128, 128, 0.658)',
                        // zIndex:1
                        // ':focus': {
                        //   ...provided[':focus'],
                        //   backgroundColor: 'red',
                        //   color: 'white',
                        // }
                    }),
                    singleValue: (provided) => ({
                        ...provided,
                        color: 'var(--text-color)',
                    }),
                    menu: (provided) => ({
                        ...provided,
                        backgroundColor: 'var(--sidebar-color)',
                    }),
                    input: (provided) => ({
                        ...provided,
                        color: 'var(--text-color)',
                    }),
                    valueContainer: (base) => ({
                        ...base,
                        overflow: 'auto', // Enable scrolling
                        maxHeight: '200px', // Set a maximum height
                    }),
                    container:(base)=>({
                        ...base,
                        zIndex:zIndex
                    })


                }
            }
            value={value}
            isMulti={isMulti}
            closeMenuOnSelect
            onChange={(value)=>onChange(value)}
            {...props}
        />
    )


}