import React, { useState } from 'react';
import axios from 'axios';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { CustomButton2, CustomDatePicker, SelectCustom } from '../adminComponents/adminComponents';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { toastmsg, toastmsgsuccess } from '../Toasts/toasts';
import { toast } from 'react-toastify';
import { MenuItem } from '@mui/material';
import dayjs from 'dayjs';
import { apiBaseUrl } from '../../static/Apiname/api';
import { useModal } from '../ModalToast/ToastModal';

const Missingdatas = () => {
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [data, setData] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const {showModal} = useModal();
    const handleShowData = async () => {
        try {
            const apiUrl = `${apiBaseUrl}/api/get-data/missing_emp_map`;
            const token = sessionStorage.getItem('access-token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            const params = {
                from_date: dayjs(fromDate).format('YYYY-MM-DD'),
                to_date: dayjs(toDate).format('YYYY-MM-DD'),
            };

            const response = await axios.post(apiUrl, params, config);
            if (response.data.data.length === 0) {
                // No data found, show toast message
                showModal('No Missing data found');
                return; // Return early to prevent further execution
            }
            setData(response.data.data); // Assuming the data is in response.data.data
            setShowTable(true);
            showModal('Data fetched successfully:','success', response.data);
        } catch (error) {
            showModal('Error fetching data', error);
        }
    }; 

    return (

        <div className='home'>
            <div className="app card" id='chartCard' style={{justifyContent:'start'}}>
            <div className='salesdetete_Maindiv'>
                <table className='sales-delete-table'>
                    <tbody>
                        <tr id="DeleteTableRow">
                            <td>
                                <label style={{ color: 'var(--text-color)', fontWeight: 'bold' }}>From Date:</label>
                            </td>
                            <td>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <CustomDatePicker
                                        value={fromDate}
                                        onChange={(e) => setFromDate(e)}
                                        format="YYYY-MM-DD"
                                    />
                                </LocalizationProvider>
                            </td>
                        </tr>
                        <tr id="DeleteTableRow">
                            <td>
                                <label style={{ color: 'var(--text-color)', fontWeight: 'bold' }}>To Date:</label>
                            </td>
                            <td>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <CustomDatePicker
                                        value={toDate}
                                        onChange={(e) => setToDate(e)}
                                        format="YYYY-MM-DD"
                                    />
                                </LocalizationProvider>
                            </td>
                        </tr>
                        <tr id="DeleteTableRow" style={{ textAlign: 'center' }}>
                            <td colSpan="2"  >
                                <CustomButton2 variant="contained" type="submit" onClick={handleShowData}>
                                    Show Missing Data
                                </CustomButton2>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {showTable && (
                <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center' }}>
                    <h3 style={{ color: 'var(--text-color)' }}>Data Table</h3>
                    <table style={{
                        borderCollapse: 'collapse', // Ensures borders are neat
                        width: '90%', // Adjusts the width of the table
                        border: '1px solid black' // Applies the border to the entire table
                    }}>
                        <thead>
                            <tr style={{
                                color: 'white',
                                backgroundColor: 'var(--primary-color)',
                                textAlign: 'center' // Center-aligns header text
                            }}>
                                <th style={{ border: '1px solid var(--text-color)' }}>Doc Date</th>
                                <th style={{ border: '1px solid var(--text-color)' }}>Distributor Code</th>
                                <th style={{ border: '1px solid var(--text-color)' }}>Product Code</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index} style={{ color: 'var(--text-color)', textAlign: 'center' }}> {/* Center-aligns body text */}
                                    <td style={{ border: '1px solid var(--text-color)' }}>{item.FT01_DocDate}</td>
                                    <td style={{ border: '1px solid var(--text-color)' }}>{item.FT01_DistributorCode}</td>
                                    <td style={{ border: '1px solid var(--text-color)' }}>{item.FM03_ProductCode}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            )}
        </div>
        </div >
    );
};

export default Missingdatas;
