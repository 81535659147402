import {createSlice} from '@reduxjs/toolkit'


const initialState = {
    value:{
        userDetails:{

        }

    }
}
const userDetailsSlice = createSlice(
    {
        name:'userSlice',
        initialState,
        reducers:{
            setUserDetails:(state,action)=>{
                state.value.userDetails = action.payload;
            },
        }
    }
)

export const {setUserDetails} = userDetailsSlice.actions;
export const userDetailsReducer = userDetailsSlice.reducer;