import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AlertModal, ModalSetting } from "../../Modal";
import { ClipLoader } from "react-spinners";
import { FormLabel, Modal } from "react-bootstrap";
import { AntTab, AntTabs, CheckBoxCustom, CustomButton, CustomButton2, CustomDatePicker, CustomSwitch, InputCustom, SelectCustom } from "../../../adminComponents/adminComponents";
import { Button, Card, CardActions, CardContent, CardMedia, Checkbox, FormControl, FormControlLabel, Hidden, InputLabel, ListItem, MenuItem, Select, Switch, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Typography, styled } from '@mui/material'
import { Link, NavLink, Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { EditVideoLink } from "./editVideoModal";
import axios from "axios";
import { adminApi } from "../../../../static/Apiname/api";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from "dayjs";

export const videoList = [
    {
        id: 1,
        description: 'product launch',
        socialMedia: 'YouTube',
        link: 'https://www.youtube.com/watch?v=YzaJ1D2w2jc',
        activeDays: '2',
        videoDuration: '00:00:8',
        isActive: true,
        statusDate: new Date(),
    },
    {
        id: 2,
        description: 'advertisment-1',
        socialMedia: 'YouTube',
        link: 'https://youtu.be/n2bN7vnvD9w?si=YPT37X6qMo9T2sql',
        activeDays: '2',
        videoDuration: '00:00:11',
        isActive: true,
        statusDate: new Date(),
    },
    {
        id: 3,
        description: 'advertisment-1',
        socialMedia: 'YouTube',
        link: 'https://youtu.be/sItk0ASEQnA?si=MvfspB0vRKlp7dYl',
        activeDays: '1',
        videoDuration: '00:00:10',
        isActive: true,
        statusDate: new Date('2023-10-15'),
    },
    {
        id: 4,
        description: 'advertisment-1',
        socialMedia: 'YouTube',
        link: 'https://youtu.be/TMtZ0Ao9PF4?si=Cfhk0KdduaPdYk5Q',
        activeDays: '2',
        videoDuration: '00:00:7',
        isActive: true,
        statusDate: new Date('2023-10-14'),
    },

]

export function VideoEditModal() {





    const [isActive, setActive] = useState(true)
    const [isFormActive, setFormActive] = useState(false)
    const [showLoadingModal, setShowLoadingModal] = useState(false)
    const [videoList, setVideoList] = useState([])
    const [updateList, setUpdateList] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const [err, setErr] = useState(false)
    const [statusDate, setStatusDate] = useState(dayjs(new Date()))
    const {
        reset,
        register,
        handleSubmit,
        formState: { errors },
        getValues,
    } = useForm();
    const [value, setValue] = React.useState(0);
    // const watchFields = watch()
    const dayValidation = (value) => {
        if (value <= 0) {
            return "not valid";
        }
        return true;
    }

    function validatetime() {
        const values = getValues();
        let { second, minute, hour } = values.VideoDuration;

        second = Number(second);
        minute = Number(minute)
        hour = Number(hour)
        if ((second > 0 || minute > 0 || hour > 0) && (second >= 0 && minute >= 0 && hour >= 0)) {
            return true
        }

        if (second > 59 || minute > 59 || hour > 23 || second <= 0 || minute < 0 || hour < 0) {
            return 'not valid'
        }

        return true;

    }

    const validation = {
        description: [
            'Description',
            {
                required: 'cannot be empty'
            }
        ],
        socialMedia: [
            'SocialMediaName',
            {
                required: 'cannot be empty'
            }
        ],
        link: [
            'Link',
            {
                required: 'cannot be empty'
            }
        ],
        activeDays: [
            'ActiveHours',
            {
                required: 'cannot be empty',
                validate: dayValidation
            }
        ],
        StatusDate: [
            'StatusDate',
            {
                required: 'cannot be empty',
            }
        ],
        videoDuration: [
            [
                'VideoDuration.hour',
                {
                    validate: validatetime
                }
            ],
            [
                'VideoDuration.minute',
                {
                    validate: validatetime
                }
            ],
            [
                'VideoDuration.second',
                {
                    validate: validatetime,
                }
            ],
        ],
    }

    function convert_date_to_string(date) {
        // Get the year, month, and day from the date object.
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        // Pad the month and day with zeros, if necessary.
        const paddedMonth = month.toString().padStart(2, '0');
        const paddedDay = day.toString().padStart(2, '0');

        // Construct a string in the format 'YYYY-MM-DD'.
        return `${year}-${paddedMonth}-${paddedDay}`;
    }

    function onAddVideo(formData) {

        let date = dayjs(statusDate).format('YYYY-MM-DD')


        function formatTime(timeObject) {
            const formattedTime = `${String(timeObject.hour).padStart(2, '0')}:${String(timeObject.minute).padStart(2, '0')}:${String(timeObject.second).padStart(2, '0')}`;
            return formattedTime;
        }


        const data = {
            ...formData,
            VideoDuration: formatTime(formData.VideoDuration),
            Status: isFormActive === true ? 1 : 0
        }

        let realData = {}

        for (const key in data) {
            realData[`${'FM12_'}${key}`] = data[key]
        }
        realData = {
            ...realData,
            FM12_StatusDate: date
        }

        console.log('data is', realData);

        setShowLoadingModal(true);
        axios.post(
            `${adminApi}/video-link/create`, {
            ...realData
        }
        ).then(
            (res) => {
                console.log('successfull');
                setErr(false)
                setShowLoadingModal(false)
                setValue(0)
                reset();
                updateVideoList()
            }
        ).catch(
            (err) => {
                console.log('created video failed');
                setShowLoadingModal(false)
                setIsLoading(false)
                setErr(true)
            }
        )


        // const finalData = {
        //     id: videoList[videoList.length - 1].id + 1,
        //     ...data,
        //     isActive: isFormActive,
        //     statusDate: new Date()
        // }
        // setShowLoadingModal(true)
        // videoList.push(finalData)
        // setTimeout(() => {
        //     setShowLoadingModal(false)
        // }, 1000)
        // setValue(0);
        // console.log(videoList);
    }
    function onValidationErr(err) {
        console.log(err);
    }

    function onChangeValueChange(event) {
        setFormActive(event.target.checked)
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    function handleOnActiveChange(event) {
        setActive(event.target.value)
    }

    function onDeleteVideo() {
        setShowDeleteAlert(false)
        setIsLoading(true)
        axios.delete(
            `${adminApi}/video-link/delete/${deleteId}`
        ).then(
            (res) => {
                setIsLoading(false);
                updateVideoList();
                console.log('succesfully deleted item');
            }
        ).catch(
            (err) => {
                setErr(true)
                setIsLoading(false);
                setShowLoadingModal(false)

                console.log('error on deleting item')
            }
        )
    }

    function onClickDeleteButton(id) {
        setDeleteId(id);
        setShowDeleteAlert(true)
    }





    function updateVideoList() {
        setUpdateList(!updateList)
    }

    useEffect(
        () => {
            setIsLoading(true)
            axios.get(`${adminApi}/video-link/list/${isActive ? 'A' : 'I'}`).then(
                (res) => {
                    setVideoList(res.data)
                    setErr(false)
                    setIsLoading(false)
                }
            ).catch(
                (err) => {
                    console.log(err);
                    setErr(true)
                    setIsLoading(false)
                    setShowLoadingModal(false)
                }
            )
        },
        [isActive, updateList]
    )

    // console.log('this is video list',videoList);

    return (<>
        <AlertModal show={showLoadingModal}>
            <ClipLoader color='white' size={30} />
        </AlertModal>
        <AlertModal show={showDeleteAlert} onHide={() => setShowDeleteAlert(false)}>
            <Modal.Body style={{ background: 'white', borderRadius: '12px', display: 'flex', flexDirection: 'column', justifyContent: 'end', alignItems: 'end' }}>
                <p>Are you sure to delete?</p>
                <CustomButton2 variant="contained" onClick={onDeleteVideo}>Yes</CustomButton2>
            </Modal.Body>
        </AlertModal>
        <AlertModal show={err} onHide={() => setErr(false)}>
            <Modal.Body style={{ background: 'white', borderRadius: '12px', display: 'flex', flexDirection: 'column', justifyContent: 'end', alignItems: 'end' }}>
                <p>Something Went Wrong !!!!</p>
            </Modal.Body>
        </AlertModal>
        <ModalSetting >
            <Modal.Header className='header' closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Video Settings
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='body'>
                <AntTabs centered value={value} onChange={handleChange} aria-label="disabled tabs example">
                    <AntTab style={{ color: 'var(--text-color)' }} label="LIST" />
                    <AntTab style={{ color: 'var(--text-color)' }} label="ADD" />
                </AntTabs>
                {

                    <>

                        <div className='adminVideoSettingList' style={{ minHeight: '300px', display: value === 0 ? 'block' : 'none' }}>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <div className='topSelectOption'>
                                    <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
                                        {/* <InputLabel id="demo-simple-select-standard-label">Age</InputLabel> */}

                                        <SelectCustom
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            value={isActive}
                                            onChange={handleOnActiveChange}

                                        >
                                            <MenuItem value={true}>Active</MenuItem>
                                            <MenuItem value={false}>None Active</MenuItem>
                                        </SelectCustom>
                                    </FormControl>
                                </div>
                            </div>
                            {
                                isLoading === false ?
                                    videoList.length === 0 ?
                                        (
                                            <div className="noListFound">No Video Found</div>
                                        )
                                        :
                                        <Table width={'100%'}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ padding: 0 }}></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell align='left' sx={{ padding: 0 }} className='textColor'>Active Days</TableCell>
                                                    <TableCell align='left' sx={{ padding: 0 }} className='textColor'>Duration</TableCell>
                                                    <TableCell sx={{ padding: 0, width: '35px' }}></TableCell>
                                                    <TableCell sx={{ padding: 0, width: '35px' }}></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>


                                                {
                                                    videoList.map(
                                                        (video) => (
                                                            <>
                                                                <TableRow key={`${video.id}F`}>
                                                                    <TableCell style={{ padding: 0 }} align='center' rowSpan={2}>
                                                                        <CheckBoxCustom style={{ padding: '0', margin: 0 }} checked={video.FM12_Status == 1 ? true : false} />
                                                                    </TableCell >
                                                                    <TableCell align='left' sx={{ padding: 0, border: 'none', paddingTop: '5px' }}>
                                                                        <a className='link' href={video.FM12_Link}>
                                                                            {video.FM12_Description}
                                                                        </a >
                                                                    </TableCell >
                                                                    <TableCell rowSpan={1} style={{ padding: 0 }} sx={{ border: 'none' }}>
                                                                        <span className='textColor'>{video.FM12_ActiveHours} </span>
                                                                    </TableCell >
                                                                    <TableCell sx={{ padding: 0 }} align='left' rowSpan={2}>
                                                                        <span className='textColor'>{video.FM12_VideoDuration}</span>
                                                                    </TableCell >
                                                                    <TableCell align='center' rowSpan={2} sx={{ padding: 0 }}>
                                                                        <Link to={`edit/${video.id}`} className='deleteButton'><i class="bi bi-pencil-fill textColor"></i></Link>
                                                                    </TableCell >
                                                                    <TableCell align='center' rowSpan={2} sx={{ padding: 0 }}>
                                                                        {/* <div className='deleteButton' onClick={() => onDeleteVideo(video.id)}><i class="bi bi-trash-fill textColor"></i></div> */}
                                                                        <div className='deleteButton' onClick={() => onClickDeleteButton(video.id)}><i class="bi bi-trash-fill textColor"></i></div>
                                                                    </TableCell >
                                                                </TableRow>
                                                                <TableRow key={`${video.id}S`}>
                                                                    <TableCell align='left' sx={{ padding: 0, fontSize: '12px', paddingBottom: '5px' }} >
                                                                        <span className='textColor'>{video.FM12_SocialMediaName}</span>
                                                                    </TableCell >
                                                                    <TableCell align='left' sx={{ padding: 0, fontSize: '10px', paddingBottom: '5px', }} >
                                                                        <span className='textColor'>From {video.FM12_StatusDate}</span>
                                                                    </TableCell >
                                                                </TableRow>
                                                            </>
                                                        )
                                                    )
                                                }

                                            </TableBody>
                                        </Table>
                                    : <div>Loading....</div>

                            }
                        </div>

                        <form style={{ display: value === 1 ? 'block' : 'none' }} className='adminVideoSettingForm' onSubmit={handleSubmit(onAddVideo, onValidationErr)}>

                            <div className='inutContainer'>
                                <FormLabel>Description</FormLabel>
                                <InputCustom fullWidth label="Description" name='description'
                                    {...register(...validation.description)} variant="outlined"
                                    error={errors.Description ? true : false}
                                    helperText={errors.Description?.message}
                                />
                            </div>
                            <div className='inutContainer'>
                                <FormLabel>Social Media</FormLabel>
                                <FormControl fullWidth>
                                    {/* <InputLabel id="demo-simple-select-label">name</InputLabel> */}
                                    <SelectCustom
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        // label="Age"
                                        name='socialMedia'
                                        {...register(...validation.socialMedia)}
                                        error={errors.SocialMedia ? true : false}
                                        helperText={errors.SocialMedia?.message}
                                        defaultValue={'YouTube'}
                                        style={
                                            {
                                                width: '250px',
                                                height: '55px',
                                                paddingLeft: '10px',
                                            }
                                        }
                                    >
                                        <MenuItem value={'YouTube'}>YouTube</MenuItem>
                                        {/* <MenuItem value={'Facebook'}>Facebook</MenuItem>
                                        <MenuItem value={'Instagram'}>Instagram</MenuItem> */}
                                    </SelectCustom>
                                </FormControl>
                            </div>
                            <div className='inutContainer'>
                                <FormLabel>Link</FormLabel>
                                <InputCustom fullWidth label="link" name='link'
                                    variant="outlined"
                                    {...register(...validation.link)}
                                    error={errors.Link ? true : false}
                                    helperText={errors.Link?.message}
                                />
                            </div>
                            <div className='inutContainer' style={{ width: '150px' }}>
                                <FormLabel>Active Days</FormLabel>
                                <InputCustom type='number' fullWidth label="Days" variant="outlined"
                                    {...register(...validation.activeDays)}
                                    error={errors.ActiveHours ? true : false}
                                    helperText={errors.ActiveHours?.message}
                                />
                            </div>
                            <div className='inutContainer' style={{ width: '150px' }}>
                                <FormLabel>Active From</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <CustomDatePicker defaultValue={statusDate}
                                        onChange={setStatusDate}
                                        format="YYYY-MM-DD"
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className='inutContainer' style={{ width: '150px' }}>
                                <FormLabel>Video Duration</FormLabel>
                                <div className="durationContainer">
                                    <InputCustom type='number' label='hour'
                                        {...register(...validation.videoDuration[0])}
                                        error={errors.VideoDuration?.hour ? true : false}
                                        helperText={errors.VideoDuration?.hour?.message}
                                    />
                                    <InputCustom type='number' label='minute'
                                        {...register(...validation.videoDuration[1])}
                                        error={errors.VideoDuration?.minute ? true : false}
                                        helperText={errors.VideoDuration?.minute?.message}
                                    />
                                    <InputCustom type='number' label='second'
                                        {...register(...validation.videoDuration[2])}
                                        error={errors.VideoDuration?.second ? true : false}
                                        helperText={errors.VideoDuration?.second?.message}
                                    />
                                </div>
                                {/* <input className='inputTypeTime' type='time' step={1}
                                    {...register(...validation.videoDuration)}
                                    error={errors.videoDuration ? true : false}
                                    min={'00:00:00'} max={'23:59:59'}></input>
                                <label className='errText'>{errors.videoDuration?.message}</label> */}
                            </div>
                            <FormControlLabel

                                control={<CustomSwitch
                                    name='isActive' checked={isFormActive} onChange={onChangeValueChange} value={isFormActive} color="primary" />}
                                label="Is Active"
                                labelPlacement="start"
                            />

                            <div className='button' >
                                <CustomButton variant="contained" type="submit">Add</CustomButton>
                            </div>

                        </form>
                    </>
                }
            </Modal.Body>

        </ModalSetting >
        <Routes>
            <Route path='edit/:id' element={<EditVideoLink />}></Route>
        </Routes>
    </>
    )
}