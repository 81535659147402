import '../../index.css';
import './Login.css';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDarkMode } from '../Darktheme/DarkModeContext';
import logoo from '../../Assets/images/vstarLogo.svg'
import axios from 'axios';
import { apiBaseUrl } from '../../static/Apiname/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toastmsg } from '../Toasts/toasts';
import OtpInput from 'react-otp-input';
import ClipLoader from "react-spinners/ClipLoader";
function LoginPage() {
  const [email, setUsername] = useState('');
  const [otp, setOtp] = useState('');
  const [loginStatus, setLoginStatus] = useState('');
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [sidebarClosed, setSidebarClosed] = useState(false);
  const [sidebarHide, setSidebarHide] = useState(false);
  const { isDarkMode, toggleDarkMode } = useDarkMode();
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [isValidOTP, setIsValidOTP] = useState(false);
  const [loading, setLoading] = useState(false);


  const navigate = useNavigate();
  const handleOTPverification = async (e) => {
    e.preventDefault()
    try {

      const response = await axios.post(`${apiBaseUrl}/api/verify-otp`, {
        email,
        otp
      },);
      console.log(response);
      if (response.data.message === 'You have successfully logged in') {
        const token = response.data.access_token;
        console.log(token);
        sessionStorage.setItem('access-token', token)
        navigate('/App')
        // setShowOtpForm(true); 
      }
    } catch (error) {
      console.error('Error:', error);
      console.error('Response Data:', error.response.data);
      toastmsg(error.response.data.message)
      setOtp('');
      // setLoginStatus('Invalid Username or Password');
    }
  }
  const handleGenerateOTP = (e) => {
    if (!email) {
      toastmsg('Please Enter Email Address!');
      return;
    }
    const emailFormatIsValid = /\S+@\S+\.\S+/.test(email); // Simple email format validation

    if (emailFormatIsValid) {
      handleLogin(e);

    } else {
      toastmsg('Please Enter Valid Email Address!!');
      e.preventDefault()
      return;
    }
  };
  const button = (
    <button
      disabled={loading}
      onClick={handleGenerateOTP}
      className="Lbutton"

    >
      <span data-loading-text="Loading..." className='LoadinganimationOTP'>
        {loading ? <span><ClipLoader color='white' size={25} /></span> : <span >Genarate OTP</span>}
      </span>
    </button>
  );


  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    if (!navigator.onLine) {
      // Handle the case when there's no internet connection
      toastmsg("No internet connection");
      setLoading(false); // Stop loading
      return; // Exit the function to prevent further execution
    }
  
    try {
      const response = await axios.post(`${apiBaseUrl}/api/generate-otp`, {
        email,
      });
  
      if (response.data.message === 'OTP sent') {
        setShowOtpForm(true);
        
      }
    } catch (error) {
      console.error('Error:', error);
      console.error('Response Data:', error.response.data);
      toastmsg(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };
  
  const toggleFullscreen = () => {

    if (!isFullscreen) {
      const docEl = document.documentElement;

      if (docEl.requestFullscreen) {
        docEl.requestFullscreen();
      } else if (docEl.mozRequestFullScreen) {
        docEl.mozRequestFullScreen();
      } else if (docEl.webkitRequestFullscreen) {
        docEl.webkitRequestFullscreen();
      } else if (docEl.msRequestFullscreen) {
        docEl.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }

    setIsFullscreen(!isFullscreen);
  };
  const toggleSidebar = () => {
    setSidebarClosed(!sidebarClosed);
  };
  const toggleSidebarHide = () => {
    setSidebarHide(!sidebarHide);
  }


  const toggleNav = () => {
    setIsNavigationOpen(!isNavigationOpen);
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  }, []);

  const handleBackIconClick = () => {
    setShowOtpForm(false);
  };

  return (
    <div className={`App ${sidebarClosed ? 'sidebar-closed' : ''} ${isDarkMode ? 'dark' : ''}`}>

      <section class="Lhome" style={{height:'1px'}} >
        <div class="mainDiv"  >

          <ToastContainer />

          <div className="bodyMain">

            <div className="background">
              <div className="shape" />
              <div className="shape" />
            </div>

            <form className="Lform"  >
              <img src={logoo} alt="" style={{ paddingBottom: '17px' }} />
              <h3>Login</h3>
              {showOtpForm ? (
                <>
                  <Link>
                    <i class="bi bi-arrow-left-short" id='back-icon' onClick={handleBackIconClick} ></i>
                  </Link>
                  <input
                    className="Linput"
                    type="text"
                    placeholder="email"
                    id="otp"
                    value={email}
                    onChange={(event) => setOtp(event.target.value)}
                    readOnly
                    style={{ textAlign: 'center' }}
                    autocomplete="off"
                  />
                  <label className="Llabel" htmlFor="otp" style={{ marginBottom: '8px' }}>
                    OTP
                  </label>
                  <OtpInput
                    shouldAutoFocus={true}
                    containerStyle={{ justifyContent: 'space-between' }}
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span></span>}
                    renderInput={(props, index) => (
                      <input
                        {...props}
                        type="number"

                        style={{
                          width: '40px',
                          height: '50px',
                          textAlign: 'center',
                          fontSize: '16px',
                          borderRadius: '6px',
                          border: '1px solid #ffffff3d',
                          backgroundColor: '#ffffff12',

                          // Add other styles as needed
                        }}
                        key={index}
                        
                      />
                    )}
                  />





                  <button className="Lbutton" onClick={handleOTPverification}  >
                    Login
                  </button>
                </>
              ) : (
                <>
                  <Link to='/'>
                    <i class="bi bi-arrow-left-short" id='back-icon' onClick={handleBackIconClick} ></i>
                  </Link>
                  <label className="Llabel" htmlFor="username">
                    Username
                  </label>
                  <input
                    className="Linput"
                    style={{ textAlign: 'center' }}
                    type="email"
                    placeholder="Enter Email"
                    id="email"
                    value={email}
                    onChange={(event) => setUsername(event.target.value)}
                    required
                    autocomplete="off"
                  />
                  {button}
                </>
              )}
              <p style={{ color: 'red' }}>{loginStatus}</p>

            </form>



          </div>



        </div>

      </section>
      <div id='settingsTabLanding' onClick={toggleNav}>
        <i class='bx bx-cog' id="settingsicon" ></i>
      </div>

      <div className={`navigation ${isNavigationOpen ? 'open' : ''}`} id="navigation">

        <div class="themehead">
          <span >Theme Settings</span>
          <span className="close-icon" onClick={toggleNav}>
            <i className='bx bx-x'></i>
          </span>

        </div>
        <div class="theme">
          <span >Theme </span>
          <div class="lightdarkbox">
            <div className="lightmode" onClick={() => toggleDarkMode(false)}>
              <i class='bx bx-sun lightt' ></i>


            </div>
            <div className="darkmode" onClick={() => toggleDarkMode(true)}>
              <i class='bx bx-moon darkk' ></i>

            </div>
          </div>
        </div>
        <div class="theme">
          <span >Full Screen</span>
          <div class="sidenavbox">

            <div className="navhide" onClick={toggleFullscreen}>



              {isFullscreen ? <i class='bx bx-exit-fullscreen'></i> : <i class='bx bx-fullscreen '></i>}

            </div>

            {isFullscreen ? <span>Exit</span> : <span></span>}


          </div>
        </div>
      </div>

      {isNavigationOpen && (
        <div className="overlay" onClick={toggleNav}>
          <p>This is the overlay content.</p>
        </div>
      )}

    </div>

  );
}

export default LoginPage;
