import React, { useEffect, useState } from 'react';
import Chart from 'chart.js/auto';

export default function PieChart({ topData }) {
  const [chartData, setChartData] = useState(null); // State for chart data
  useEffect(() => {
    if (topData.rows) {
      const names = topData.rows.map((row) => row.name);
      const Totals = topData.rows.map((row) => {
        // Remove commas and convert to integer
        return parseInt(row.total.VAL_BTD.replace(/,/g, ''), 10);
      });
      console.log(names);
      console.log(Totals);
      setChartData({
        labels: names, // Set the chart data labels as an array of names
        datasets: [
          {
            label: 'My First Dataset',
            data: Totals, // Use the converted integers
            backgroundColor: ['#008577', '#419b45', '#bac430', '#f7ad00'],
            hoverOffset: 4,
          },
        ],
        
      });
    }
  }, [topData.rows]);

  useEffect(() => {
    const pieChartCanvas = document.getElementById('pieChart');
    if (pieChartCanvas) {
      const ctx = pieChartCanvas.getContext('2d');
      const existingChart = Chart.getChart(ctx);

      // Destroy the existing chart if it exists
      if (existingChart) {
        existingChart.destroy();
      }

      if (chartData) {
        new Chart(ctx, {
          type: 'pie',
          data: chartData, 
          options: {
            plugins: {
                legend: {
                    labels: {
                        // This more specific font property overrides the global property
                        font: {
                            size: 14,
                            color:'#4242'
                        }
                    }
                }
            }
        }// Use the dynamically set chart data
        });
      }
    }
  }, [chartData]);

  return (
    <div className="col-lg-6">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Pie Chart</h5>

          {/* Pie Chart */}
          <canvas id="pieChart" style={{ maxHeight: '400px' }}></canvas>
          {/* End Pie Chart */}
        </div>
      </div>
    </div>
  );
}
