import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AlertModal, ModalSetting } from "../../Modal";
import { ClipLoader } from "react-spinners";
import { FormLabel, Modal } from "react-bootstrap";
import { AntTab, AntTabs, CheckBoxCustom, CustomButton, CustomButton2, CustomDatePicker, CustomSwitch, InputCustom, SelectCustom } from "../../../adminComponents/adminComponents";
import { FormControl, FormControlLabel, Hidden, InputLabel, ListItem, MenuItem, Select, Switch, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Typography, styled } from '@mui/material'
import { Link, NavLink, Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { videoList } from "./videoEditSettigs";
import axios from "axios";
import { adminApi } from "../../../../static/Apiname/api";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";



export function EditVideoLink() {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [formValue, setFormValue] = useState({});
    const [isErr, setisErr] = useState(false);
    const [isActiveChanged, setIsActiveChanged] = useState(false);
    const [showLoadingModal, setShowLoadingModal] = useState(true);
    const [statusDate, setStatusDate] = useState(dayjs(new Date()))
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        reset
    } = useForm();

    const [show, setShow] = useState(true)
    const navigate = useNavigate()
    const onExited = () => {
        navigate(-1);
    }
    // console.log(formData);

    const dayValidation = (value) => {
        if (value <= 0) {
            return "not valid";
        }
        return true;
    }

    function validatetime() {
        const values = getValues();
        let { second, minute, hour } = values.VideoDuration;

        second = Number(second);
        minute = Number(minute)
        hour = Number(hour)
        if ((second > 0 || minute > 0 || hour > 0) && (second >= 0 && minute >= 0 && hour >= 0)) {
            return true
        }

        if (second > 59 || minute > 59 || hour > 23 || second <= 0 || minute < 0 || hour < 0) {
            return 'not valid'
        }

        return true;

    }

    const validation = {
        description: [
            'Description',
            {
                required: 'cannot be empty'
            }
        ],
        socialMedia: [
            'SocialMediaName',
            {
                required: 'cannot be empty'
            }
        ],
        link: [
            'Link',
            {
                required: 'cannot be empty'
            }
        ],
        activeDays: [
            'ActiveHours',
            {
                required: 'cannot be empty',
                validate: dayValidation
            }
        ],
        videoDuration: [
            [
                'VideoDuration.hour',
                {
                    validate: validatetime
                }
            ],
            [
                'VideoDuration.minute',
                {
                    validate: validatetime
                }
            ],
            [
                'VideoDuration.second',
                {
                    validate: validatetime,
                }
            ],
        ],
    }

    // const [value, setValue] = React.useState(0);
    const [isFormActive, setFormActive] = useState(false)

    function convert_date_to_string(date) {
        // Get the year, month, and day from the date object.
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        // Pad the month and day with zeros, if necessary.
        const paddedMonth = month.toString().padStart(2, '0');
        const paddedDay = day.toString().padStart(2, '0');

        // Construct a string in the format 'YYYY-MM-DD'.
        return `${year}-${paddedMonth}-${paddedDay}`;
    }


    function onAddVideo(formData) {

        function formatTime(timeObject) {
            const formattedTime = `${String(timeObject.hour).padStart(2, '0')}:${String(timeObject.minute).padStart(2, '0')}:${String(timeObject.second).padStart(2, '0')}`;
            return formattedTime;
        }
        let date = dayjs(statusDate).format('YYYY-MM-DD')


        const data = {
            ...formData,
            VideoDuration: formatTime(formData.VideoDuration),
            Status: isFormActive === true ? 1 : 0
        }

        let realData = {}

        for (const key in data) {
            realData[`${'FM12_'}${key}`] = data[key]
        }

        realData = {
            ...realData,
            FM12_StatusDate: date
        }


        console.log('editing data', realData)

        setShowLoadingModal(true)

        axios.post(
            `${adminApi}/video-link/update/${id}`, {
            ...realData
        }
        ).then(
            (res) => {
                console.log('successfull');
                setisErr(false)
                setShowLoadingModal(false)
                onExited()
                reset();
            }
        ).catch(
            (err) => {
                console.log('updated video failed');
                setShowLoadingModal(false)
                setisErr(true)
            }
        )


        // const finalData = {
        //     id: videoList[videoList.length - 1].id + 1,
        //     ...data,
        //     isActive: isFormActive,
        //     statusDate: new Date()
        // }
        // setShowLoadingModal(true)
        // videoList.push(finalData)
        // setTimeout(() => {
        //     setShowLoadingModal(false)
        // }, 1000)
        // setValue(0);
        // console.log(videoList);
    }
    function onValidationErr(err) {
        console.log(err);
    }

    function onChangeValueChange(event) {
        setIsActiveChanged(true)
        setFormActive(event.target.checked)
        console.log(event.target.value);

    }


    useEffect(
        () => {

            axios.get(
                `${adminApi}/video-link/read/${id}`
            ).then(
                (res) => {
                    const data = {}

                    for (const key in res.data) {
                        data[key.replace("FM12_", "")] = res.data[key]
                    }
                    console.log(data);
                    const duration = data.VideoDuration
                    const [hour, minute, second] = duration.split(':');
                    setFormValue({
                        ...data,
                        VideoDuration: {
                            hour: hour,
                            minute: minute,
                            second: second
                        }
                    })
                    setFormActive(data.Status == 1 ? true : false)
                    setStatusDate(dayjs(new Date(data.StatusDate)))
                    setShowLoadingModal(false)
                    setisErr(false)
                }
            ).catch(
                (err) => {
                    console.log(err);
                    setShowLoadingModal(false)
                    setisErr(true)
                }
            )


            // setFormActive(formData.isActive)
        }, []
    )

    return (
        <>
            <AlertModal show={showLoadingModal}>
                <ClipLoader color='white' size={30} />
            </AlertModal>
            <AlertModal show={isErr} onHide={() => setisErr(false)}>
                <Modal.Body style={{ background: 'white', borderRadius: '12px', display: 'flex', flexDirection: 'column', justifyContent: 'end', alignItems: 'end' }}>
                    <p>Something Went Wrong !!!!</p>
                </Modal.Body>
            </AlertModal>
            <Modal
                size="lg"
                show={show}
                onHide={() => setShow(false)}
                onExited={onExited}
                aria-labelledby="example-modal-sizes-title-lg"
                scrollable
                centered
                backdropClassName='adminSettingsModalSample'
                className="adminSettingsModal"
                style={{
                    zIndex: '13'
                }}
            >
                <Modal.Header className='header' >
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Edit
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='body'>
                    {
                        showLoadingModal === false
                            ?
                            <form className='adminVideoSettingForm' onSubmit={handleSubmit(onAddVideo, onValidationErr)}>

                                <div className='inutContainer'>
                                    <FormLabel>Description</FormLabel>
                                    <InputCustom fullWidth label="Description"
                                        {...register(...validation.description)} variant="outlined"
                                        error={errors.Description ? true : false}
                                        helperText={errors.Description?.message}
                                        defaultValue={formValue['Description']}
                                    />
                                </div>
                                <div className='inutContainer'>
                                    <FormLabel>Social Media</FormLabel>
                                    <FormControl fullWidth>
                                        {/* <InputLabel id="demo-simple-select-label">name</InputLabel> */}
                                        <SelectCustom
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            // label="Age"
                                            name='socialMedia'
                                            {...register(...validation.socialMedia)}
                                            error={errors.SocialMedia ? true : false}
                                            helperText={errors.SocialMedia?.message}
                                            defaultValue={'YouTube'}
                                        >
                                            <MenuItem value={'YouTube'}>YouTube</MenuItem>
                                            {/* <MenuItem value={'Facebook'}>Facebook</MenuItem>
                                            <MenuItem value={'Instagram'}>Instagram</MenuItem> */}
                                        </SelectCustom>
                                    </FormControl>
                                </div>
                                <div className='inutContainer'>
                                    <FormLabel>Link</FormLabel>
                                    <InputCustom fullWidth label="link" name='link'
                                        variant="outlined"
                                        {...register(...validation.link)}
                                        error={errors.Link ? true : false}
                                        helperText={errors.Link?.message}
                                        defaultValue={formValue['Link']}
                                    />
                                </div>
                                <div className='inutContainer' style={{ width: '150px' }}>
                                    <FormLabel>Active Days</FormLabel>
                                    <InputCustom type='number' fullWidth label="Days" variant="outlined"
                                        {...register(...validation.activeDays)}
                                        error={errors.ActiveHours ? true : false}
                                        helperText={errors.ActiveHours?.message}
                                        defaultValue={formValue['ActiveHours']}
                                    />
                                </div>
                                <div className='inutContainer' style={{ width: '150px' }}>
                                    <FormLabel>Active From</FormLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <CustomDatePicker defaultValue={statusDate}
                                            onChange={setStatusDate}
                                            format="YYYY-MM-DD"
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className='inutContainer' style={{ width: '150px' }}>
                                    <FormLabel>Video Duration</FormLabel>
                                    <div className="durationContainer">
                                        <InputCustom type='number' placeholder='hour'
                                            {...register(...validation.videoDuration[0])}
                                            error={errors.VideoDuration?.hour ? true : false}
                                            helperText={errors.VideoDuration?.hour?.message}
                                            defaultValue={formValue.VideoDuration.hour}
                                        />
                                        <InputCustom type='number' placeholder='minute'
                                            {...register(...validation.videoDuration[1])}
                                            error={errors.VideoDuration?.minute ? true : false}
                                            helperText={errors.VideoDuration?.minute?.message}
                                            defaultValue={formValue.VideoDuration.minute}
                                        />
                                        <InputCustom type='number' placeholder='second'
                                            {...register(...validation.videoDuration[2])}
                                            error={errors.VideoDuration?.second ? true : false}
                                            helperText={errors.VideoDuration?.second?.message}
                                            defaultValue={formValue.VideoDuration.second}
                                        />
                                    </div>
                                    {/* <input className='inputTypeTime' type='time' step={1}
                                    {...register(...validation.videoDuration)}
                                    error={errors.videoDuration ? true : false}
                                    min={'00:00:00'} max={'23:59:59'}></input>
                                <label className='errText'>{errors.videoDuration?.message}</label> */}
                                </div>
                                <FormControlLabel

                                    control={<CustomSwitch
                                        name='isActive' checked={isFormActive} onChange={onChangeValueChange} value={isFormActive} color="primary" />}
                                    label="Is Active"
                                    labelPlacement="start"
                                />

                                <div className='button' >
                                    <CustomButton2 variant="contained" type="submit">Update</CustomButton2>
                                </div>

                            </form>
                            : (<div>loading...</div>)
                    }
                </Modal.Body>
                {/* <Routes>
                <Route path='edit/:id' element={<EditModalVideo />}></Route>
            </Routes> */}
            </Modal>
        </>
    )
}
