// import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import './dashbord2.css'
import React, { useState } from 'react';
// import Chart from 'react-apexcharts';

function Dashboard2(props) {
  return (
    <div>
      {/* <div className="chartss"> */}
        <div className="app card" id='chartCard'>
          {/* <div className="row">
            <div className="mixed-chart">
              <Chart
                options={chartOptions}
                series={chartSeries}
                type="bar"
                className="chartBar"
              />
            </div>
          </div> */}
        {/* </div> */}
        {/* Uncomment the following section to add a line chart */}
        {/* <div className="app card" id='chartCard'>
          <div className="row">
            <div className="mixed-chart">
              <Chart
                options={chartOptions}
                series={chartSeries}
                type="line"
                className="chartBar"
              />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Dashboard2;


// export default Dashboard2;
