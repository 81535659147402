import React, { useEffect, useState } from 'react';
import Chart from 'chart.js/auto';

// Helper function to convert names to short forms
function convertToShortForm(name) {
  const nameParts = name.split(' ');
  if (nameParts.length >= 2) {
    return nameParts[0].charAt(0) + nameParts[1].charAt(0);
  }
  return name; // Return the original name if it couldn't be shortened
}

export default function PieChart({ topData }) {
  const [chartData, setChartData] = useState(null); // State for chart data

  useEffect(() => {
    if (topData.rows) {
      const names = topData.rows.map((row) => convertToShortForm(row.name)); // Convert names to short form
      const Totals = topData.rows.map((row) => {
        // Remove commas and convert to integer
        return parseInt(row.VAL_MTD_.replace(/,/g, ''), 10);
      });
      console.log(names);
      console.log(Totals);
      setChartData({
        labels: names, // Set the chart data labels as an array of short forms
        datasets: [
          {
            label: 'My First Dataset',
            data: Totals, // Use the converted integers
            backgroundColor: [
              '#1f77b4', '#ff7f0e', '#2ca02c', '#d62728',
              '#9467bd', '#8c564b', '#e377c2', '#7f7f7f',
              '#bcbd22', '#17becf', '#636363', '#7a4171',
              '#a55194', '#ce6dbd'
            ],
            hoverOffset: 4,
          },
        ],
      });
    }
  }, [topData.rows]);

  useEffect(() => {
    const pieChartCanvas = document.getElementById('pieChart');
    if (pieChartCanvas) {
      const ctx = pieChartCanvas.getContext('2d');
      const existingChart = Chart.getChart(ctx);

      // Destroy the existing chart if it exists
      if (existingChart) {
        existingChart.destroy();
      }

      if (chartData) {
        new Chart(ctx, {
          type: 'pie',
          data: chartData, // Use the dynamically set chart data
        });
      }
    }
  }, [chartData]);

  return (
    <div className="col-lg-6">
      <div className="card">
        <div className="card-body">
          {/* <h5 className="card-title">Pie Chart</h5> */}

          {/* Pie Chart */}
          <canvas id="pieChart" style={{ width:'880px' }}></canvas>
          {/* End Pie Chart */}
        </div>
      </div>
    </div>
  );
}
