import { Button, Card, CardActions, CardContent, CardMedia, Checkbox, FormControl, FormControlLabel, Hidden, InputLabel, ListItem, MenuItem, Select, Switch, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import '../Adminstyles.css'
import { FormLabel, Modal } from 'react-bootstrap'
import { Link, NavLink, Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { AlertModal, ModalSetting } from '../Modal'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs'
import { useForm } from 'react-hook-form'
import { refType } from '@mui/utils'
import { ClipLoader } from 'react-spinners';
import { AntTab, AntTabs, CheckBoxCustom, CustomButton, CustomButton2, CustomSwitch, InputCustom, SelectCustom } from '../../adminComponents/adminComponents'
import { VideoEditModal } from './videoEditSetting/videoEditSettigs'
import { NewsEditModal } from './newsEditModal/newsEditSetting'









export default function LandingPageSettings() {
    return (
        <div className='landingPageSettings'>

            <NavLink className='settingsCard' to='videoSetting'>
                <div class="card">
                    <h3> Video Settings <i class="bi bi-camera-reels" id='vidIcon'></i> </h3>
                    <span>Customize your website's first impression with our Landing Page Video Settings. Choose and manage engaging videos that reflect your brand and captivate your audience.</span>
                    <div className='buttonDIv'>
                        <button className='button'>Go To Settings</button>
                    </div>
                </div>
            </NavLink>
            <NavLink className='settingsCard' to='newsSetting'>
                <div class="card">
                    <h3>News Settings <i class="bi bi-newspaper" id='vidIcon'></i> </h3>
                    <span>
                        Enhance your News Page with our News Content Editor. Easily edit and update articles to keep your audience informed and engaged.
                    </span>
                    <div className='buttonDIv'>
                        <button className='button'>Go To Settings</button>
                    </div>
                </div>
            </NavLink>
            <Routes>
                <Route path='videoSetting/*' element={<VideoEditModal />}></Route>
                <Route path='newsSetting/*' element={<NewsEditModal />}></Route>
            </Routes>
        </div>
    )
}
