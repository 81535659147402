import { useState, useEffect, useRef } from 'react';
import { Link, NavLink, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { useDarkMode } from '../Darktheme/DarkModeContext';
import App from '../../App';
import imageLogo from '../../Assets/images/vstarLogo.svg'
import Dsr from '../DSR/Dsr';
import axios from 'axios';
import { apiBaseUrl } from '../../static/Apiname/api';
import { useDispatch, useSelector } from 'react-redux';
import { useRadioGroup } from '@mui/material';
import { HideTopNav, ShowTopNav, ToggleTopNav } from '../../redux/navTogle/navTogleSlice';
import { toastmsg } from '../Toasts/toasts';
import { ToastContainer } from 'react-toastify';
// import { handleLoggIn } from '../../static/functions/authentication';
import { ProtectedRoutes } from '../Routes/protectedRoutes';
import debounce from 'lodash.debounce';
import { ExeluploadPage, UploadRouting } from '../exeluploads/ExeluploadPage';
import SalesDeleteForm from '../DeleteTableData/Salesdeletefrom';
import Misingdatas from '../MissingData/Missingdata';
import Missingdatas from '../MissingData/Missingdata';
import { Employee } from '../Employees/Employee';
import { setUserDetails } from '../../redux/userReducer/userSlice';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Treeview } from '../Treeview/Treeview';
import { TreeViewList } from '../Treeview/treeView.list';


export function Topnav() {

  const mapLinks = [
    { id: 'LinkDsr', to: 'DSR', path: '/DSR/*', label: 'DSR', icon: <i class='bx bxs-bar-chart-alt-2 icon' ></i>, element: <ProtectedRoutes><Dsr /></ProtectedRoutes> },
    { id: 'LinkUploadexcel', to: 'Exeluploadpage', path: 'Exeluploadpage', label: 'Upload excel', icon: <i class='bx bx-upload icon' ></i>, element: <ProtectedRoutes><ExeluploadPage /></ProtectedRoutes> },
    { id: 'LinkEmployee', to: 'Employee', path: 'Employee', label: 'Employee', icon: <i class='bx bxs-user-badge icon' ></i>, element: <ProtectedRoutes><Employee /></ProtectedRoutes> },
    { id: 'LinkRoleCreation', to: 'Treeview', path: 'Treeview/*', label: 'Role', icon: <i class="bi bi-diagram-3 icon"></i>, element: <ProtectedRoutes><TreeViewList /></ProtectedRoutes> },
  ];

  const [isFullscreen, setIsFullscreen] = useState(false);
  // const [isNavigationOpen, setIsNavigationOpen] = useState(false);
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [username, setUsername] = useState(''); // Added state for username
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);
  const { isDarkMode, toggleDarkMode } = useDarkMode();
  const [sidebarClosed, setSidebarClosed] = useState(true);
  const [sidebarshow, setSidebarShow] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const [sidebarHide, setSidebarHide] = useState(windowWidth < 900 ? null : false);
  const navigate = useNavigate();
  const toggleNavCollapse = () => {
    setIsNavCollapsed(!isNavCollapsed);
  };
  const [allowedTables, setAllowedTabs] = useState([])

  const myDivRef = useRef(null);
  const listRef = useRef(null);
  const btnRef = useRef(null);
  const topNavRef = useRef(null)
  const isNavHide = useSelector(
    state => state.navState.value
  )
  const [isLoaded, setIsLoaded] = useState(false)

  const closeTopNav = () => {
    // Get the DOM element from the ref
    const myDiv = myDivRef.current;

    // Check if the ref is defined and the element has the class
    if (myDiv && myDiv.classList.contains('show')) {
      // Remove the class
      myDiv.classList.remove('show');
    }
  };

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      const docEl = document.documentElement;


      if (docEl.requestFullscreen) {

        setSidebarHide(true);
        dispatch(HideTopNav());
        docEl.requestFullscreen();
      } else if (docEl.mozRequestFullScreen) {
        docEl.mozRequestFullScreen();
      } else if (docEl.webkitRequestFullscreen) {
        docEl.webkitRequestFullscreen();
      } else if (docEl.msRequestFullscreen) {
        docEl.msRequestFullscreen();
      }
    } else {


      if (document.exitFullscreen) {
        setSidebarHide(false);
        dispatch(ShowTopNav());
        // setSidebarShow(true)
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }

    setIsFullscreen(!isFullscreen);

  };

  const userDetails = useSelector(
    state => state.userData.value.userDetails
  )

  const [showLogout, setShowLogout] = useState(false);
  const logoutDivRef = useRef(null);

  const toggleLogoutDiv = () => {
    setShowLogout(!showLogout);
  };

  const handleClickOutside = (event) => {
    if (logoutDivRef.current && !logoutDivRef.current.contains(event.target)) {
      setShowLogout(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // document.addEventListener('keyup', function (event) {
  //   if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
  //     // When the "Esc" key is released (keyup), setSidebarHide to false and dispatch ShowTopNav
  //     setSidebarHide(false);
  //     dispatch(ShowTopNav());
  //   }
  // });

  document.addEventListener('fullscreenchange', () => {
    if (document.fullscreenElement) {
      // The document is now in fullscreen mode
    } else {
      setSidebarHide(false);
      dispatch(ShowTopNav());
    }
  });



  const toggleSidebar = () => {
    setSidebarClosed(!sidebarClosed);
    setSidebarShow(!sidebarshow);
  };
  const toggleSidebarHide = () => {
    setSidebarHide(!sidebarHide);
    setSidebarShow(true);

  }

  const toggleNav = () => {
    setIsNavigationOpen(!isNavigationOpen);
  };

  function navbarState() {

    let state = '';


    if (sidebarHide === null && windowWidth < 900) {
      state = 'hide'
    }
    else if (windowWidth < 900 && sidebarHide === null) {
      state = 'hide'
    }
    else if (sidebarHide) {
      state = 'hide'
    } else if (sidebarClosed) {
      state = 'close'
    } else {
      state = ''
    }
    // console.log(sidebarHide);

    return state;


  }
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };


    // const handleResizeChange = debounce(() => {
    //   if (topNavRef.current.offsetHeight !== 70) {
    //     topNavRef.current.style.overflow = 'hidden';
    //   }
    // }, 50); // Adjust the debounce delay as needed

    // const resizeObserver = new ResizeObserver(entries => {
    //   entries.forEach(entry => {
    //     if (entry.target === topNavRef.current) {
    //       handleResizeChange();
    //     }
    //   });
    // });

    // resizeObserver.observe(topNavRef.current);



    // const handleTopNavonTStart = () => {
    //   // if (topNavRef.current.style.opacity === '0') {
    //   if (topNavRef.current?.offsetHeight === 70) {
    //     // console.log('60600660');
    //     topNavRef.current.style.overflow = 'visible';
    //   }
    //   // }
    // }

    // topNavRef.current.addEventListener('resize', () => {
    //   if (topNavRef.current.offsetHeight !== 70) {
    //     topNavRef.current.style.overflow = 'hidden';
    //   }
    // });
    window.addEventListener('resize', handleWindowResize);
    // topNavRef.current.addEventListener('transitionend', handleTopNavonTend)
    // topNavRef.current.addEventListener('transitionstart', handleTopNavonTStart)

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      // window.removeEventListener('transitionstart', handleTopNavonTStart);
      // topNavRef.current.removeEventListener('resize', () => {
      //   if (topNavRef.current.offsetHeight !== 70) {
      //     topNavRef.current.style.overflow = 'hidden';
      //   }
      // });
      // resizeObserver.disconnect()
      // window.removeEventListener('transitionstart', handleTopNavonTend);
    };
  }, []);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    function handleClickOutside(event) {
      if (listRef.current && !listRef.current.contains(event.target) && !btnRef.current.contains(event.target)) {
        closeTopNav()
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);



  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoaded(false)
      const token = sessionStorage.getItem('access-token')
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      if (token) {
        try {
          // Use the obtained token for subsequent requests
          const userResponse = await axios.get(`${apiBaseUrl}/api/user`, config);

          const userDetails = userResponse.data.user;
          const sentData = {
            employee_id: userDetails.id,
            // menu_program_ids: ['TabAsmwise','TabDistrictwise']
            menu_program_ids: mapLinks.map(link => link.id)
          }
          const allowResponse = await axios.post(`${apiBaseUrl}/api/user-menu/list`, sentData, config)
          const allowed = allowResponse.data.allowed_menu_program_ids;
          if (allowed) {


            const allowedtabs = mapLinks.filter(
              map => {
                for (let i = 0; i < allowed.length; i++) {
                  if (allowed[i] === map.id) {
                    return true
                  }
                }
              }
            );


            // const allowedtabs = allowed.map(
            //   tab => {



            //     const realtab = mapLinks.filter(
            //       statictab => {
            //         if (tab === statictab.id) {
            //           return true
            //         } else {
            //           return false
            //         }
            //       }
            //     )
            //     if (realtab.length > 0) {
            //       return realtab[0]
            //     } else {
            //       return null
            //     }

            //   }
            // )
            setAllowedTabs(
              allowedtabs
            )
          }

          setUsername(userDetails.FM06_EmployeeName);
          dispatch(setUserDetails(userDetails))
          setIsLoaded(true)
        } catch (error) {
          toastmsg('something went wrong')
          console.error('Error fetching user information:', error);
          setUsername('');
        }
      } else {
        console.warn('No authentication token found in sessionStorage.');
        setUsername('');
      }
    };

    fetchUserData();
  }, []);

  async function handleLogout() {
    const logout_authToken = sessionStorage.getItem('access-token')
    // Clear the token from local storage (or your chosen storage mechanism)
    try {
      const response = await axios.get(`${apiBaseUrl}/api/logout`, {
        headers: {
          Authorization: `Bearer ${logout_authToken}`,
        },

      })
      console.log(response.data);
      navigate('/login');
    }
    catch (err) {
      console.log('error', err);
      toastmsg('somthing wrong')
    }
    sessionStorage.removeItem('access-token');

    // Redirect the user to the login page or perform any other desired actions
    // You can use React Router to navigate to the login page
  }

  return (
    <>
      <div className={`App ${sidebarClosed ? 'sidebar-closed' : ''} ${isDarkMode ? 'dark' : ''}`} onClick={() => setIsNavCollapsed(true)}>
        <ToastContainer />

        <header ref={topNavRef} id="headNav" className={`nav fixed-top ${isNavHide ? 'hidden' : ''}`}>
          <nav className="navbar navbar-expand-lg " id="headnav">
            <div className="container-fluid">
              {/* {sidebarHide && (
            )} */}
              <div className="nav-toggle" onClick={
                () => {
                  setSidebarHide(false);
                  setSidebarClosed(true)
                }
              }>
                <i class='bx'></i>
                <i style={{ fontSize: '2.5rem', marginTop: '9px' }} className={`bx ${sidebarClosed
                  ?
                  'bx bx-menu-alt-left '
                  :
                  ' bx bx-menu-alt-left  '}`} />
              </div>
              {sidebarHide && (
                <Link to='/' style={{ textDecoration: 'none' }}>
                  <a className='navbar-brand' href="#">
                    <img height={30} src={imageLogo} alt="" />

                    <span style={{ fontWeight: '600' }}>Vstar</span>
                  </a>
                </Link>
              )}


              <button ref={btnRef} className="navbar-toggler" id='navbtnTog'
                type="button"
                onClick={toggleNavCollapse}
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded={!isNavCollapsed}
                aria-label="Toggle navigation">
                <i className="bi bi-list"></i>
              </button>
              <div ref={myDivRef} className={`collapse navbar-collapse flex-row-reverse ${isNavCollapsed ? 'collapsed' : ''}`} id="navbarSupportedContent" >
                <ul ref={listRef} className="navbar-nav ms-auto mb-2 mb-lg-0" id="ulnav" onClick={() => setIsNavCollapsed(true)}>
                  <li className="nav-item" style={{ fontSize: '1.8rem', alignSelf: 'center', padding: '0px', cursor: 'pointer' }}>
                    <i className={isFullscreen ? 'bx bx-exit-fullscreen fullscreen' : 'bx bx-fullscreen fullscreen'} id="fullscreenIconNav" style={{ alignSelf: 'center', fontSize: '1.8rem' }} onClick={() => { toggleFullscreen(); closeTopNav() }} />
                  </li>
                  <li className="nav-item" style={{ fontSize: '1.8rem', alignSelf: 'center', padding: '6px', cursor: 'pointer' }}>
                    <i className={isDarkMode ? 'bx bx-sun' : 'bx bx-moon'} id="fullscreenIconNav" style={{ fontSize: '1.8rem', alignSelf: 'baseline' }} onClick={() => { toggleDarkMode(); closeTopNav() }} />
                  </li>
                  <li class="nav-item" style={{ alignSelf: 'center', padding: '0px', cursor: 'pointer' }} onClick={() => dispatch(ToggleTopNav())} >
                    <i className="bx bxs-hide " style={{ fontSize: '1.5rem', paddingLeft: 10, paddingRight: 10 }} />

                  </li>
                  <li className="nav-item" style={{ alignSelf: 'center', padding: '0px', cursor: 'pointer' }} onClick={toggleLogoutDiv}>
                    <a className="nav-link disabled" aria-disabled="true">{username}</a>
                  </li>
                  <li className="nav-item" style={{ alignSelf: 'center', padding: '0px', cursor: 'pointer' }} onClick={toggleLogoutDiv}
                  >
                    <i className='bx bxs-user' id="userr"></i>
                    {showLogout && (
                  <div id="logoutDiv" ref={logoutDivRef} >
                    {/* Your logout content here */}
                    <button
                      type="button"
                      class="btn logoutPopupBtn"

                    >
                      My Profile
                    </button>
                    <button
                      type="button"
                      class="btn logoutPopupBtn"
                      onClick={handleLogout}
                    >
                      Log out
                    </button>
                    
                  </div>
                )}
                  </li>
                  
                </ul>

              </div>
            </div>
          </nav>
        </header>
        <nav className={`sidebar ${navbarState()}`}>
          <header>
            <Link to='/' style={{ textDecoration: 'none' }}>
              <div className="image-text">
                <span className="image">
                  <img src={imageLogo} alt="logoimg" />
                </span>
                <div className={`text header-text ${sidebarClosed ? 'collapsed' : ''}`}>
                  <span className="name">Vstar</span>
                  <span className="profession" id='collective-text'>Just Right For Me</span>
                </div>
              </div>
            </Link>
            <i className='bx bx-chevron-right toggle' onClick={toggleSidebar}></i>

          </header>
          <div class="menu-bar">
            <div class="menu">

              <ul class="menu-links">

                <li class="nav-links " style={{ cursor: 'pointer' }} id='hideiconSidenav' onClick={setSidebarHide} >
                  <i class='bx bxs-hide icon' ></i>
                  <span class="text nav-text " >
                    Hide Navbar
                  </span>
                </li>
                {/* <li class="nav-links " >
                  <NavLink className='sideNonActive' to={'/APP'}>
                    <i class='bx bx-home-alt icon ' ></i>
                    <span class="text nav-text " >
                      Dashboard
                    </span>
                  </NavLink>
                </li> */}
                {
                  isLoaded === false ?
                    <SkeletonTheme duration={0.5} baseColor="#f6f5ff00" highlightColor="#cfcfc8" width={'95%'}>
                      <Skeleton height={50} width={'100%'} count={4} />
                    </SkeletonTheme>
                    :
                    <>
                      <li class="nav-links" id={'LinkDashbord'}>
                        <NavLink className='sideNonActive' to={'/App'} style={isActive => ({
                          pointerEvents: isActive.isActive ? 'none' : 'auto'
                        })}>
                          <i class='bx bx-home icon' ></i>
                          <span class="text nav-text">
                            Dashboard
                          </span>
                        </NavLink>


                      </li>
                      {
                        allowedTables.map(
                          tabs => (
                            <li class="nav-links" id={tabs}>
                              <NavLink className='sideNonActive' to={tabs.to} style={isActive => ({
                                // pointerEvents: isActive.isActive ? 'none' : 'auto'
                              })}>
                                {tabs.icon}
                                <span class="text nav-text">
                                  {tabs.label}
                                </span>
                              </NavLink>


                            </li>
                          )
                        )
                      }

                      {/* <li class="nav-links" id="LinkUploadexcel">
                        <NavLink className='sideNonActive' to={"Exeluploadpage"} >
                          <i class='bx bx-upload icon' ></i>
                          <span class="text nav-text">
                            Upload excel
                          </span>
                        </NavLink>
                      </li>
                      <li class="nav-links" id="LinkEmployeeexcel">
                        <NavLink className='sideNonActive' to={"Employee"} >
                          <i class='bx bxs-user-badge icon' ></i>
                          <span class="text nav-text">
                            Employee
                          </span>
                        </NavLink>
                      </li>
                      <li class="nav-links" id='LinkRoleCreation'>
                        <NavLink className='sideNonActive' to={"Treeview"}>
                          <i class="bi bi-diagram-3 icon"></i>
                          <span class="text nav-text ">
                            Role
                          </span>
                        </NavLink>
                      </li> */}
                    </>
                }


              </ul>
            </div>
            <div class="bottom-content">
              <li class="">
                <NavLink onClick={handleLogout} style={{ textDecoration: 'none' }} className='sideNonActive'>

                  <i class='bx bx-log-out icon' ></i>
                  <span class="text nav-text">
                    Logout
                  </span>
                </NavLink>

              </li>

              <li class="mode">
                <div class="moon-sun">
                  <i class='bx bx-moon icon moon' ></i>
                  <i class='bx bx-sun icon sun' ></i>
                </div>
                <span className="mode-text text">
                  {isDarkMode ? 'Light mode' : 'Dark Mode'}
                </span>
                <div className="toggle-switch" onClick={toggleDarkMode}>
                  <span className={`switch ${isDarkMode ? 'dark' : ''}`}>

                  </span>
                </div>
              </li>
              <li className="mode" id="fullscreenBtn" onClick={toggleFullscreen} style={{ cursor: 'pointer' }}>
                <div className="moon-sun">
                  <i className={isFullscreen ? 'bx bx-exit-fullscreen fullscreen' : 'bx bx-fullscreen fullscreen'} id="fullscreenIcon" />
                </div>
                <span className="mode-text text" id="screentext">
                  {isFullscreen ? 'Exit Full Screen' : 'Full Screen'}
                </span>
                <div className="toggle-switch"></div>
              </li>
            </div>

          </div>
        </nav>

        <Routes>
          <Route path="/App" element={<App />} />
          <Route path="/Exeluploadpage/*" element={<UploadRouting />} />

          {
            allowedTables.map(
              tabs => (
                <Route path={tabs.path} element={tabs.element} />

              )
            )
          }
          {
            isLoaded ? <Route path="*" element={<div className='home'> you are not accessible this  page</div>} /> : null
          }
          {/*
          <Route path="/DSR/*" element={<ProtectedRoutes><Dsr /></ProtectedRoutes>} />
          <Route path="Exeluploadpage" element={<ProtectedRoutes><ExeluploadPage /></ProtectedRoutes>} />
          <Route path="Employee" element={<ProtectedRoutes><Employee /></ProtectedRoutes>} />
          <Route path="Treeview" element={<ProtectedRoutes><Treeview /></ProtectedRoutes>} />
        */}

          {/* <Route path="SalesDeletepage" element={<ProtectedRoutes><SalesDeleteForm /></ProtectedRoutes>} /> */}
          {/* <Route path="MissingDataspage" element={<ProtectedRoutes><Missingdatas /></ProtectedRoutes>} /> */}
        </Routes>

        <div className="settingsTab" onClick={toggleNav}>
          <i class='bx bx-cog' id="settingsicon" ></i>
        </div>

        <div className={`navigation ${isNavigationOpen ? 'open' : ''}`} id="navigation">

          <div class="themehead">
            <span >Theme Settings</span>
            <span className="close-icon" onClick={toggleNav}>
              <i className='bx bx-x'></i>
            </span>
          </div>
          <div class="theme">
            <span >Theme </span>
            <div class="lightdarkbox">
              <div className="lightmode" onClick={() => toggleDarkMode(false)}>
                <i class='bx bx-sun lightt' ></i>


              </div>
              <div className="darkmode" onClick={() => toggleDarkMode(true)}>
                <i class='bx bx-moon darkk' ></i>

              </div>
            </div>
          </div>
          <div class="theme">
            <span >Sidenav View</span>
            <div class="sidenavbox">

              {(sidebarHide || sidebarHide === null)
                ?
                <div className="navhide" onClick={() => setSidebarHide(false)}>
                  <i class='bx bxs-show'></i>
                </div>
                :
                <div className="navhide" onClick={() => setSidebarHide(true)}>
                  <i class='bx bxs-hide'></i>
                </div>
              }


              {(sidebarHide || sidebarHide === null)
                ?
                <span>Show</span>
                :
                <span>Hide</span>}


            </div>
          </div>
          <div class="theme">
            <span >Topnav View</span>
            <div class="sidenavbox">

              {(isNavHide === true)
                ?
                <div className="navhide" onClick={() => dispatch(ToggleTopNav())}>
                  <i class='bx bxs-show'></i>
                </div>
                :
                <div className="navhide" onClick={() => dispatch(ToggleTopNav())}>
                  <i class='bx bxs-hide'></i>
                </div>
              }


              {(isNavHide === true)
                ?
                <span>Show</span>
                :
                <span>Hide</span>}


            </div>
          </div>
          <div class="theme">
            <span >Full Screen</span>
            <div class="sidenavbox">

              <div className="navhide" onClick={toggleFullscreen}>



                {isFullscreen ? <i class='bx bx-exit-fullscreen'></i> : <i class='bx bx-fullscreen '></i>}

              </div>

              {isFullscreen ? <span>Exit</span> : <span></span>}


            </div>
          </div>
        </div>

        {isNavigationOpen && (
          <div className="overlay" onClick={toggleNav}>
            <p>This is the overlay content.</p>
          </div>
        )}
      </div>

    </>
  )
}

