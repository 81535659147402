import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ModalSetting } from "../../Modal";
import { ClipLoader } from "react-spinners";
import { FormLabel, Modal } from "react-bootstrap";
import { AntTab, AntTabs, CheckBoxCustom, CustomButton, CustomButton2, CustomDatePicker, CustomSwitch, InputCustom, SelectCustom } from "../../../adminComponents/adminComponents";
import { Button, Card, CardActions, CardContent, CardMedia, Checkbox, FormControl, FormControlLabel, Hidden, InputLabel, ListItem, MenuItem, Select, Switch, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Typography, styled } from '@mui/material'
import { Link, NavLink, Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { EditNewsLink } from "./editNewsModal";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const videoList = [
    {
        id: 1,
        NewsHeading: 'Lorem Ipsum is simply dummy text of the printing and typesetting industryLorem Ipsum has been the industry text of the printing and typesetting industryLorem Ipsum has been the industry',
        NewsDetails: 'sampleDetails1',
        NewsLink: 'https://www.youtube.com/watch?v=NMgV9StH26E',
        ActiveHours: '2',
        Status: true,
        StatusDate: '2023-10-22'
    },
    {
        id: 2,
        NewsHeading: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
        NewsDetails: 'sample details2',
        NewsLink: 'hgfhdfgh',
        ActiveHours: '1',
        Status: false,
        StatusDate: '2023-10-25'
    },
    {
        id: 3,
        NewsHeading: 'Lorem Ipsum is simply dummy text of the printing and typesetting',
        NewsDetails: 'sampledetails3',
        NewsLink: 'kgjhgug',
        ActiveHours: '30',
        Status: true,
        StatusDate: '2023-10-26'
    },
]


export function NewsEditModal() {





    const [formData, setFormData] = useState({
        Status: true,
    })
    const [value, setValue] = React.useState(0);
    const [isActive, setActive] = useState(true)
    const [statusDate, setStatusDate] = useState(dayjs(new Date()))

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    function onAddVideo(data) {

        let date = dayjs(statusDate).format('YYYY-MM-DD')

        const finalData = {
            ...data,
            Status: isFormActive,
            StatusDate:date
        }
        console.log(finalData);
    }
    function onValidationErr(err) {
        console.log(err);
    }

    const validation = {
        NewsHeading: [
            'NewsHeading',
            {
                required: 'cannot be empty'
            }
        ],
        NewsDetails: [
            'NewsDetails',
            {
                required: 'cannot be empty'
            }
        ],
        NewsLink: [
            'NewsLink',
            {
                required: 'cannot be empty'
            }
        ],
        ActiveHours: [
            'ActiveHours',
            {
                required: 'cannot be empty'
            }
        ]
    }
    function onChangeValueChange(event) {

        setFormActive(event.target.checked)
        console.log(event.target.value);

    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    function handleOnActiveChange(event) {
        console.log(event.target.value);
        setActive(event.target.value)
    }
    const [isFormActive, setFormActive] = useState(false)


    return (
        <>
            <ModalSetting >
                <Modal.Header closeButton className='header'>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        News Settings
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='body'>
                    <AntTabs centered value={value} onChange={handleChange} aria-label="disabled tabs example">
                        <AntTab label="List" />
                        <AntTab label="Add" />
                    </AntTabs>

                    {

                        <>
                            <div className='adminVideoSettingList' style={{ minHeight: '500px', display: value === 0 ? 'block' : 'none' }}>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <div className='topSelectOption'>
                                        <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
                                            {/* <InputLabel id="demo-simple-select-standard-label">Age</InputLabel> */}
                                            <SelectCustom
                                                labelId="demo-simple-select-filled-label"
                                                id="demo-simple-select-filled"
                                                value={isActive}
                                                onChange={handleOnActiveChange}
                                                sx={{
                                                    '& .MuiSelect-select': {
                                                        padding: '5px'
                                                    }
                                                }}
                                            >
                                                <MenuItem value={true}>Active</MenuItem>
                                                <MenuItem value={false}>None Active</MenuItem>
                                            </SelectCustom>
                                        </FormControl>
                                    </div>
                                </div>
                                <Table width={'100%'}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ padding: 0 }}></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell align='center' sx={{ padding: 0, width: '90px' }} className='textColor'>Active Days</TableCell>
                                            <TableCell sx={{ padding: 0, width: '35px' }}></TableCell>
                                            <TableCell sx={{ padding: 0, width: '35px' }}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>


                                        {
                                            videoList.map(
                                                (video) => (
                                                    video.Status === isActive &&
                                                    <>
                                                        <TableRow>
                                                            <TableCell style={{ padding: 0 }} align='center' >
                                                                <CheckBoxCustom style={{ padding: '0', margin: 0 }} checked={video.Status} />
                                                            </TableCell >
                                                            <TableCell align='left' sx={{ padding: '5px', paddingTop: '5px' }}>
                                                                <a className='link link2 textColor' href={video.NewsLink} >
                                                                    {video.NewsHeading}
                                                                </a >
                                                            </TableCell >
                                                            <TableCell align='center' style={{ padding: 0 }}>
                                                                <span className='textColor'>{video.ActiveHours} </span>
                                                            </TableCell >
                                                            <TableCell align='center' sx={{ padding: 0 }}>
                                                                <Link to={`edit/${video.id}`} className='deleteButton textColor'><i class="bi bi-pencil-fill"></i></Link>
                                                            </TableCell >
                                                            <TableCell align='center' sx={{ padding: 0 }}>
                                                                <div className='deleteButton'><i class="bi bi-trash-fill textColor"></i></div>
                                                            </TableCell >
                                                        </TableRow>

                                                    </>
                                                )
                                            )
                                        }

                                    </TableBody>
                                </Table>
                            </div>

                            <form style={{ display: value === 1 ? 'block' : 'none' }} className='adminVideoSettingForm' onSubmit={handleSubmit(onAddVideo, onValidationErr)}>
                                <div className='inutContainer'>
                                    <FormLabel>News Heading</FormLabel>
                                    <InputCustom fullWidth label="heading"
                                        variant="outlined"
                                        {...register(...validation.NewsHeading)}
                                        error={errors.NewsHeading ? true : false}
                                        helperText={errors.NewsHeading?.message}
                                    />

                                </div>
                                <div className='inutContainer'>
                                    <FormLabel>News Details</FormLabel>
                                    <InputCustom fullWidth label="details"
                                        variant="outlined"
                                        {...register(...validation.NewsDetails)}
                                        error={errors.NewsDetails ? true : false}
                                        helperText={errors.NewsDetails?.message}
                                        multiline
                                        minRows={2}
                                        maxRows={6}
                                    />
                                </div>
                                <div className='inutContainer'>
                                    <FormLabel>Link</FormLabel>
                                    <InputCustom fullWidth label="link"
                                        variant="outlined"
                                        {...register(...validation.NewsLink)}
                                        error={errors.NewsLink ? true : false}
                                        helperText={errors.NewsLink?.message}
                                    />
                                </div>
                                <div className='inutContainer' style={{ width: '150px' }}>
                                    <FormLabel>Active Days</FormLabel>
                                    <InputCustom type='number'
                                        fullWidth label="Days"
                                        variant="outlined"
                                        {...register(...validation.ActiveHours)}
                                        error={errors.ActiveHours ? true : false}
                                        helperText={errors.ActiveHours?.message}

                                    />
                                </div>
                                <div className='inutContainer' style={{ width: '150px' }}>
                                    <FormLabel>Active From</FormLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <CustomDatePicker defaultValue={statusDate}
                                            onChange={setStatusDate}
                                            format="YYYY-MM-DD"
                                        />
                                    </LocalizationProvider>
                                </div>
                                <FormControlLabel

                                    control={<CustomSwitch
                                        name='isActive' checked={isFormActive} onChange={onChangeValueChange} value={isFormActive} color="primary" />}
                                    label="Is Active"
                                    labelPlacement="start"
                                />
                                <div className='button' >
                                    <CustomButton variant="contained" type="submit">Add</CustomButton>
                                </div>

                            </form>
                        </>
                    }
                </Modal.Body>

            </ModalSetting>
            <Routes>
                <Route path='edit/:id' element={<EditNewsLink videoList={videoList} />}></Route>
            </Routes>
        </>
    )
}

export { videoList as newsList }