import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Landingpage from './LandingPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import LoginPage from './components/LoginPage/Login';
import Dsr from './components/DSR/Dsr';
import { DarkModeProvider } from './components/Darktheme/DarkModeContext';
import { Topnav } from './components/Topnavbar/Topnav';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import AdminPage from './components/Admin/AdminPage';
import { ProtectedRoutes } from './components/Routes/protectedRoutes';
import { Modal, ModalProvider } from './components/ModalToast/ToastModal';

  window.addEventListener('contextmenu', (event) => {
  event.preventDefault(); // Prevent right-click context menu
});

window.addEventListener('keydown', (event) => {
  if (event.key === 'F12' || (event.ctrlKey && event.shiftKey && event.key === 'I')) {
    event.preventDefault(); // Prevent opening developer tools
  }
});

ReactDOM.render(
  <Provider store={store}>
    
  <DarkModeProvider>
    <ModalProvider>
  <Modal/>
    <Router>
      <Routes>
        <Route path="/" element={<Landingpage />} />
        <Route path="/Login" element={<LoginPage />} />
        <Route path="/Admin/*" element={<AdminPage />} />
        <Route path='*' element={<ProtectedRoutes allowDefaultloading={true}><Topnav/></ProtectedRoutes>}></Route>
      </Routes>
    </Router>
    </ModalProvider>

  </DarkModeProvider>
  </Provider>,
  document.getElementById('root')
);
