// import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import './dashbord2.css'
import React, { useEffect, useState } from 'react';
import { useDarkMode } from '../Darktheme/DarkModeContext';
import '../../index.css'
// import Chart from 'react-apexcharts';

function Dashboard2(props) {
  const [chartOptions, setChartOptions] = useState({});

  const [chartSeries, setChartSeries] = useState([]);
  const [height,setHeight] = useState(0);
  const { isDarkMode, toggleDarkMode } = useDarkMode();
  
  useEffect(
    () => {

      const setUpTable = async ()=>{
        if (props.data?.names) {
          const names = props.data.names
          
          const Totals = props.data.totals
          const shortNames = names.map((fullName) => {
            // Split the full name by spaces and map each part to its first letter
            const parts = fullName.split(' ');
            const shortForm = parts.map(part => part.charAt(0)).join('');
            return shortForm;
          });
          setChartOptions(
            {
              chart: {
                id: "basic-bar",
                // width:'800px',
                // height:'3000px',
              },
              xaxis: {
                categories: shortNames,
                labels: {
                  style: {
                    colors: isDarkMode ? '#ffffff' : '#333333',
                    fontSize: '0.5rem'
                  }
                },
              },
              yaxis: {
                labels: {
                  style: {
                    colors: isDarkMode ? '#ffffff' : '#333333',
                    fontSize: '0.5rem'
                  }
                },
              },
              colors: ["#a95557"],
  
              dataLabels: {
                enabled: false,
                style: {
                  fontSize: "8px", // Adjust the font size of data labels
                }
              },
              plotOptions: {
                bar: {
                  // horizontal: true,
                  columnWidth: '90%',
                  // barHeight: "200px", // Make all bars the same width
                  colors: {
                    backgroundBarColors: [], // Clear background colors
  
                  },
                  fill: {
                    colors: ["#a95557"], // Set the same color for all bars
                  },
  
                },
                line: {
                  strokeColors: '#2ca593'
                }
              },
              tooltip: {
                enabled: true,
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                  console.log('lsdfjlsdjljfljdslffl',w.globals);
                  return `<div class="hoverbox-outer">
                    <div class="topcontent">
                      ${names[dataPointIndex]}
                    </div>
                    <div class="value">
                      ${series[seriesIndex][dataPointIndex]}
                    </div>
                  </div>`
                },
                x: {
                  formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    // Find the index of the hovered data point
                    // const index = value.dataPointIndex;
                    // Use the full category name from the original names array
                    return names[dataPointIndex];
                  },
  
                },
              },
            }
          )
          setChartSeries(
            [
              {
                name: "series-1",
                data: Totals
              }
            ]
          )
          setHeight(names.length*30)
        }
      }
      setUpTable();

    }, [props.data,isDarkMode]
  )

  return (
    <div>
      <div className="chartss">
        <div className="app card" id='chartCard' >
          <div className="row" style={{height:'100%'}} >
            <div className="mixed-chart" >
              <Chart
                options={chartOptions}
                series={chartSeries}
                type="bar"
                className="chartBar"
                width={height==='100%'?'90%':`${50+height}px`}
                height={'100%'}
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Dashboard2;


// export default Dashboard2;
