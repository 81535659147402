import './Adminstyles.css'
import { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
// import { Box, Button, CardActionArea, Tab, Tabs, styled } from '@mui/material';
import { useDarkMode } from '../Darktheme/DarkModeContext';
import landingimg from '../../Assets/images/landingpageimg.png'
import { Link, NavLink, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import LandingPageSettings from './landingPageSettings/landingPageSettings';

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

const AntTabs = styled((props)=><Tabs {...props}/>)({
  borderBottom: '1px solid var(--body-color)',
  backgroundColor: 'var(--sidebar-color)',
  '& .MuiTabs-indicator': {
    backgroundColor: 'var(--primaryy-color)',
    height: '3px',
    borderTopRightRadius: '10px',
    borderTopLeftRadius: '10px',
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: 600,
  marginRight: theme.spacing(1),
  color: 'var(--text-color)',
  '&:hover': {
    color: 'var(--text-colorHover)',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: 'var(--primaryy-color)',
    fontWeight: 600,
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'var(--sidebar-color)',
  },
}));


function AdminPage() {
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);
  const { isDarkMode, toggleDarkMode } = useDarkMode();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [tabVal, setTabVal] = useState(0)
  const navigate = useNavigate()
  const location = useLocation();
  const [selectedOption, setselectedOption] = useState('landingPage');
  // useEffect(
  //   ()=>{
  //     // console.log(tabVal);
  //     // if(tabVal===0){
  //     //   navigate('landingPage',{replace:true})
  //     // }
  //   },[]
  // )
  const toggleFullscreen = () => {

    if (!isFullscreen) {
      const docEl = document.documentElement;

      if (docEl.requestFullscreen) {
        docEl.requestFullscreen();
      } else if (docEl.mozRequestFullScreen) {
        docEl.mozRequestFullScreen();
      } else if (docEl.webkitRequestFullscreen) {
        docEl.webkitRequestFullscreen();
      } else if (docEl.msRequestFullscreen) {
        docEl.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }

    setIsFullscreen(!isFullscreen);
  };


  const toggleNav = () => {
    setIsNavigationOpen(!isNavigationOpen);
  };
  return (
    <div className='adminPageOuter'>
      <section className='adminTopNav'>
        <div className='topNav'>
          <i class='bx bx-log-out' style={{ fontSize: '1.4rem' }} onClick={() => navigate('/')}></i>
          <i class='bx bxs-user-circle' style={{ fontSize: '2.4rem' }}></i>
        </div>
      <Box sx={{ bgcolor: '#fff' }}>
        <AntTabs   variant="scrollable" scrollButtons="auto" value={tabVal} onChange={(e, newValue) => { setTabVal(newValue) }} aria-label="ant example">
          <AntTab label="Landing Page Settings" component={Link} to={'landingPage'} />
          <AntTab label="Another Setting" component={Link} to={'anotherSetting'} />
          <AntTab label="Some Another" component={Link} to={'someanother'} />
        </AntTabs>

      </Box>
      </section>
      


      <Routes>
        <Route path='' element={<Navigate to="landingPage" replace={true} />}></Route>
        <Route path='landingPage/*'  Component={()=>{return <LandingPageSettings />}}></Route>
        <Route path='anotherSetting/*' Component={()=>{if(tabVal!==1){setTabVal(1)}return <div>another settings</div>}}></Route>
        <Route path='someanother/*' Component={()=>{if(tabVal!==2){setTabVal(2)}return <div>some other settings</div>}}></Route>
      </Routes>

      <div id='settingsTabLanding' onClick={toggleNav}>
        <i class='bx bx-cog' id="settingsicon" ></i>
      </div>
      <div className={`navigation ${isNavigationOpen ? 'open' : ''}`} id="navigation">

        <div class="themehead">
          <span >Theme Settings</span>
          <span className="close-icon" onClick={toggleNav}>
            <i className='bx bx-x'></i>
          </span>

        </div>
        <div class="theme">
          <span >Theme </span>
          <div class="lightdarkbox">
            <div className="lightmode" onClick={() => toggleDarkMode(false)}>
              <i class='bx bx-sun lightt' ></i>


            </div>
            <div className="darkmode" onClick={() => toggleDarkMode(true)}>
              <i class='bx bx-moon darkk' ></i>

            </div>
          </div>
        </div>
        <div class="theme">
          <span >Full Screen</span>
          <div class="sidenavbox">

            <div className="navhide" onClick={toggleFullscreen}>



              {isFullscreen ? <i class='bx bx-exit-fullscreen'></i> : <i class='bx bx-fullscreen '></i>}

            </div>

            {isFullscreen ? <span>Exit</span> : <span></span>}


          </div>
          {isNavigationOpen && (
            <div className="overlay" onClick={toggleNav}>
              {/* Content of the overlay */}
              <p>This is the overlay content.</p>
            </div>
          )}
        </div>
      </div>

    </div>

  )
}

export default AdminPage