import React, { useEffect, useState } from 'react'
import { toastmsg } from '../Toasts/toasts'
import axios from 'axios'
import { apiBaseUrl } from '../../static/Apiname/api'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper } from '@mui/material';
import { Button } from '@mui/material';
import { Modal } from 'react-bootstrap';
import { AddModal, EditModal } from './components/Modal';


export function TreeViewList() {

    const [roleLists, setRoleLists] = useState([])
    const [isLoading, setisLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [rebuild, setRebuild] = useState(false)
    const [editId, setEditId] = useState()

    const openaddModal = () => {
        setOpenModal(true)
    }

    const updateList = () => {
        setRebuild(!rebuild)
    }

    const openEdit = (id) => {
        setEditId(id)
        setOpenEditModal(true)
    }



    useEffect(
        () => {

            const loadData = async () => {

                setisLoading(true)

                const token = sessionStorage.getItem('access-token')
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                };

                try {

                    if (token) {

                        const listresponse = await axios.get(`${apiBaseUrl}/api/user-role/list`, config)
                        setRoleLists(listresponse.data?.user_roles)

                    } else {
                        throw Error('no token found')
                    }

                } catch (error) {
                    toastmsg('somthing went wrong')
                    console.log(error)
                }

                setisLoading(false)



            }

            loadData()


        }, [rebuild]
    )


    return (
        <div className="home">

            <div className="mainDiv roleListTableOuter" style={{ padding: '1px' }}>
               
                    <div className=' col-12 col-sm-11 col-md-8 col-lg-7 '>
                        <AddModal show={openModal} onHide={() => { setOpenModal(false) }} updateUi={updateList} />
                        <EditModal id={editId} show={openEditModal} onHide={() => { setOpenEditModal(false); setEditId(null) }} updateUi={updateList} />
                        <div className="btngrp" style={{ width: '95%' }}  >
                            <Button variant="contained" color="success" className='mb-2' style={{ float: 'right' }}
                                onClick={openaddModal}
                            >
                                <i className='bx bxs-user-plus icon fs-5 pe-2 '></i> New Role
                            </Button>
                            {/* <Button variant="contained" color="primary" className='mb-2' style={{ float: 'right' }} onClick={handleOpen}  >
                            <i className='bx bxs-user-plus icon fs-5 pe-2 '></i> Search Employee
                        </Button> */}


                        </div>


                        <Paper sx={{ width: '95%', overflow: 'hidden' }} id='tablemaindiv' >

                            {
                                isLoading === false ?
                                    <TableContainer sx={{ maxHeight: 'calc(100vh - 150px)' }} >
                                        <Table aria-label="simple table" >
                                            <TableHead style={{ position: 'sticky', top: '0', padding: '0' }}>
                                                <TableRow>
                                                    <TableCell id='TableHead' >No</TableCell>
                                                    <TableCell id='TableHead' >Role Name</TableCell>
                                                    <TableCell id='TableHead'>Status  </TableCell>
                                                    {/* <TableCell id='TableHead'>Actions</TableCell> */}
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {roleLists.map((row, index) => (

                                                    <TableRow
                                                        onClick={() => openEdit(row.id)}
                                                        key={row.id}
                                                        className='dataRow'
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell id='TableData'>{index + 1}</TableCell>
                                                        <TableCell id='TableData' component="th" scope="row">
                                                            {row.FM14_RoleName}
                                                        </TableCell>
                                                        <TableCell id='TableData'>
                                                            {row.FM14_Status === 1 ? "Active" : "Inactive"}
                                                        </TableCell>

                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    : <div>Loading....</div>
                            }
                        </Paper>
                    </div>
            </div>
        </div>
    )
}
