import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AlertModal, ModalSetting } from "../../Modal";
import { ClipLoader } from "react-spinners";
import { FormLabel, Modal } from "react-bootstrap";
import { AntTab, AntTabs, CheckBoxCustom, CustomButton, CustomButton2, CustomDatePicker, CustomSwitch, InputCustom, SelectCustom } from "../../../adminComponents/adminComponents";
import { Button, Card, CardActions, CardContent, CardMedia, Checkbox, FormControl, FormControlLabel, Hidden, InputLabel, ListItem, MenuItem, Select, Switch, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Typography, styled } from '@mui/material'
import { Link, NavLink, Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { newsList } from "./newsEditSetting";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";


export function EditNewsLink({ videoList }) {
    const params = useParams();
    const [formData, setFormData] = useState({})
    const [isFormActive, setFormActive] = useState(false)
    const [showLoadingModal, setShowLoadingModal] = useState(true);
    const validation = {
        NewsHeading: [
            'NewsHeading',
            {
                required: 'cannot be empty'
            }
        ],
        NewsDetails: [
            'NewsDetails',
            {
                required: 'cannot be empty'
            }
        ],
        NewsLink: [
            'NewsLink',
            {
                required: 'cannot be empty'
            }
        ],
        ActiveHours: [
            'ActiveHours',
            {
                required: 'cannot be empty'
            }
        ]
    }
    const [err, setErr] = useState(false)
    const [statusDate, setStatusDate] = useState(dayjs(new Date()))

    // console.log(formData);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();

    function onAddVideo(data) {
        let date = dayjs(statusDate).format('YYYY-MM-DD')

        const finalData = {
            ...data,
            Status: isFormActive,
            StatusDate:date
        }
        console.log(finalData);
    }
    function onValidationErr(err) {
        console.log(err);
    }

    function onChangeValueChange(event) {

        setFormActive(event.target.checked)
        console.log(event.target.value);

    }

    const [show, setShow] = useState(true)
    const navigate = useNavigate()
    const onExited = () => {
        navigate(-1);
    }

    useEffect(
        () => {

            const load = new Promise(
                (resove, reject) => {
                    try {
                        setShowLoadingModal(true)
                        const formData = newsList.filter(
                            (value) => value.id == params.id
                        )[0]
                        setFormData(formData)
                        for (const key in formData) {
                            setValue(key, formData[key])
                        }
                        setFormActive(formData.Status)
                        setStatusDate(dayjs(new Date(formData.StatusDate)))

                        resove('ok')
                    } catch (err) {
                        reject(err)
                    }
                }
            )

            load.then(
                (val) => {
                    setShowLoadingModal(false)
                    setErr(false)
                }
            ).catch(
                (err) => {
                    setShowLoadingModal(false)
                    setErr(false)
                }
            )


        }, []
    )



    return (
        <>
            <AlertModal show={showLoadingModal}>
                <ClipLoader color='white' size={30} />
            </AlertModal>
            <AlertModal show={err} onHide={() => setErr(false)}>
                <Modal.Body style={{ background: 'white', borderRadius: '12px', display: 'flex', flexDirection: 'column', justifyContent: 'end', alignItems: 'end' }}>
                    <p>Something Went Wrong !!!!</p>
                </Modal.Body>
            </AlertModal>
            <Modal
                size="lg"
                show={show}
                onHide={() => setShow(false)}
                onExited={onExited}
                aria-labelledby="example-modal-sizes-title-lg"
                scrollable
                centered
                backdropClassName='adminSettingsModalSample'
                className="adminSettingsModal"
                style={{
                    zIndex: '13'
                }}
            >
                <Modal.Header className='header' >
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Edit
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='body'>
                    {
                        showLoadingModal === false
                        ?
                        <form className='adminVideoSettingForm' onSubmit={handleSubmit(onAddVideo, onValidationErr)}>

                        <div className='inutContainer'>
                            <FormLabel>News Heading</FormLabel>
                            <InputCustom fullWidth label="heading"
                                variant="outlined"
                                {...register(...validation.NewsHeading)}
                                error={errors.NewsHeading ? true : false}
                                helperText={errors.NewsHeading?.message}
                            />

                        </div>
                        <div className='inutContainer'>
                            <FormLabel>News Details</FormLabel>
                            <InputCustom fullWidth label="details"
                                variant="outlined"
                                {...register(...validation.NewsDetails)}
                                error={errors.NewsDetails ? true : false}
                                helperText={errors.NewsDetails?.message}
                                multiline
                                minRows={2}
                                maxRows={6}

                            />
                        </div>
                        <div className='inutContainer'>
                            <FormLabel>Link</FormLabel>
                            <InputCustom fullWidth label="link"
                                variant="outlined"
                                {...register(...validation.NewsLink)}
                                error={errors.NewsLink ? true : false}
                                helperText={errors.NewsLink?.message}
                            />
                        </div>
                        <div className='inutContainer' style={{ width: '150px' }}>
                            <FormLabel>Active Days</FormLabel>
                            <InputCustom type='number'
                                fullWidth label="Days"
                                variant="outlined"
                                {...register(...validation.ActiveHours)}
                                error={errors.ActiveHours ? true : false}
                                helperText={errors.ActiveHours?.message}

                            />
                        </div>
                        <div className='inutContainer' style={{ width: '150px' }}>
                            <FormLabel>Active From</FormLabel>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <CustomDatePicker defaultValue={statusDate}
                                    onChange={setStatusDate}
                                    format="YYYY-MM-DD"
                                />
                            </LocalizationProvider>
                        </div>
                        <FormControlLabel

                            control={<CustomSwitch
                                name='isActive' checked={isFormActive} onChange={onChangeValueChange} value={isFormActive} color="primary" />}
                            label="Is Active"
                            labelPlacement="start"
                        />

                        <div className='button' >
                            <CustomButton2 variant="contained" type="submit">Edit</CustomButton2>
                        </div>

                    </form>
                    :(<div>Loading....</div>)
                    }
                </Modal.Body>
                {/* <Routes>
                <Route path='edit/:id' element={<EditModalVideo />}></Route>
            </Routes> */}
            </Modal>
        </>)
}
