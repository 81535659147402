import { createSlice } from '@reduxjs/toolkit'

export function todayDate() {
    const today = new Date();
    const yesterday = new Date(today);

    yesterday.setDate(yesterday.getDate() - 1);

    const day = String(yesterday.getDate()).padStart(2, '0');
    const month = String(yesterday.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = yesterday.getFullYear();

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;

}
const tableDateslice = createSlice(

    {
        name: 'tableDate',
        initialState: {
            value:{
                date:todayDate(),
                state:2
            }

        },
        reducers: {
            SetTableDate: (state, action) => {
                state.value.date = action.payload;
            },
            SetTableState: (state, action) => {
                state.value.state = action.payload;
            },
         
        }
    }
)

export const { SetTableDate,SetTableState} = tableDateslice.actions;
export const TableDateReducer = tableDateslice.reducer;