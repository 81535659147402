import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";


export function ModalSetting(props) {

    const [show,setShow] = useState(true)
    const navigate = useNavigate()
    const onExited = ()=>{
        navigate(-1);
    }


    return (
        <Modal
            size="lg"
            show={show}
            onHide={() => setShow(false)}
            onExited={onExited}
            aria-labelledby="example-modal-sizes-title-lg"
            scrollable
            // centered
            centered
            className="adminSettingsModal"
            backdropClassName="adminSettingsModalSample"
            style={
                {zIndex:12}
            }
        >
            {props.children}
        </Modal>
    )
}

export const AlertModal = (props)=>{
    const [show,setShow] = useState(props.show)
    useEffect(
        ()=>{
            setShow(props.show)
        },[props.show]
    )
    return (
        <Modal
            size="sm"
            show={props.show}
            // onHide={() => setShow(false)}
            // onExited={onExited}
            aria-labelledby="example-modal-sizes-title-lg"
            centered
            className="adminAlertSettingsModal"
            backdropClassName="adminAlertSettingsModalSample"
            style={
                {zIndex:2001}
            }
            {...props}
        >
            {props.children}
        </Modal>
    )
}