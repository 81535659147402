import axios from "axios";
import { apiBaseUrl } from "../Apiname/api";

export function getAccessToken(){
    return sessionStorage.getItem('access-token');
}

export const handleLoggIn = async()=>{
    const token = getAccessToken();
    if(token){
        try{
            const res = await axios.get(`${apiBaseUrl}/api/user`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
              console.log('response is',res.status);
            if(res.status===401){
                console.log('user is unauthenticated');
                return false;
            }
        }catch(err){
            console.log(err);
            if(err.response){
                console.log(err.response.status);
                if(err.response.status===401){
                    console.log('user is unauthenticated');
                    return false;
                }
            }
        }
    }else{
        console.log('user is unauthenticated');
        return false;
    }
    console.log('user is authenticated');
    return true;
}

