import { Button, Checkbox, Select, Switch, Tab, Tabs, TextField, styled } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";

export const AntTabs = React.forwardRef((props, ref) => <Tabs {...props} ref={ref} sx={{
    borderBottom: '1px solid var(--body-color)',
    backgroundColor: 'var(--sidebar-color)',
    '& .MuiTabs-indicator': {
        backgroundColor: 'var(--primaryy-color)',
        height: '3px',
        borderTopRightRadius: '10px',
        borderTopLeftRadius: '10px',
    },
}} />)

export const CustomButton = React.forwardRef((props, ref) => <Button {...props} ref={ref} endIcon={<i class="bi bi-plus" style={{ padding: 0, margin: 0 }}></i>} sx={{
    background: 'var(--primaryy-color)',
    paddingTop: '5px',
    '&:hover': {
        backgroundColor: 'var(--primaryy-colorHover)'
    }
}} />)

export const CustomButton2 = styled((props) => <Button {...props} />)({
    background: 'var(--primaryy-color)',
    paddingTop: '5px',
    '&:hover': {
        backgroundColor: 'var(--primaryy-colorHover)'
    }
});

export const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: 600,
    marginRight: theme.spacing(1),
    color: 'var(--text-color)',
    '&:hover': {
        color: 'var(--text-colorHover)',
        opacity: 1,
    },
    '&.Mui-selected': {
        color: 'var(--primaryy-color)',
        fontWeight: 600,
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'var(--sidebar-color)',
    },
}));

export const InputCustom = React.forwardRef((props, ref) => <TextField  {...props} ref={ref} sx={{
    ...props.style,
    padding:'0px',
    '& .MuiFormLabel-root': {
        color: 'var(--text-color)',
        top: '-6px'
    },
    '& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root': {
        color: 'var(--text-color)'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--primary-color) !important' ,
    },
    '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--primary-color)'
    },
    '& .MuiOutlinedInput-input':{
        color:'var(--text-color)',
        padding:'10px',
    },
   
}} />)
export const SelectCustom = React.forwardRef((props, ref) => <Select ref={ref} {...props} MenuProps={{
    PaperProps: {
        style: {
            '& .MuiList-root': {
                backgroundColor: 'var(--sidebar-color)'
            },
            backgroundColor: 'var(--sidebar-color)',
            color: 'var(--text-color)',


        }
    }
}} sx={{
    ...props.style,
    '& .MuiSelect-root':{
        color: 'var(--text-color)'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--primary-color) !important'
    },
    '& .MuiSelect-select': {
        color: 'var(--text-color-transparent)',
        padding:'5px'
    

    },
    '& .MuiSvgIcon-root': {
        color: 'var(--text-color-transparent)'
    },
    '& .MuiList': {
        backgroundColor: 'var(--sidebar-color)',
        color: 'var(--text-color)'
    },
    '&::after': {
        borderBottom: '2px solid var(--primary-color) !important'
    },

}} />)

export const CheckBoxCustom = styled((props) => <Checkbox {...props} />)({
    color: 'var(--primary-color)',
    '& .MuiSvgIcon-root': {
        color: 'var(--primary-color)',

    }

});
export const CustomSwitch = styled((props) => <Switch {...props} />)({

    '& .Mui-checked': {
        color: 'var(--primaryy-color) !important',
    },
    '& .Mui-checked+.MuiSwitch-track': {
        backgroundColor: 'var(--primaryy-colorHover) !important'
    },
    '& .MuiSwitch-track': {
        backgroundColor: 'var(--text-color) '
    },


});


export const CustomDatePicker = React.forwardRef((props, ref) => <DatePicker {...props} ref={ref} sx={{
    '& .MuiFormLabel-root': {
        color: 'var(--text-color)'
    },
    '& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root': {
        color: 'var(--text-color)'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--primary-color)',
    },
    '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--primary-color)'
    },
    '& .MuiOutlinedInput-root':{
        color:'var(--text-color)'
    },
    '& .MuiIconButton-root':{
        color:'var(--text-color)'
    },
    '& .MuiOutlinedInput-notchedOutline':{
        borderColor:'var(--primary-color) !important'
    }
}} />)

