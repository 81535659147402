import { ToastContainer, toast } from 'react-toastify';

export function toastmsg(msg){
  const OkButton = ({ closeToast }) => (
    <button className="btn btn-outline-light" onClick={closeToast}>
      OK
    </button>
  );
    return  (    
    toast.error(msg, {
        position: "top-center",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        closeButton: <OkButton />
        
      }));
}
export function toastmsgsuccess(msgg){
    return  (    
    toast.success(msgg, {
        position: "top-center",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          color: "#fff",
          backgroundColor:'#0a7a0c', // Set the text color to green for success messages
        },
        theme: "colored",
      }));
}