import { components } from 'react-select'
import '../treeview.css'
export function CustomOption(props) {
    return (
        <components.Option  {...props}>
            <div className='customOptionOuter'>

                <table>
                    <tbody>
                        <tr>
                            <td className='first'>{props.data.empCode}</td>
                            <td className='vara'>-</td>
                            <td className='second'>{props.label}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </components.Option>
    );
}