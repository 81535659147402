import {createSlice} from '@reduxjs/toolkit'


const navTogleSlice = createSlice(
    {
        name:'navToggle',
        initialState:{
            value:false
        },
        reducers:{
            HideTopNav:(state,action)=>{
                state.value = true;
            },
            ShowTopNav:(state,action)=>{
                state.value = false;
            },
            ToggleTopNav:(state,action)=>{
                state.value = !state.value
            }
        }
    }
)

export const {HideTopNav,ShowTopNav,ToggleTopNav} = navTogleSlice.actions;
export const navTogleReducer = navTogleSlice.reducer;