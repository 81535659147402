import React, { useEffect, useState } from 'react'
import { getAccessToken, handleLoggIn } from '../../static/functions/authentication'
import { Navigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

export function ProtectedRoutes({ children, allowDefaultloading = false }) {

    const [loggedIn, setLoggedIn] = useState('waiting')
    const checkLoggein = async () => {
        const res = await handleLoggIn();
        setLoggedIn(res === true ? 'true' : 'false')
        console.log('chekking login', loggedIn);

    }


    useEffect(
        () => {
            checkLoggein();
        }, [getAccessToken()]
    )

    if (loggedIn === 'true') {
        return children
    } else if (loggedIn === 'false') {
        return <Navigate to="/login" replace />
    }

    if (allowDefaultloading === false) {
        return null
    }

    return (
        <div style={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <ClipLoader color='var(--primary-color)' size={25} />
          </div>
          
    )
}
