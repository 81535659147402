import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { adminApi } from '../../static/Apiname/api';
const Dashboard1 = () => {
  const [newsData, setNewsData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [videoSlides, setVideoSlide] = useState([]);
  const [isVideoLoading, setIsVideoLoading] = useState(true)
  const [isVideoErr, setIsVideoErr] = useState(false)
  const defaultNews = {
    title: 'Countertop Market Size Worth USD 167.66 Billion, Globally, by 2030 at a CAGR of 4.0%',
    country: 'United States Of America',
    content: 'Countertop Market to Grow at 4.0% CAGR from 2023 to 2030; Caesarstone Launched its Multi-Material Portfolio to Strengthen Industry Footing: Fortune Business Insights™ Countertop Market to Grow at 4.0% CAGR from 2023 to 2030; Caesarstone Launched its Multi-Material Portfolio to Strengthen Industry Footing: Fortune Business Insights'
  };
  function getYouTubeVideoID(url) {
    const regex = /https?:\/\/(www\.)?youtu(?:\.be|be\.com)\/(?:watch\?v=|embed\/)?(?<videoId>[\w-]+)(?:\?|&|$)/;
    const match = regex.exec(url);
    if (match) {
      return match.groups.videoId;
    }
    return null;
  }



  useEffect(
    () => {
      const videoCarousel = document.querySelector('.video-carousel');

      axios.get(`${adminApi}/video-link/list/A`).then(
        (res) => {
          console.log('succesfully fetched videos');
          const videoList = res.data;
          console.log('videos are',videoList);
          const videoLinks = videoList.map(
            link => ({
              link: getYouTubeVideoID(link.FM12_Link),
              duration: link.FM12_VideoDuration,
              activeDays: link.FM12_ActiveHours,
              statusDate: convert_date_to_stringReal(new Date(link.FM12_StatusDate)),
              id: link.id
            })
          )
          const videoSlide = videoLinks.map((videoLink) => {
            const videoSlide = document.createElement('div');
            videoSlide.className = 'video-slide';
            videoSlide.innerHTML = `
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/${videoLink.link}?autoplay=1&controls=0&mute=1&loop=1&playlist=${videoLink.link}"
              title="YouTube video player"
              frameborder="0"
              allowfullscreen
              style="border: none; pointer-events: none; padding: 0; margin: 0; max-width: 566px; border-radius: 10px;"
            ></iframe>
          `;
            videoCarousel.appendChild(videoSlide);

            return videoSlide;
          });
          setVideoSlide(videoSlide)
          let currentSlide = 0;

          // Calculate the total duration of all videos
          function slideVideos() {
            // Check if the video is still active
            const today = new Date();

            if (videoSlide.length <= 0) {
              console.log('no videos to display');
              return;
            }

            const daysSinceStatusDate = daysBetween(convert_date_to_string(videoLinks[currentSlide].statusDate), convert_date_to_string(today));
            
            if (videoLinks[currentSlide].activeDays < daysSinceStatusDate || daysSinceStatusDate<0) {
              console.log('active day exeeded');
              console.log('status date', videoLinks[currentSlide].statusDate);
              console.log('active days ', videoLinks[currentSlide].activeDays);
              videoLinks.splice(currentSlide, 1)
              videoSlide.splice(currentSlide, 1)
              currentSlide = currentSlide % videoSlide.length;
              slideVideos();
              return;
            }
            videoSlide.forEach((slide, index) => {
              if (index === currentSlide) {
                slide.style.transform = 'translateX(0)';
              } else {
                slide.style.transform = 'translateX(-100%)';
              }
            });

            // console.log('Changing video', 'index', currentSlide);
            const currentVideoDuration = durationToMilliseconds(videoLinks[currentSlide].duration); // Convert seconds to milliseconds
            console.log('current video duration in second ', videoLinks[currentSlide].duration, 's');
            currentSlide = (currentSlide + 1) % videoSlide.length;

            // Calculate the interval based on the current video duration
            videoTimer = setTimeout(slideVideos, currentVideoDuration);

          }
          slideVideos(); // Start the initial video slide
          setIsVideoLoading(false)
          setIsVideoErr(false)
        }
      ).catch(
        (err) => {
          console.log('error fetching video links',err);
          setIsVideoErr(true)
        }
      )



      let videoTimer;


      function daysBetween(date1, date2) {
        // Convert the dates to milliseconds.
        const milliseconds1 = date1.getTime();
        const milliseconds2 = date2.getTime();

        // Calculate the difference in milliseconds.
        const difference = milliseconds2 - milliseconds1;

        // Calculate the difference in days.
        const days = difference / (1000 * 60 * 60 * 24);

        // Round the result down to the nearest integer.
        return Math.floor(days);
      }

      function convert_date_to_string(date) {
        // Get the year, month, and day from the date object.
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        // Pad the month and day with zeros, if necessary.
        const paddedMonth = month.toString().padStart(2, '0');
        const paddedDay = day.toString().padStart(2, '0');

        // Construct a string in the format 'YYYY-MM-DD'.
        return new Date(`${year}-${paddedMonth}-${paddedDay}`);
      }

      function convert_date_to_stringReal(date) {
        // Get the year, month, and day from the date object.
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        // Pad the month and day with zeros, if necessary.
        const paddedMonth = month.toString().padStart(2, '0');
        const paddedDay = day.toString().padStart(2, '0');

        // Construct a string in the format 'YYYY-MM-DD'.
        return new Date(`${year}-${paddedMonth}-${paddedDay}`);
      }

      function durationToMilliseconds(duration) {
        const parts = duration.split(':').map(Number);
        // Ensure there are at least three parts (hours, minutes, seconds)
        while (parts.length < 3) {
          parts.unshift(0);
        }

        const [hours, minutes, seconds] = parts;

        // Convert hours, minutes, and seconds to milliseconds
        const milliseconds = (hours * 3600 + minutes * 60 + seconds) * 1000;

        return milliseconds;
      }




      return () => {
        clearTimeout(videoTimer)
      }

    }, []
  )



  useEffect(() => {
    axios.get('https://newsdata.io/api/1/news?apikey=pub_273555d0d6f13e01db9dfcb835a48541d1104&q=manufacture%20textiles')
      .then(response => {
        if (response.data && response.data.results) {
          setNewsData(response.data.results);
        }
      })
      .catch(error => {
        console.error('Error fetching news data:', error);
      });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % newsData.length);
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [newsData]);
  return (
    <div>
      <div className="infoPart">
        <div class="cardDiv row row-cols-1 row-cols-md-3 g-4" grid>
          <div class="card col" style={{ width: '84%', height: 'auto', minHeight: '350px', backgroundColor: '#ffffff59' }}  >
            <div class="card-body" >
              <div class="video-carousel">

              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="Firstpart">

        <div class="cardDiv row row-cols-1 row-cols-md-3 g-4" >
          <div class="card col" style={{ width: '94%', height: '350px ', backgroundColor: '#ffffff17' }} id='news'>
            <div class="card-body " style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: 'linear-gradient(to bottom, transparent, black)',
              borderRadius: '6px'
            }}>


              <div className="scrolling-text-container" >
                <h5 className="card-title scrolling-text" id='newsTitle'>
                  {newsData.length > 0 ? newsData[currentIndex]?.title : defaultNews.title}
                </h5>
              </div>
              <div id='newsMaindiv'>
                <div className="country" style={{ fontWeight: 'bold' }}>
                  {newsData.length > 0 ? newsData[currentIndex]?.country : defaultNews.country}:
                </div>

                <div style={{ color: 'white', maxHeight: '8em', overflow: 'hidden' }}>
                  {newsData.length > 0 ? newsData[currentIndex]?.content : defaultNews.content}                    </div>

              </div>
              <a href={newsData[currentIndex]?.link} target="_blank" rel="noopener noreferrer" style={{ color: '#2ca593' }}>
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>

  )
}

export default Dashboard1