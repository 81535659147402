import React, { useEffect, useState } from 'react';
import MainTable from '../Tables/MainTable';
import Dashboard2 from '../dashboard2/dashboard2';
import PieChart from '../Tables/MainChartPie';
import AsmTable from '../Tables/AsmTable';
import Exetable from '../Tables/ExewiseTable';
import Districwise from '../Tables/DistrictWise';
import DistributeWise from '../Tables/DistributeWise';
import Daywise from '../Tables/Daywise'
import { NavLink, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { HideTopNav, ShowTopNav } from '../../redux/navTogle/navTogleSlice';
import { useDispatch, useSelector } from 'react-redux';
import Productwise from '../Tables/Productwise';
import Prodtrimmedwise from '../Tables/Prodtrimmedwise';
import Exedbwise from '../Tables/Exedbwise';
import Exeprodwise from '../Tables/Exeprodwise';
import { ProtectedRoutes } from '../Routes/protectedRoutes';
import ExeluploadPage from '../exeluploads/ExeluploadPage';
import { apiBaseUrl } from '../../static/Apiname/api';
import axios from 'axios';
import { toastmsg } from '../Toasts/toasts';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';



function Dsr() {
  const [showGraph, setShowGraph] = useState(false);
  const [showChart, setShowChart] = useState(false);
  const [activeTab, setActiveTab] = useState('table'); // Default active tab is 'maintable'
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [allowedTables, setAllowedTabs] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)

  const userDetails = useSelector(
    state => state.userData.value.userDetails
  )

  const handleTabClick = (tab) => {
    console.log(tab);
    setActiveTab(tab);
  };

  const navLinksData = [
    { id: 'TabAsmwise', to: 'asm', label: 'ASM Wise', path: 'asm/*',element: <ProtectedRoutes><AsmTable /></ProtectedRoutes>},
    { id: 'TabExewise', to: 'executives', label: 'EXE Wise', path: "executives/*",element:<ProtectedRoutes><Exetable /></ProtectedRoutes>},
    { id: 'TabDistrictwise', to: 'districtwise', label: 'District Wise', path: "districtwise/*",element:<ProtectedRoutes><Districwise /></ProtectedRoutes>},
    { id: 'TabDBwise', to: 'distributerwise', label: 'DB Wise', path: "distributerwise/*" , element:<ProtectedRoutes><DistributeWise /></ProtectedRoutes>},
    { id: 'TabDaywise', to: 'daywise', label: 'Day Wise', path: "daywise/*" ,element:<ProtectedRoutes><Daywise /></ProtectedRoutes>},
    { id: 'TabProdwise', to: 'prodwise', label: 'Prod Wise', path: "prodwise/*",element:<ProtectedRoutes><Productwise /></ProtectedRoutes> },
    // { id: 7, to: 'prodtrimwise', label: 'Prod Trimmed' },
    // { id: 8, to: 'Exedbwise', label: 'Exe-DB Wise' },
    // { id: 9, to: 'Exeprodwise', label: 'Exe-Prod Wise' },
  ];

  useEffect(
    () => {

      const fetchData = async () => {

        setIsLoaded(false)

        try {
          const token = sessionStorage.getItem('access-token')
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };

          const sentData = {
            employee_id: userDetails.id,
            // menu_program_ids: ['TabAsmwise','TabDistrictwise']
            menu_program_ids: navLinksData.map(link => link.id)
          }
          const allowResponse = await axios.post(`${apiBaseUrl}/api/user-menu/list`, sentData, config)
          const allowed = allowResponse.data.allowed_menu_program_ids;
          if (allowed) {
            const allowedtabs = navLinksData.filter(
              map=>{
                for(let i=0;i<allowed.length;i++){
                  if(allowed[i]===map.id){
                    return true
                  }
                }
              }
            );
            setAllowedTabs(
              allowedtabs
            )

            // navigate(
            //   allowedTables.length>0?allowedTables[0]:null,
            //   {
            //     replace:true
            //   }
            // )

          }

          setIsLoaded(true)

        } catch (err) {
          toastmsg('somthing went wrong')
          console.log(err)
        }
      }

      fetchData()

    }, []
  )



  return (
    <section className="home">
      <div className="mainDiv" style={{ padding: '1px' }}>
        <div>
          {
            isLoaded
              ?
              <Routes>
                <Route path='' element={<Navigate to={allowedTables.length > 0 ? allowedTables[0].to : null} replace={true} />}></Route>
                {
                  allowedTables.map(
                    routes => <Route path={routes.path} element={routes.element} />
                  )
                  // <>
                  //   <Route path='asm/*' element={<ProtectedRoutes><AsmTable /></ProtectedRoutes>} />
                  //   <Route path="executives/*" element={<ProtectedRoutes><Exetable /></ProtectedRoutes>} />
                  //   <Route path="districtwise/*" element={<ProtectedRoutes><Districwise /></ProtectedRoutes>} />
                  //   <Route path="distributerwise/*" element={<ProtectedRoutes><DistributeWise /></ProtectedRoutes>} />
                  //   <Route path="daywise/*" element={<ProtectedRoutes><Daywise /></ProtectedRoutes>} />
                  //   <Route path="prodwise/*" element={<ProtectedRoutes><Productwise /></ProtectedRoutes>} />
                  // </>
                }
                {/* <Route path="prodtrimwise/*" element={<ProtectedRoutes><Prodtrimmedwise /></ProtectedRoutes>} /> */}
                <Route path="Exedbwise/:id/*" element={<ProtectedRoutes><Exedbwise /></ProtectedRoutes>} />
                <Route path="Exeprodwise/:id/*" element={<ProtectedRoutes><Exeprodwise /></ProtectedRoutes>} />
                <Route path="*" element={<div > you are not accessible this  page</div>} />
              </Routes>
              : null
          }
        </div>

        <div>
          <div className={`table-options`} id='table-tabs'>
            {
              isLoaded ?
                allowedTables.map((link) => (
                  <NavLink key={link.id} id='tableBtns' to={link.to}>
                    {link.label}
                  </NavLink>
                ))
                :
                < SkeletonTheme duration={2} baseColor="#f6f5ff00" highlightColor="#cfcfc8" width={'95%'}>
                  <Skeleton height={30} width={'100px'} />
                  <Skeleton height={30} width={'100px'} />
                  <Skeleton height={30} width={'100px'} />
                  <Skeleton height={30} width={'100px'} />
                </SkeletonTheme>
            }



          </div>
        </div>
      </div>

    </section >
  );
}

export default Dsr;
