import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { TreeviewCreate } from '../Treeview.createRole'
import { toastmsg, toastmsgsuccess } from '../../Toasts/toasts';
import axios from 'axios';
import { apiBaseUrl } from '../../../static/Apiname/api';
import { InputCustom } from '../../adminComponents/adminComponents';
import CheckboxTree from 'react-checkbox-tree';
import { SelectBox } from '../../selectBox/selectBox';
// import { Select } from '@mui/material';
import { CustomOption } from './customOptions';
import { ClipLoader } from 'react-spinners';
import Select, { components } from 'react-select';
import { AlertModal } from '../../Admin/Modal';
import './modal.css'


function createNestedArrayWithFormattedFields(data) {
    let map = new Map();

    // First pass: create an entry for each item with formatted fields.
    data.forEach(item => {
        map.set(item.id, {
            value: item.id,
            label: item.FM18_MenuDisplayName,
            children: []
        });
    });

    // Second pass: properly nest the children.
    let roots = [];
    data.forEach(item => {
        const current = map.get(item.id);
        if (item.FM18_M18_ParentID === null) {
            roots.push(current);
        } else {
            let parent = map.get(item.FM18_M18_ParentID);
            if (parent) {
                parent.children.push(current);
            }
        }
    });

    return roots;
}


function TreeHierarchy({ onChange = () => { }, nodes = [] }) {
    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const nestednodes = createNestedArrayWithFormattedFields(nodes)
    // const [nodes, setNodes] = useState([])

    // const nodes = [
    //     {
    //         value: 'parent',
    //         label: 'Parent',
    //         children: [
    //             { value: 'child1', label: 'Child 1' },
    //             { value: 'child2', label: 'Child 2' },
    //         ],
    //     },
    //     // ... other nodes
    // ];

    // useEffect(
    //   () => {
    //     const loadData = async () => {
    //       const token = sessionStorage.getItem('access-token')
    //       const config = {
    //         headers: {
    //           Authorization: `Bearer ${token}`,
    //         },
    //       };
    //       try {
    //         const res = await axios.get(`${apiBaseUrl}/api/menu/list`, config)
    //         // const nestedRole = createNestedArray(res.data);
    //         // const formattedRole = createNestedArrayWithFormattedFields(res.data)
    //         // console.log(formattedRole)
    //         setNodes(res.data)
    //       } catch (err) {
    //         console.log('error loading the menu list')
    //       }
    //     }
    //     loadData()
    //   }, []
    // )

    const icons = {
        halfCheck: <i className="bx bx-check-square"></i>,
        uncheck: <i className="bx bx-square"></i>,
        check: <i className="bx bxs-check-square"></i>,
        expandClose: <i className="bx bx-chevron-right"></i>,
        expandOpen: <i className="bx bx-chevron-down"></i>,
        expandAll: <i className="bx bx-plus-circle"></i>,
        collapseAll: <i className="bx bx-minus-circle"></i>,
        parentClose: <i className="bx bx-folder"></i>,
        parentOpen: <i className="bx bxs-folder-open"></i>,
        leaf: <i className="bx bx-file"></i>
    };

    return (
        <CheckboxTree
            nodes={nestednodes}
            checked={checked}
            expanded={expanded}
            onCheck={(values) => { console.log(values); onChange(values); setChecked(values) }}
            onExpand={setExpanded}
            icons={icons}
            checkModel='all'
        // onClick={(value)=>console.log(value)}
        />
    );
}


export function AddModal({
    show = false,
    onHide = () => { },
    updateUi = () => { }
}) {

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [employees, setEmployees] = useState([])
    const [roleName, setRoleName] = useState('');
    const [checkedRoles, setCheckedRoles] = useState([])
    const [err, setErr] = useState(null)
    const [isSubmitted, setSumbitted] = useState(false)
    // const [filterEmp, setfilterEmp] = useState([])
    const [nodes, setNodes] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const orgHerarchieValues = ["ED", "VP", "HOD", "MIS", "RSM", "ASM", "SE"];

    const orgHerarchy = [
        { value: null, label: 'All' },
        ...orgHerarchieValues.map(
            org => ({
                label: org,
                value: org
            })
        )
    ]
    const [orgHeraSelected, setOrgHeraSelected] = useState(orgHerarchy[0])
    const filterEmp = employees.filter(
        emp => {
            if (orgHeraSelected.value && emp.orgHerarchy !== orgHeraSelected.value) {
                return false
            }
            return true
        }
    )
    // {value:"ALL"},


    const handleSelectAll = () => {

        setSelectedOptions(filterEmp);

    };

    const clearDatas = () => {
        setSelectedOptions([])
        // setEmployees([])
        setRoleName('')
        setCheckedRoles([])
    }
    const findAllParentIDs = (data, id) => {
        const result = [];
        let currentID = id;

        const findParent = (itemID) => {
            // Find the item with the given ID
            const item = data.find(d => d.id == itemID);
            if (item && item.FM18_M18_ParentID !== null) {
                // If the item has a parent, add the parent ID to the result array
                result.push(item.FM18_M18_ParentID);
                // Recursively find the parent of the parent
                findParent(item.FM18_M18_ParentID);
            }
        }

        findParent(currentID);
        return result;
    }

    const submitRole = async () => {
        setSumbitted(true)
        setIsLoading(true)

        try {

            const employee_ids = selectedOptions.map(
                emp => emp.value
            )





            const err = {}
            if (roleName === '') {
                err['role_name'] = 'please enter role name'
            }
            if (checkedRoles.length === 0) {
                err['checked_roles'] = 'please select any role '
            }
            if (employee_ids.length === 0) {
                err['employee_ids'] = 'please select an employee '
            }
            if (Object.keys(err).length !== 0) {
                setSumbitted(false)
                setIsLoading(false)
                setErr(err)
                return;
            } else {
                setErr(null)
            }
            if (Array.isArray(nodes)) {
                let completeParents = []
                checkedRoles.forEach(
                    roles => {
                        const parents = findAllParentIDs(nodes, roles);
                        if (parents.length > 0) {
                            completeParents = [...new Set([...completeParents, ...parents])];
                        }
                    }
                )

                const completeRoles = [...new Set([...completeParents, ...checkedRoles])]
                console.log('parents are', completeRoles)
                const data = {
                    role_name: roleName,
                    menu_ids: completeRoles,
                    // user_role_status:1,
                    employee_ids
                }

                console.log('senting data', data)

                const token = sessionStorage.getItem('access-token')
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                };

                const res = await axios.post(`${apiBaseUrl}/api/user-role/create`, data, config);
                clearDatas()
                toastmsgsuccess('Role created successfully')
                setSumbitted(false)
                updateUi()
                onHide()

                // console.log(res.data)
                // console.log(res)
            }




        } catch (err) {
            setSumbitted(false)
            console.log(err)
            if (err.response?.data?.message) {
                toastmsg(err.response?.data?.message)
            } else {
                toastmsg('somthing went wrong')
            }

        }

        setIsLoading(false)

    }

    const closingModal = () => {
        setSelectedOptions([])
        setOrgHeraSelected(orgHerarchy[0])
        setEmployees([])
        setRoleName('')
        setCheckedRoles([])
        setErr(null)
        setSumbitted(false)
        // setfilterEmp([])
        setNodes([])
        setIsLoading(false)
    }



    useEffect(
        () => {
            const loadData = async () => {
                closingModal()
                setIsLoading(true)

                const token = sessionStorage.getItem('access-token')
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                };
                try {
                    let empList;
                    // filtering employee
                    if (employees.length === 0) {
                        const res = await axios.get(`${apiBaseUrl}/api/employee`, config)
                        empList = res.data.map(
                            emp => {
                                return {
                                    label: emp.FM06_EmployeeName,
                                    value: emp.id,
                                    empCode: emp.FM06_EmployeeCode,
                                    orgHerarchy: emp.FM06_OrganizationHierarchy
                                }
                            }
                        )

                        // console.log('employee list', empList)

                        setEmployees(empList)
                        // setfilterEmp(empList)

                    } else {

                        console.log('rebuild', show)
                        // const emplistnew = employees.filter(
                        //     emp => emp.orgHerarchy === orgHeraSelected.value
                        // )
                        // setfilterEmp(emplistnew)

                    }

                    const res = await axios.get(`${apiBaseUrl}/api/menu/list`, config)
                    setNodes(res.data);
                } catch (err) {
                    console.log(err)
                    toastmsg('error fetching employee list')
                }
                setIsLoading(false)
            }
            if (show === true) {

                loadData()
            }

        }, [show]
    )

    return (
        <>
            <AlertModal show={isLoading}>
                <ClipLoader color='white' size={30} />
            </AlertModal>
            <Modal
                size="lg"
                show={show}
                onHide={onHide}
                // onExited={onExited}
                aria-labelledby="example-modal-sizes-title-lg"
                scrollable
                // centered
                centered
                className="adminSettingsModal"
                backdropClassName="adminSettingsModalSample"

                onExiting={closingModal}


            >
                <Modal.Header className='header' closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Add Role
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='body'>
                    <div className=" treeviewbackground container  ">
                        <div className="row justify-content-center  ">
                            {/* <div className="col-md-3"></div> */}
                            <div className="col pt-3 d-flex  flex-column  row-gap-3 ">


                                <InputCustom
                                    style={{ width: '100%' }} label="Role name" name='Role name'
                                    onChange={(event) => setRoleName(event.target.value)} value={roleName}
                                    error={err?.role_name ? true : false}
                                    helperText={err?.role_name}
                                />
                                <TreeHierarchy nodes={nodes} onChange={(values) => setCheckedRoles(values)} />
                                <div className="col">
                                    <SelectBox
                                        placeholder='Organizational Herarchy'
                                        options={orgHerarchy}
                                        isMulti={false}
                                        onChange={(value) => setOrgHeraSelected(value)}
                                        value={orgHeraSelected}
                                    ></SelectBox>
                                </div>
                                <div className="row align-items-start   column-gap-2 row-gap-2  ">
                                    <div className="col ">
                                        <Select
                                            options={filterEmp}
                                            components={{
                                                Option: CustomOption
                                            }}
                                            styles={
                                                {
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        backgroundColor: 'var(--sidebar-color)',
                                                        borderColor: err?.employee_ids ? 'red' : 'var(--primaryy-color)',
                                                        boxShadow: 'none',
                                                        cursor: 'pointer',
                                                        ':hover': {
                                                            borderColor: state.isSelected ? 'var(--primaryy-color)' : 'var(--primaryy-color)'
                                                        }
                                                    }),
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        color: state.isSelected ? 'white' : 'var(--text-color)',
                                                        backgroundColor: state.isSelected ? 'var(--primaryy-color)' : 'var(--sidebar-color)',
                                                        cursor: 'pointer',
                                                        ':active': {
                                                            ...provided[':active'],
                                                            backgroundColor: 'var(--primaryy-color)',
                                                            color: 'white',
                                                        },
                                                        borderBottom: 'solid 1px rgba(128, 128, 128, 0.658)'
                                                        // ':focus': {
                                                        //   ...provided[':focus'],
                                                        //   backgroundColor: 'red',
                                                        //   color: 'white',
                                                        // }
                                                    }),
                                                    singleValue: (provided) => ({
                                                        ...provided,
                                                        color: 'var(--text-color)',
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        backgroundColor: 'var(--sidebar-color)',
                                                    }),
                                                    input: (provided) => ({
                                                        ...provided,
                                                        color: 'var(--text-color)',
                                                    }),
                                                    valueContainer: (base) => ({
                                                        ...base,
                                                        overflow: 'auto', // Enable scrolling
                                                        maxHeight: '200px', // Set a maximum height
                                                    })


                                                }
                                            }
                                            value={selectedOptions}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            onChange={(value) => setSelectedOptions(value)}


                                        />
                                        <p style={{ fontSize: '10px', color: 'red', textAlign: 'start' }} >{err?.employee_ids}</p>
                                    </div>
                                    <div className="  col-12 col-sm-auto d-flex justify-content-end  ">
                                        <button id="selectAllbtn" onClick={handleSelectAll} >
                                            Select All
                                        </button>
                                    </div>

                                </div>
                                <button id="selectAllbtn" onClick={submitRole} >
                                    {isSubmitted ? <span><ClipLoader color='white' size={25} /></span> : 'Add Role'}

                                </button>
                            </div>
                            {/* <div className="col-md-4"></div> */}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}


export function EditModal({
    id,
    show = false,
    onHide = () => { },
    updateUi = () => { }
}) {

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [employees, setEmployees] = useState([])
    const [roleName, setRoleName] = useState('');
    const [checkedRoles, setCheckedRoles] = useState([])
    const [err, setErr] = useState(null)
    const [isSubmitted, setSumbitted] = useState(false)
    const [isEditMode, setIsEditeMode] = useState(false)
    // const [filterEmp, setfilterEmp] = useState([])
    const [nodes, setNodes] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const orgHerarchieValues = ["ED", "VP", "HOD", "MIS", "RSM", "ASM", "SE"];

    const orgHerarchy = [
        { value: null, label: 'All' },
        ...orgHerarchieValues.map(
            org => ({
                label: org,
                value: org
            })
        )
    ]
    const [orgHeraSelected, setOrgHeraSelected] = useState(orgHerarchy[0])
    const filterEmp = employees.filter(
        emp => {
            if (orgHeraSelected.value && emp.orgHerarchy !== orgHeraSelected.value) {
                return false
            }
            return true
        }
    )
    // {value:"ALL"},


    const handleSelectAll = () => {

        setSelectedOptions(filterEmp);

    };

    const clearDatas = () => {
        setSelectedOptions([])
        // setEmployees([])
        setRoleName('')
        setCheckedRoles([])
    }
    const findAllParentIDs = (data, id) => {
        const result = [];
        let currentID = id;

        const findParent = (itemID) => {
            // Find the item with the given ID
            const item = data.find(d => d.id == itemID);
            if (item && item.FM18_M18_ParentID !== null) {
                // If the item has a parent, add the parent ID to the result array
                result.push(item.FM18_M18_ParentID);
                // Recursively find the parent of the parent
                findParent(item.FM18_M18_ParentID);
            }
        }

        findParent(currentID);
        return result;
    }

    const submitRole = async () => {
        setSumbitted(true)
        setIsLoading(true)

        try {

            const employee_ids = selectedOptions.map(
                emp => emp.value
            )





            const err = {}
            if (roleName === '') {
                err['role_name'] = 'please enter role name'
            }
            if (checkedRoles.length === 0) {
                err['checked_roles'] = 'please select any role '
            }
            if (employee_ids.length === 0) {
                err['employee_ids'] = 'please select an employee '
            }
            if (Object.keys(err).length !== 0) {
                setSumbitted(false)
                setIsLoading(false)
                setErr(err)
                return;
            } else {
                setErr(null)
            }
            if (Array.isArray(nodes)) {
                let completeParents = []
                checkedRoles.forEach(
                    roles => {
                        const parents = findAllParentIDs(nodes, roles);
                        if (parents.length > 0) {
                            completeParents = [...new Set([...completeParents, ...parents])];
                        }
                    }
                )

                const completeRoles = [...new Set([...completeParents, ...checkedRoles])]
                console.log('parents are', completeRoles)
                const data = {
                    role_name: roleName,
                    menu_ids: completeRoles,
                    // user_role_status:1,
                    employee_ids
                }

                console.log('senting data', data)

                const token = sessionStorage.getItem('access-token')
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                };

                const res = await axios.post(`${apiBaseUrl}/api/user-role/create`, data, config);
                clearDatas()
                toastmsgsuccess('Role created successfully')
                setSumbitted(false)
                updateUi()
                onHide()

                // console.log(res.data)
                // console.log(res)
            }




        } catch (err) {
            setSumbitted(false)
            console.log(err)
            if (err.response?.data?.message) {
                toastmsg(err.response?.data?.message)
            } else {
                toastmsg('somthing went wrong')
            }

        }

        setIsLoading(false)

    }

    const closingModal = () => {
        setSelectedOptions([])
        setOrgHeraSelected(orgHerarchy[0])
        setEmployees([])
        setRoleName('')
        setCheckedRoles([])
        setErr(null)
        setSumbitted(false)
        // setfilterEmp([])
        setNodes([])
        setIsLoading(false)
        setIsEditeMode(false)
    }

    const turnOnEditMode = () => {
        setIsEditeMode(true)
    }

    const closeEditMode = ()=>{
        setIsEditeMode(false)
    }



    useEffect(
        () => {
            const loadData = async () => {
                closingModal()
                setIsLoading(true)

                const token = sessionStorage.getItem('access-token')
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                };
                try {
                    let empList;
                    // filtering employee
                    if (employees.length === 0) {
                        const res = await axios.get(`${apiBaseUrl}/api/employee`, config)
                        empList = res.data.map(
                            emp => {
                                return {
                                    label: emp.FM06_EmployeeName,
                                    value: emp.id,
                                    empCode: emp.FM06_EmployeeCode,
                                    orgHerarchy: emp.FM06_OrganizationHierarchy
                                }
                            }
                        )

                        // console.log('employee list', empList)

                        setEmployees(empList)
                        // setfilterEmp(empList)

                    } else {

                        console.log('rebuild', show)
                        // const emplistnew = employees.filter(
                        //     emp => emp.orgHerarchy === orgHeraSelected.value
                        // )
                        // setfilterEmp(emplistnew)

                    }

                    const res = await axios.get(`${apiBaseUrl}/api/menu/list`, config)
                    setNodes(res.data);
                } catch (err) {
                    console.log(err)
                    toastmsg('error fetching employee list')
                }
                setIsLoading(false)
            }
            if (show === true) {

                loadData()
            }

        }, [show]
    )

    return (
        <>
            <AlertModal show={isLoading}>
                <ClipLoader color='white' size={30} />
            </AlertModal>
            <Modal
                size="lg"
                show={show}
                onHide={onHide}
                // onExited={onExited}
                aria-labelledby="example-modal-sizes-title-lg"
                scrollable
                // centered
                centered
                className="adminSettingsModal modalRoleEditAndView"
                backdropClassName="adminSettingsModalSample"

                onExiting={closingModal}


            >
                <Modal.Header className='header w-100 ' >
                    <Modal.Title id="example-modal-sizes-title-lg" className='w-100'>
                        <div className=' d-flex justify-content-between align-items-center '>
                            <div>View Role {id}</div>
                            <div className=' d-flex  '>
                                <div onClick={turnOnEditMode} className="iconButton pe-3">
                                    <i class='bx bxs-edit'></i>
                                </div>
                                <div onClick={onHide} className="iconButton">
                                    <i class='bx bx-x'></i>
                                </div>
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='body'>
                    <div className=" treeviewbackground container  ">
                        <div className="row justify-content-center  ">
                            {/* <div className="col-md-3"></div> */}
                            <div className="col pt-3 d-flex  flex-column  row-gap-3 ">


                                <InputCustom
                                    style={{ width: '100%' }} label="Role name" name='Role name'
                                    onChange={(event) => setRoleName(event.target.value)} value={roleName}
                                    error={err?.role_name ? true : false}
                                    helperText={err?.role_name}
                                />
                                <TreeHierarchy nodes={nodes} onChange={(values) => setCheckedRoles(values)} />
                                <div className="col">
                                    <SelectBox
                                        placeholder='Organizational Herarchy'
                                        options={orgHerarchy}
                                        isMulti={false}
                                        onChange={(value) => setOrgHeraSelected(value)}
                                        value={orgHeraSelected}
                                    ></SelectBox>
                                </div>
                                <div className="row align-items-start   column-gap-2 row-gap-2  ">
                                    <div className="col ">
                                        <Select
                                            options={filterEmp}
                                            components={{
                                                Option: CustomOption
                                            }}
                                            styles={
                                                {
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        backgroundColor: 'var(--sidebar-color)',
                                                        borderColor: err?.employee_ids ? 'red' : 'var(--primaryy-color)',
                                                        boxShadow: 'none',
                                                        cursor: 'pointer',
                                                        ':hover': {
                                                            borderColor: state.isSelected ? 'var(--primaryy-color)' : 'var(--primaryy-color)'
                                                        }
                                                    }),
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        color: state.isSelected ? 'white' : 'var(--text-color)',
                                                        backgroundColor: state.isSelected ? 'var(--primaryy-color)' : 'var(--sidebar-color)',
                                                        cursor: 'pointer',
                                                        ':active': {
                                                            ...provided[':active'],
                                                            backgroundColor: 'var(--primaryy-color)',
                                                            color: 'white',
                                                        },
                                                        borderBottom: 'solid 1px rgba(128, 128, 128, 0.658)'
                                                        // ':focus': {
                                                        //   ...provided[':focus'],
                                                        //   backgroundColor: 'red',
                                                        //   color: 'white',
                                                        // }
                                                    }),
                                                    singleValue: (provided) => ({
                                                        ...provided,
                                                        color: 'var(--text-color)',
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        backgroundColor: 'var(--sidebar-color)',
                                                    }),
                                                    input: (provided) => ({
                                                        ...provided,
                                                        color: 'var(--text-color)',
                                                    }),
                                                    valueContainer: (base) => ({
                                                        ...base,
                                                        overflow: 'auto', // Enable scrolling
                                                        maxHeight: '200px', // Set a maximum height
                                                    })


                                                }
                                            }
                                            value={selectedOptions}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            onChange={(value) => setSelectedOptions(value)}


                                        />
                                        <p style={{ fontSize: '10px', color: 'red', textAlign: 'start' }} >{err?.employee_ids}</p>
                                    </div>
                                    <div className="  col-12 col-sm-auto d-flex justify-content-end  ">
                                        <button id="selectAllbtn" onClick={handleSelectAll} >
                                            Select All
                                        </button>
                                    </div>

                                </div>
                                {isEditMode ?
                                    <div className='d-flex column-gap-2 justify-content-end '>
                                        <button onClick={closeEditMode}>Cancel</button>
                                        <button id="selectAllbtn" onClick={submitRole} >
                                            {isSubmitted ? <span><ClipLoader color='white' size={25} /></span> : 'Edit Role'}
                                        </button>
                                    </div>
                                    : null}
                            </div>
                            {/* <div className="col-md-4"></div> */}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}