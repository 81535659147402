// src/components/SalesDeleteForm.js
import React, { useState } from 'react';
import axios from 'axios';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { CustomButton2, CustomDatePicker, SelectCustom } from '../adminComponents/adminComponents';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MenuItem } from '@mui/material';
import dayjs from 'dayjs';
import { apiBaseUrl } from '../../static/Apiname/api';
import { useModal } from '../ModalToast/ToastModal';

const SalesDeleteForm = () => {
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [transType, setTransType] = useState('S');
    const {showModal}=useModal();
    
    const handleDelete = async () => {

        try {
            // Replace the URL with your actual API endpoint
            const apiUrl = `${apiBaseUrl}/api/delete-data/sales`;
            const token = sessionStorage.getItem('access-token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            const params = {
                from_date: dayjs(fromDate).format('YYYY-MM-DD'),
                to_date: dayjs(toDate).format('YYYY-MM-DD'),
                trans_type: transType,
            };

            console.log(params, 'params')

            // Make a DELETE request to the API
            const response = await axios.post(apiUrl, params, config);

            showModal('Delete successful:','success', response.data);
        } catch (error) {
            showModal('Error deleting  data', error);
        }
    };

    return (
        <div className='home'> 
            <div className="app card" id='chartCard'>
                <div className='salesdetete_Maindiv'>
                    <table className='sales-delete-table'>
                        <tbody style={{textAlign:'center'}}>
                            <tr id="DeleteTableRow">
                                <td>
                                    <label style={{ color: 'var(--text-color)', fontWeight: 'bold' }}>From Date:</label>
                                </td>
                                <td>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <CustomDatePicker
                                            value={fromDate}
                                            onChange={(e) => setFromDate(e)}
                                            format="YYYY-MM-DD"
                                        />
                                    </LocalizationProvider>
                                </td>
                            </tr>
                            <tr id="DeleteTableRow">
                                <td>
                                    <label style={{ color: 'var(--text-color)', fontWeight: 'bold' }}>To Date:</label>
                                </td>
                                <td>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <CustomDatePicker
                                            value={toDate}
                                            onChange={(e) => setToDate(e)}
                                            format="YYYY-MM-DD"
                                        />
                                    </LocalizationProvider>
                                </td>
                            </tr>
                            <tr id="DeleteTableRow">
                                <td>
                                    <label style={{ color: 'var(--text-color)', fontWeight: 'bold' }}>Transaction Type:</label>
                                </td>
                                <td>
                                    <SelectCustom style={{ width: '80%' }}
                                        value={transType}
                                        onChange={(e) => setTransType(e.target.value)}
                                    >
                                        <MenuItem value={'S'}>Sales Data</MenuItem>
                                        <MenuItem value={'R'}>Return Data</MenuItem>
                                    </SelectCustom>
                                </td>
                            </tr>
                            <tr id="DeleteTableRow">
                                <td colSpan="2">
                                    <CustomButton2 variant="contained" type="submit" onClick={handleDelete}>
                                        Delete  Data
                                    </CustomButton2>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default SalesDeleteForm;
