import './App.css';
import { useState, useEffect } from 'react';
import Dashboard2 from './components/dashboard2/dashboard2';
import { Link } from 'react-router-dom';
import { useDarkMode } from './components/Darktheme/DarkModeContext';

function App() {




  




  // useEffect(() => {
  //   const handleFullscreenChange = () => {
  //     setIsFullscreen(!!document.fullscreenElement);
  //   };

  //   document.addEventListener('fullscreenchange', handleFullscreenChange);
  //   document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
  //   document.addEventListener('mozfullscreenchange', handleFullscreenChange);
  //   document.addEventListener('MSFullscreenChange', handleFullscreenChange);

  //   return () => {
  //     document.removeEventListener('fullscreenchange', handleFullscreenChange);
  //     document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
  //     document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
  //     document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
  //   };
  // }, []);






  return (




      <section class="home" >
        <div class="mainDiv ">
            

          <Dashboard2 />


        </div>
      </section>




  );
}

export default App;
