import './Table.css';
import React, { useRef, useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import jsPDF from 'jspdf';
import { CSVLink } from 'react-csv';
import 'jspdf-autotable';
import { MdSend } from 'react-icons/md';
// import { Link } from '@mui/material';
import { Link, NavLink, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { async } from 'q';
// import jsonData from '../../json.json'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import Dashboard2 from '../graph/districtwise';
import PieChart from '../charts/Demochart';
import { apiBaseUrl } from '../../static/Apiname/api';
import { useDispatch } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDownloadExcel } from 'react-export-table-to-excel';
import { useSelector } from 'react-redux';
import { SetTableDate, SetTableState, todayDate } from '../../redux/navTogle/tableSlice';

function CustomDownloadExcel({ modifiedTable }) {
    const { onDownload } = useDownloadExcel({
        currentTableRef: modifiedTable,
        filename: 'Users-table',
        sheet: 'Users',
    });
    if (modifiedTable) {
        onDownload();
    }
    return <></>
}

function AsmTable({ onStateChange, passingData, columns, topData, }) {


    const tableRef = useRef(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [showTable, setShowTable] = useState(true);
    const [tableSelectValue, settableSelectValue] = useState('All');
    const [data, setData] = useState([]);
    const [clickedExcelDownload, setclickedExcelDownload] = useState(false)
    const dispatch = useDispatch();
    // const [date, setDate] = useState();
    // const [columns, setColumns1] = useState([])

    // const [topData, setTopData] = useState({})
    console.log('date', passingData);

    const [activeTab, setActiveTab] = useState('table'); // Default active tab is 'maintable'
    const rows = topData.rows ? topData.rows : [];
    // const [startDate, setStartDate] = useState(new Date());
    const {date:startDate} = useSelector(
        state => state.TableData.value
      
    )
    const [selectedOption, setSelectedOption] = useState(passingData.state_id); // Initial selected option
    // const options = ['KL ASM', 'KA ASM', 'TN ASM','KERALA'];

    console.log('newdate', startDate);
    const stateIdMap = {
        'KL': 2,
        'KA': 1,
        'TG': 3,
        'TN': 4,
        'AP': 5,
    };


    const options = []

    for (const key in stateIdMap) {
        options.push({
            stateId: stateIdMap[key],
            heading: key
        })
    }

    // console.log(props.tabOption);

    function prepareCSVData(rows, columns) {
        const data = [columns.map(column => column.label)];

        rows.forEach(row => {
            const rowData = columns.map(column => row[column.id]);
            data.push(rowData);
        });

        return data;
    }




    let categoryNames = []; // Declare categoryNames globally

    const zone = data?.category;

    if (Array.isArray(zone) && zone.length > 0) {
        categoryNames = zone.map(category => category.zone_name
);
    } else {
        console.log("No zone found in the data.");
    }


    const employeeNames = [];
    const employeeZones = [];

    if (Array.isArray(zone) && zone.length > 0) {
        // Loop through the zone
        zone.forEach(category => {
            // Access the "employee" array within each category
            const districts = category.employee;

            if (Array.isArray(districts) && districts.length > 0) {
                // Loop through the districts within each category and extract the names
                districts.forEach(employee => {
                    const employeeName = employee.name;
                    const employeeZone = employee.zone;
                    employeeNames.push(employeeName);
                    employeeZones.push(employeeZone);
                });
            }
        });
    }

    // console.log("Employee Names:", employeeNames);






    const handleValueButtonClick = (event) => {  // console.log(event.target.value);  
        settableSelectValue(event.target.value);
    };


    const downloadPDF = () => {
        const pdf = new jsPDF();

        const styles = { halign: 'center', valign: 'center', lineWidth: 0.4 }
        console.log(selectedOption)
        const stateName = options.find(
            val => val.stateId == selectedOption
        ).heading

        // Split the columns into two parts
        let firstColumns = columns.filter(
            (column) => {
                if (!column.id.startsWith('VAL_') && (column.id.startsWith('QTY_') || column.id === 'tableName'))
                    return true;
            }
        );
        const firstColAbove = [
            { content: stateName, colSpan: 3, styles: styles },
            { content: 'Quantity', colSpan: firstColumns.filter(column => column.id.startsWith('QTY_')).length, styles: styles }
        ]


        const pdfRows = []
        rows.forEach(
            (row) => {
                let temp = [[{ content: row.name, rowSpan: row.data.length + 1, styles: styles },]]
                row.data.forEach(
                    (data, index) => {
                        columns.forEach(
                            column => {
                                if (column.id === 'zone' || column.id === 'name' || column.id.startsWith('QTY_'))
                                    temp[index].push({ content: data[column.id], styles: styles })
                            }
                        )
                        if (index < row.data.length - 1) {
                            temp.push([]);
                        }
                    }
                )

                const subTotal = []
                subTotal.push({ content: 'Total', colSpan: 2, styles: styles })
                columns.forEach(
                    column => {
                        if (column.id.startsWith('QTY_'))
                            subTotal.push({ content: row.total[column.id], styles: styles })
                    }
                )
                temp.push(subTotal)



                pdfRows.push(...temp)

            }
        )
        let temp=[{ content: 'Total',colSpan:3, styles: styles }]
        columns.forEach(

            column => {
                if (column.id.startsWith('QTY_'))
                temp.push({ content: topData.total[column.id], styles: styles })
        }
        )
        pdfRows.push(temp)
        
        firstColumns = firstColumns.map((column, index) => {
            if (column.id === 'tableName') {
                return { content: passingData.head, colSpan: 3, styles: styles }
            }
            return { content: column.label, styles: styles };
        })

        let remainingColumns = columns.filter(
            (column) => {
                if (!column.id.startsWith('QTY_') && (column.id.startsWith('VAL_') || column.id === 'tableName'))
                    return true;
            }
        );


        const secondColAbove = [
            { content: stateName, colSpan: 3, styles: styles },
            { content: 'Value', colSpan: remainingColumns.filter(column => column.id.startsWith('VAL_')).length, styles: styles }
        ]



        const remainingRows = []
        rows.forEach(
            (row) => {
                let temp = [[{ content: row.name, rowSpan: row.data.length + 1, styles: styles },]]
                row.data.forEach(
                    (data, index) => {
                        columns.forEach(

                            column => {
                                if (column.id === 'zone' || column.id === 'name' || column.id.startsWith('VAL_'))
                                    temp[index].push({ content: data[column.id], styles: styles })
                            }
                        )

                        if (index < row.data.length - 1) {
                            temp.push([]);
                        }

                    }
                )

                const subTotal = []
                subTotal.push({ content: 'Total', colSpan: 2, styles: styles })
                columns.forEach(
                    column => {
                        if (column.id.startsWith('VAL_'))
                            subTotal.push({ content: row.total[column.id], styles: styles })
                    }
                )
                temp.push(subTotal)



                remainingRows.push(...temp)

            }
        )

        temp=[{ content: 'Total',colSpan:3, styles: styles }]
        columns.forEach(

            column => {
                if (column.id.startsWith('VAL_'))
                temp.push({ content: topData.total[column.id], styles: styles })
        }
        )
        remainingRows.push(temp)

        remainingColumns = remainingColumns.map((column, index) => {
            if (column.id === 'tableName') {
                return { content: passingData.head, colSpan: 3, styles: styles }
            }
            return { content: column.label, styles: styles };
        })


        // Extract the column IDs that start with "VAL_"
        // const valStartingColumns = columns.filter(column => column.id.startsWith('VAL_'));

        // Create the first part of the table with center-aligned headers and data
        pdf.autoTable({
            head: [firstColAbove, firstColumns],
            body: pdfRows,
            startY: 10,
            margin: { top: 10 },
            headStyles: { halign: 'center' }, // Center-align column headers
            styles: { halign: 'center', fontSize: 5 }, // Center-align column data
        });

        // Calculate the height of the first part
        const firstPartHeight = pdf.autoTable.previous.finalY;

        // Add space between the two parts
        const spaceBetweenParts = 10;

        // Create the second part of the table with center-aligned headers and data

        pdf.autoTable({
            head: [secondColAbove, remainingColumns],
            body: remainingRows,
            startY: firstPartHeight + spaceBetweenParts,
            headStyles: { halign: 'center' }, // Center-align column headers
            styles: { halign: 'center', fontSize: 5 }, // Center-align column data
        });

        // Save the PDF with a specified file name
        pdf.save('table_data.pdf');
    };


    const handleOptionChange = (event) => {
        console.log('sdfsdfsdfsd', event.target.value);
        onStateChange(event.target.value)
        setSelectedOption(event.target.value);
    };

    const handleGraphButtonClick = () => {
        setShowTable(!showTable);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleTabClick = (tab) => {
        setActiveTab(tab);

    };
    function handleDateSelect(date) {
        const date1 = new Date(date);
        const day = date1.getDate().toString().padStart(2, '0'); // Pad with leading zero if needed
        const month = (date1.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1
        const year = date1.getFullYear();
        // const formattedDate = `${day}-${month}-${year}`;
        const formattedDate = `${year}-${month}-${day}`;
        console.log('sample date', formattedDate);
        dispatch(SetTableDate(formattedDate))
    }

    function handleDateChange(data) {
        const date1 = data;
        const day = date1.getDate().toString().padStart(2, '0'); // Pad with leading zero if needed
        const month = (date1.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1
        const year = date1.getFullYear();
        // const formattedDate = `${day}-${month}-${year}`;
        const formattedDate = `${year}-${month}-${day}`;
        console.log('sample date', formattedDate);
        dispatch(SetTableDate(formattedDate))
        // handleDateSelect(data)
    }
    function onDateClickOutside() {
        handleDateSelect(startDate)
    }

    const tableColumn = columns.filter(
        (column) => {
            if (tableSelectValue === 'All') {
                return true
            } else if (tableSelectValue === 'Quantity') {
                if (!column.id.startsWith('VAL_')) {
                    return true
                }
            } else if (tableSelectValue === 'Value') {
                if (!column.id.startsWith('QTY_')) {
                    return true
                }
            }
        }
    )



    const prepareTableForExport = () => {
        if (!tableRef.current) {
            console.error("Table reference is not available.");
            return null;
        }

        // Clone the table element
        const tableClone = tableRef.current.cloneNode(true);

        // Extract values from the datepicker and dropdown before removing them
        const selectContainerTable = tableClone.querySelector('#selectcontainerTable');
        const datepickerValue = document.querySelector('#datepik').value;
        const dropdownValue = document.querySelector('#stateselect-box').value;
        const state = options.filter(state => state.stateId == dropdownValue)[0]['heading'];
        console.log('date', datepickerValue)
        console.log('table',)
        selectContainerTable.innerHTML += `<h4><span style="font-size: 30px;">${state}</span> (${datepickerValue})</h4>`;
        // Remove elements with specific IDs (e.g., dropdown and datepicker)
        const elementsToRemove = tableClone.querySelectorAll('#datepik, #stateselect-box');
        elementsToRemove.forEach(element => {
            element.remove();
        });

        return tableClone;
    };

    // Use the prepareTableForExport function to get the modified table
    // const modifiedTable = prepareTableForExport();

    // Use the useDownloadExcel hook to download the modified table


    // Event handler to trigger the download with the modified table
    const handleDownload = () => {
        setclickedExcelDownload(true);
        setTimeout(
            () => {
                setclickedExcelDownload(false)
            }, 1000
        )
    };

    useEffect(
        () => {
            dispatch(SetTableDate(passingData.date === undefined ? startDate : passingData.date))
            // setStartDate(new Date(passingData.date === undefined ? null : passingData.date))
            setSelectedOption(passingData.state_id)
        },
        []
    )


    return (
        <div >
            {clickedExcelDownload && <CustomDownloadExcel modifiedTable={prepareTableForExport()} />}
            <div id='TableOptions'>
                <div className='radio-group'>
                    <label className='radio'>
                        <input
                            type="radio"
                            name="tableSelectValue"
                            value="All"
                            checked={tableSelectValue === "All"}
                            onChange={handleValueButtonClick}
                        />
                        <span></span>

                        All
                    </label>
                    <label className='radio'>
                        <input
                            type="radio"
                            name="tableSelectValue"
                            value="Quantity"
                            checked={tableSelectValue === "Quantity"}
                            onChange={handleValueButtonClick}
                        />
                        <span></span>
                        Qty
                    </label>
                    <label className='radio'>
                        <input
                            type="radio"
                            name="tableSelectValue"
                            value="Value"
                            checked={tableSelectValue === "Value"}
                            onChange={handleValueButtonClick}
                        />
                        <span></span>
                        Val
                    </label>
                </div>

                <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: '10px' }}>
                        <Button variant="contained" color="primary" onClick={downloadPDF} id="btnDownloadPdf">
                            <i class="bi bi-filetype-pdf" style={{ fontSize: '1.5rem', paddingRight: '10px' }}></i> PDF
                        </Button>
                    </div>
                    <div>
                        {/* <CSVLink
                            data={prepareCSVData(rows, columns)}
                            filename="table_data.csv"
                        >
                            <Button variant="contained" color="primary" id="btnDownloadCsv">
                                <i class="bi bi-filetype-csv" style={{ fontSize: '1.5rem', paddingRight: '10px' }}></i>  CSV
                            </Button>
                        </CSVLink> */}
                        <Button variant="contained" color="primary" id="btnDownloadCsv" onClick={handleDownload}>
                            <i class="bi bi-filetype-csv" style={{ fontSize: '1.5rem', paddingRight: '10px' }}></i>  CSV
                        </Button>
                    </div>
                </div>
            </div>

            {topData.rows ?
                <Paper sx={{ width: '95%', overflow: 'hidden' }} id='tablemaindiv' >
                    <TableContainer sx={{ maxHeight: 'calc(100vh - 150px)' }} id='table-scrollbar'>
                        <Table aria-label="sticky table" style={{ borderCollapse: 'separate' }} ref={tableRef}>
                            <TableHead style={{ position: 'sticky', top: '0', padding: '0' }} >  
                                <TableRow>
                                    <TableCell id='TableHead' rowSpan={3} colSpan={2} style={{ fontSize: '1.5rem', fontFamily: 'inherit', borderRight: 'solid 1px' }}>

                                        <div id='selectcontainerTable' style={{ height: '100%' }}>
                                            <select value={passingData.state_id} onChange={handleOptionChange} id='stateselect-box' >
                                                {options.map((option) => (
                                                    <option key={option.stateId} value={option.stateId} id='options'>
                                                        {option.heading}
                                                    </option>
                                                ))}
                                            </select>

                                            {/* <input type="date"  id='datepik'/> */}
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy" // Define the desired date and time format
                                                selected={new Date(startDate)}
                                                onSelect={handleDateSelect}
                                                onChange={handleDateChange}
                                                onCalendarClose={onDateClickOutside}
                                                id='datepik'
                                            // Add the showTimeInput prop to enable time selection
                                            />
                                        </div>
                                    </TableCell>
                                    {
                                        tableColumn.map((column) => (column.id.startsWith('QTY_') || column.id.startsWith('VAL_')) && (
                                            <TableCell id='TableHead' key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                                {column.label.toUpperCase()}
                                            </TableCell>
                                        ))
                                    }
                                </TableRow>
                                {/* <TableRow>
                                    

                                </TableRow> */}
                                <TableRow style={{ maxHeight: '30px', overflowY: 'hidden' }}>

                                    {
                                        tableColumn.map((column) => (column.id.startsWith('QTY_') || column.id.startsWith('VAL_')) && (
                                            <TableCell id='TableHead' key={column.id} align={column.align} >
                                                {topData.total[column.id]}
                                            </TableCell>
                                        ))
                                    }


                                </TableRow>
                                <TableRow>

                                    {
                                        (() => {
                                            let quantityCount = 0;
                                            let valueCount = 0;
                                            tableColumn.forEach(
                                                (column) => {
                                                    if (column.id.startsWith('QTY_')) {
                                                        quantityCount++
                                                    } else if (column.id.startsWith('VAL_')) {
                                                        valueCount++;
                                                    }
                                                }
                                            )
                                            const rowCell = []
                                            if (valueCount > 0) {
                                                rowCell.push(
                                                    <TableCell id='TableHead' key={'value'} colSpan={valueCount}>
                                                        Value
                                                    </TableCell>
                                                )
                                            }
                                            if (quantityCount > 0) {
                                                rowCell.push(
                                                    <TableCell id='TableHead' key={'Quantity'} colSpan={quantityCount}>
                                                        Quantity
                                                    </TableCell>
                                                )
                                            }
                                            return rowCell
                                        })()

                                    }
                                </TableRow>
                                <TableRow>
                                    {
                                        tableColumn.map((column) => column.id !== 'name' && column.id !== 'id' && (
                                            <TableCell id='TableHead' key={column.id} align={column.align} style={{ minWidth: column.minWidth }} colSpan={column.colSpan} >
                                                {column.label.toUpperCase()}
                                            </TableCell>
                                        ))
                                    }

                                </TableRow>
                            </TableHead>
                            <TableBody >


                                {
                                    rows.map(
                                        (row) => {

                                            let dynamicRow = []
                                            dynamicRow.push(
                                                <TableRow>
                                                    <TableCell id='TableData' rowSpan={row.data.length + 1}>
                                                        {row.name}
                                                    </TableCell>
                                                    {
                                                        row.data.filter(
                                                            (data,index)=>index===0
                                                        ).map(
                                                            (row, index) => {
                                                                    return (
                                                                        tableColumn.map(
                                                                            (col) => {

                                                                                // if (col.id.startsWith('QTY_') || col.id.startsWith('VAL_')) {
                                                                                // console.log(row[col.label]);
                                                                                return col.id !== 'zone' && col.id !== 'id' && (
                                                                                    <TableCell id='TableData'>
                                                                                        {row[col.id]}
                                                                                    </TableCell>
                                                                                )
                                                                                // }
                                                                            }
                                                                        )

                                                                    )
                                                            }
                                                        )
                                                    }
                                                </TableRow>
                                            )

                                            dynamicRow.push(
                                                row.data.filter(
                                                    (data,index)=>index!==0
                                                ).map(
                                                    (row, index) => {
                                                            return (
                                                                <TableRow>
                                                                    {
                                                                        tableColumn.map(
                                                                            (col) => {
        
                                                                                // if (col.id.startsWith('QTY_') || col.id.staartsWith('VAL_')) {
                                                                                // console.log(row[col.label]);
                                                                                return col.id !== 'zone' && col.id !== 'id' && (
                                                                                    <TableCell id='TableData'>
                                                                                        {row[col.id]}
                                                                                    </TableCell>
                                                                                )
                                                                                // }
                                                                            }
                                                                        )
                                                                    }
                                                                </TableRow>

                                                            )
                                                    }
                                                )
                                            )

                                            dynamicRow.push(
                                                <TableRow>
                                                    {
                                                        [
                                                            <TableCell className='totalrow' colSpan={1} align='center' id='TableData'>
                                                                Total
                                                            </TableCell>
                                                            ,
                                                            ...tableColumn.map(
                                                                (col) => {

                                                                    // if (col.id.startsWith('QTY_') || col.id.startsWith('VAL_')) {
                                                                    // console.log(row[col.label]);
                                                                    return (col.id.startsWith('QTY_') || col.id.startsWith('VAL_')) && (
                                                                        <TableCell id='TableData' className='totalrow' >
                                                                            {row.total[col.id]}
                                                                        </TableCell>
                                                                    )
                                                                    // }
                                                                }
                                                            )
                                                        ]
                                                    }

                                                </TableRow>

                                            )



                                            return dynamicRow

                                        }
                                    )

                                }




                                <TableRow id='totalRow'>

                                    {
                                        [
                                            <TableCell colSpan={2} id='TableHead' >
                                                Total
                                            </TableCell>,
                                            ...tableColumn.map((column) => (column.id.startsWith('QTY_') || column.id.startsWith('VAL_')) && (
                                                <TableCell id='TableHead' key={column.id} align={column.align} >
                                                    {topData.total[column.id]}
                                                </TableCell>
                                            ))

                                        ]
                                    }


                                </TableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                : <>

                    <SkeletonTheme baseColor="#f6f5ff00" highlightColor="#ffffff73" width={'95%'}>
                        <p>
                            <Skeleton height={90} />
                            <Skeleton count={15} />
                        </p>
                    </SkeletonTheme>
                </>

            }
        </div>

    );
}
export default function LandingPage(props) {



    const [columns, setColumns1] = useState([])
    const [topData, setTopData] = useState({})
    const dispatch = useDispatch();
    // const [date, setDate] = useState('30-06-2023');
    const date = useSelector(
        state => state.TableData.value.date
      
    )
    // const [stateId, setStateId] = useState(2)
    const stateId =useSelector(
        state => state.TableData.value.state
    )

    const userDetails = useSelector(
        state => state.userData.value.userDetails
    )
    const [data, setData] = useState({})
    const [graphData, setGraphData] = useState({})






    useEffect(() => {

        const loadTable = async () => {


            try {
                setTopData({})
                const apiUrl = `${apiBaseUrl}/api/get-data/district-wise`;
                const token = sessionStorage.getItem('access-token')
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                };
                console.log('stateId', stateId);
                const response = await axios.post(apiUrl, { 'state_id': stateId, "date": date,'employee_id':userDetails.id}, config);
                const jsonData = response.data;
                console.log("datedate", jsonData.data.date);
                console.log(userDetails.id);
                const datee = jsonData.data.date
                dispatch(SetTableDate(datee));
                // console.log('jjssoonnddaattaa',jsonData.data.head);
                const statename = jsonData.data.state;
                const head = jsonData.data.head;
                // let headers = response.data.data.category[0].employee[0];
                let headers = { ...jsonData.data.zone[0].districts[0] };
                delete headers.value;
                delete headers.quantity;
                //exracting headers from data
                let columns = [{ id: 'zone', label: 'zone', minWidth: 80, colSpan: 1 }];
                for (const key in headers) {

                    // if (key !== 'name') {

                    columns.push(
                        {
                            id: key,
                            label: key,

                        }
                    )

                    // if (key === 'zone') {
                    //     columns.push(
                    //         {
                    //             id: key,
                    //             label: key,

                    //         }
                    //     )
                    // } else if (key === 'name') {
                    //     columns.push(
                    //         {
                    //             id: key,
                    //             label: key,

                    //         }
                    //     )
                    // } else {
                    // columns.push(
                    //     {
                    //         id: `VAL_${key}`,
                    //         label: key,
                    //         minWidth: 72,
                    //         align: 'right',
                    //         format: (value) => value.toLocaleString('en-US'),
                    //     }
                    // )
                    // }



                    // }
                }

         

                headers = { ...jsonData.data.zone[0].districts[0].value }
                for (const key in headers) {

                    columns.push(
                        {
                            id: `VAL_${key}`,
                            label: key,
                            minWidth: 72,
                            align: 'right',
                            format: (value) => value.toLocaleString('en-US'),
                        }
                    )
                }

                headers = { ...jsonData.data.zone[0].districts[0].quantity }
                for (const key in headers) {

                    columns.push(
                        {
                            id: `QTY_${key}`,
                            label: key,
                            minWidth: 72,
                            align: 'right',
                            format: (value) => value.toLocaleString('en-US'),
                        }
                    )
                }
                setColumns1(columns)

                console.log(columns);

                //make rows
                // const rowData = response.data.data.category
                const rowData = jsonData.data.zone
                // console.log(rowData);
                let rows = [];

                rowData.forEach(
                    (row) => {
                        let totalRow = {}
                        for (const key in row.total.quantity) {
                            totalRow[`QTY_${key}`] = row.total.quantity[key]
                        }
                        for (const key in row.total.value) {
                            totalRow[`VAL_${key}`] = row.total.value[key]
                        }
                        rows.push(

                            {
                                name: row.zone_name,
                                data: [
                                    ...row.districts.map(
                                        (district) => {

                                            let emp = { ...district }
                                            let empRow = {}
                                            delete emp.quantity;
                                            delete emp.value;

                                            for (const key in emp) {
                                                empRow[key] = emp[key]
                                            }
                                            emp = { ...district }
                                            delete emp.district;
                                            for (const key in emp.quantity) {
                                                empRow[`QTY_${key}`] = emp.quantity[key]
                                            }
                                            for (const key in emp.value) {
                                                empRow[`VAL_${key}`] = emp.value[key]
                                            }

                                            return empRow;

                                        }
                                    ),

                                ],
                                total: totalRow
                            }

                        )
                    }
                )

                let totalRow = {}
                for (const key in jsonData.data.total.quantity) {
                    totalRow[`QTY_${key}`] = jsonData.data.total.quantity[key]
                }
                for (const key in jsonData.data.total.value) {
                    totalRow[`VAL_${key}`] = jsonData.data.total.value[key]
                }
                console.log(rows);
                setTopData({
                    rows,
                    total: totalRow
                })

                delete jsonData.data.zone;
                setData(jsonData.data)
                setGraphData(
                    {
                        names: rows.map((row) => row.name),
                        totals: rows.map((row) => {
                            // Remove commas and convert to integer
                            return parseInt(row.total.VAL_MTD_.replace(/,/g, ''), 10);
                        })
                    })
                // console.log({
                //     rows,
                //     total: response.data.data.total
                // });



            } catch (err) {
                console.log(err);
            }


        }

        loadTable();



    }, [stateId,date]);

    function onStateChange(stateId) {
        dispatch(SetTableState(stateId))
    }




    return (
        <div  style={{paddingBottom:'46px'}}>


            <div className="menuOptions">

                <NavLink id='menuBtns' to={'chart'}>Chart</NavLink>
                <NavLink id='menuBtns' to={'table'} style={{ borderRight: 'solid 1.5px rgba(255, 255, 255, 0.5)', borderLeft: 'solid 1.5px rgba(255, 255, 255, 0.5)' }}>Table</NavLink>
                <NavLink id='menuBtns' to={'graph'} >Graph</NavLink>
            </div>
            {/* <div className={`transition-left-to-right ${showGraph || showChart ? 'move-left' : 'move-right'}`}>
          {showGraph ? <Dashboard2 /> : showChart ? <PieChart /> : null}
        </div> */}

            <Routes>
                <Route path='' element={<Navigate to="table" replace={true} />}></Route>
                <Route path={`chart`} element={<PieChart topData={topData} />} />
                <Route path={`table`} element={<AsmTable onStateChange={onStateChange} columns={columns} topData={topData} passingData={data} />} />
                <Route path={`graph`} element={<Dashboard2 data={graphData} />} />
            </Routes>
        </div>

    );
}