import './ToastModal.css'
import React, { createContext, useContext, useState } from 'react';


function Modal() {
  const { modal, closeModal } = useModal();

  if (!modal.isOpen) return null;
  const modalClass = modal.type === 'success' ? 'success-modal':'modall'

  return (
    <div className="modal-backdropp">
      <div className={modalClass}>
        <p>{modal.message}</p>
       <center><button className='modalbtnToast' onClick={closeModal}>Close</button></center> 
      </div>
    </div>
  );
}


const ModalContext = createContext();
export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState({ isOpen: false, message: '' });
  const [successmodal,setModalsuccess] =useState({ isOpen: false, message: '' })
  const showModal = (message, type = 'default') => {
    setModal({ isOpen: true, message, type });
  };
  const showModalsuccess = (message, type = 'success') => {
    setModalsuccess({ isOpen: true, message, type });
  };
  

  const closeModal = () => {
    setModal({ isOpen: false, message: '' });
  };

  return (
    <ModalContext.Provider value={{ modal, showModal, closeModal,successmodal,showModalsuccess }}>
      {children}
    </ModalContext.Provider>
  );
};

export {Modal};
